import {HttpClient} from '@angular/common/http';
import {Injectable, NgModule} from '@angular/core';

import {
  HashMap,
  Translation,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  TRANSLOCO_MISSING_HANDLER,
  translocoConfig,
  TranslocoLoader,
  TranslocoMissingHandler,
  TranslocoMissingHandlerData,
  TranslocoModule
} from '@ngneat/transloco';

@Injectable()
export class TranslocoHttpLoader implements TranslocoLoader {

  constructor(private http: HttpClient) {
  }

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

@Injectable()
export class TranslocoMissingKeyHandler implements TranslocoMissingHandler {
  handle(key: string, data: TranslocoMissingHandlerData, params?: HashMap): any {
    const firstDot = key.indexOf('.');
    if (firstDot) {
      return key.substr(firstDot + 1);
    }
    return key;
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    TranslocoHttpLoader,
    TranslocoMissingKeyHandler,
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'th'],
        defaultLang: 'en',
        fallbackLang: 'en',
        reRenderOnLangChange: true, // Remove this option if your application doesn't support changing language in runtime.
        prodMode: false,
      })
    },
    {provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader},
    {provide: TRANSLOCO_MISSING_HANDLER, useClass: TranslocoMissingKeyHandler}
  ]
})
export class TranslocoRootModule {
}
