export class PharmacyOutletPayload {

  itemId: string
  qty: number
  pharmacyOutletId: string

  static mapFromFormModel(itemId: string, qty: number, pharmacyOutletId: string): PharmacyOutletPayload {
    const payload = new PharmacyOutletPayload();
    payload.itemId = itemId;
    payload.qty = qty;
    payload.pharmacyOutletId = pharmacyOutletId;
    return payload;
  }
}
