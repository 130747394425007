import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'orderSeverityStatus'
})
export class OrderSeverityStatusClassPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case 'Urgent':
        return 'text-tertiary'
      case 'Emergency':
        return 'text-burnt-sienna'
      case 'Normal':
        return 'text-eucalyptus'
      default:
        return '';
    }
    // return value === 'Urgent' ? 'text-burnt-sienna' : 'text-japanese-laurel'
  }

}
