import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {getApplicationVersion} from '@v2/core/functions/functions';
import {UtilityService} from '@v2/core/services/utility.service';
import {finalize} from 'rxjs/operators';
import {AuthService, LoginResponse} from '../../core';

interface VerificationResponse {
  isVerify: boolean
}

interface VerificationResendCodeResponse {
  success: boolean
}

@Component({
  selector: 'app-two-step-verification',
  templateUrl: './two-step-verification.component.html',
  styleUrls: ['./two-step-verification.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'authentication'}]
})
export class TwoStepVerificationComponent implements OnInit {

  isSubmitting = false;
  twoStepVerificationForm: FormGroup;
  queryParams: LoginResponse;
  errorMessage: string;
  appVersion = '';

  constructor(
    private fb: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    private authService: AuthService,
    private matSnackBar: MatSnackBar,
    private translocoService: TranslocoService,
    private utilityService: UtilityService
  ) {
  }

  ngOnInit() {
    this.appVersion = getApplicationVersion();

    this.twoStepVerificationForm = this.fb.group({
      otp: ['', Validators.required]
    });

    this.route.queryParams.subscribe(queryParams => {
      this.queryParams = JSON.parse(atob(queryParams.data));
    })
  }

  resendCode() {
    this.authService
      .resendCode(this.queryParams)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe((data: VerificationResendCodeResponse) => {
        if (data.success) {
          this.utilityService.showSnackBarCloseLabel(this.translocoService.translate('Verification code sent', {}, 'authentication'));
        } else {
          this.utilityService.showSnackBarCloseLabel(this.translocoService.translate('Verification code send fail', {}, 'authentication'));
        }
      }, () => {
        this.errorMessage = 'Verification failed';
      });
  }

  onSubmit() {
    this.isSubmitting = true;
    this.errorMessage = '';
    const postData = {data: this.twoStepVerificationForm.value};
    this.authService
      .verifyOtp(postData, this.queryParams)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
        (data: VerificationResponse) => {
          if (data.isVerify) {
            this.router.navigateByUrl(this.authService.defaultURL).then()
          } else {
            this.utilityService.showSnackBarCloseLabel(this.translocoService.translate('Verification failed', {}, 'authentication'));
          }
        }, () => {
          this.utilityService.showSnackBarCloseLabel(this.translocoService.translate('Verification failed', {}, 'authentication'));
        });
  }

}
