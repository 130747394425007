import {DrugAllergyApiModel, DrugInteractionApiModel} from '../api/drug-allergy-api.model';

export class DrugAllergyViewModel {
  constructor(
    public id: number,
    public allergyStatus: string,
    public allergyType: string,
    public medicineId: string,
    public brandName: string,
    public genericName: string,
    public durationMonth: number,
    public durationYear: number,
    public isConfirmed: boolean,
    public name: string,
    public notes: string,
    public severity: string,
    public symptoms: string
  ) {
  }

  static mapFromApiModel(apiData: DrugAllergyApiModel): DrugAllergyViewModel {
    return new DrugAllergyViewModel(
      apiData.id,
      apiData.allergyStatus,
      apiData.allergyType && apiData.allergyType.label,
      apiData.catalogElement && apiData.catalogElement.id,
      apiData.catalogElement && apiData.catalogElement.brandName,
      apiData.catalogElement && apiData.catalogElement.genericName,
      apiData.durationMonth,
      apiData.durationYear,
      apiData.isConfirmed,
      apiData.name,
      apiData.notes,
      apiData.severity && apiData.severity.label,
      apiData.symptoms,
    )
  }
}

export class DrugInteractionViewModel {
  id: string
  drugInteractionXref: string
  brandName1: string
  genericName1: string
  xref1: string
  strengthUnit1: string
  brandName2: string
  brandName1Id: string
  brandName2Id: string
  genericName2: string
  xref2: string
  strengthUnit2: string
  englishNote: string
  thaiNote: string

  static mapFromApiModel(apiData: DrugInteractionApiModel, medicineId: string): DrugInteractionViewModel[] {
    const interactionArray: DrugInteractionViewModel[] = []
    if (apiData && apiData.interactions && apiData.interactions.length) {
      apiData.interactions.map(data => {
        const interactionDrug = data.drugs.find(drugData => drugData.id !== medicineId)
        if (interactionDrug) {
          const viewModel = new DrugInteractionViewModel()
          const drug1 = data.drugs && data.drugs.length && data.drugs[0]
          const drug2 = data.drugs && data.drugs.length && data.drugs[1]
          viewModel.id = data.id
          viewModel.drugInteractionXref = data.xref
          viewModel.brandName1 = drug1.brandName
          viewModel.genericName1 = drug1.genericName
          viewModel.xref1 = drug1.xref
          viewModel.strengthUnit1 = drug1.strengthUnit
          viewModel.brandName2 = drug2.brandName
          viewModel.brandName1Id = drug1.id
          viewModel.brandName2Id = drug2.id
          viewModel.genericName2 = drug2.genericName
          viewModel.xref2 = drug2.xref
          viewModel.strengthUnit2 = drug2.strengthUnit
          viewModel.englishNote = data.englishInteractionNote
          viewModel.thaiNote = data.thaiInteractionNote
          interactionArray.push(viewModel)
        }
      })
    }

    return interactionArray
  }
}
