import {Injectable} from '@angular/core';
import {ConcurrencyScopeEnum} from '@v2/core/enums/concurrency-scope.enum';
import {IBase, IData} from '@v2/core/models/masterdata';
import {IConcurrencyDeletedItems} from '@v2/core/models/masterdata/IPost.master-data';
import {IVitalsOPD} from '@v2/core/models/masterdata/IVitals';
import {Observable} from 'rxjs';
import {PostModel} from '../../../../models';
import {BaseHttpService} from '../../../base-http.service';
import {BaseModel, VitalsApiModel} from '../../../models';

@Injectable({
  providedIn: 'root'
})
export class NurseOpdVitalApiService {

  endPoint = 'opd/ns';

  constructor(private baseHttpService: BaseHttpService) {
  }

  getAllVitals(nurseStationId: string, appointmentId: string): Observable<IBase<IData<IVitalsOPD>>> {
    return this.baseHttpService.get<IBase<IData<IVitalsOPD>>>(`${this.endPoint}/${nurseStationId}/appointment/${appointmentId}/vitals`)
  }

  putVitals(nurseStationId: string, appointmentId: string, vitalData: PostModel<VitalsApiModel, IConcurrencyDeletedItems>): Observable<BaseModel<VitalsApiModel>> {
    return this.baseHttpService.putWrapper(`${this.endPoint}/${nurseStationId}/appointment/${appointmentId}/vitals`, vitalData, ConcurrencyScopeEnum.VITALS)
  }

}
