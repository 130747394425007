import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexModule, GridModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TranslocoModule} from '@ngneat/transloco';
import {PipeModule} from '@v2/core/pipes/pipe.module';
import {NgxPrintModule} from 'ngx-print';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {InfoItemModule} from '../../../../shared/common/info-item/info-item.module';
import {ReadOnlyInputModule} from '../../../../shared/components/read-only-input/read-only-input.module';
import {CommonPipesModule} from '../../../../shared/pipes/common-pipes/common-pipes.module';
import {PrintMedicalRecordStickerModule} from '../../../adt-management/admit-transfer-request/admit-transfer-request-shared/print-medical-record-sticker/print-medical-record-sticker.module';
import {ProfilePicModule} from '../profile-pic/profile-pic.module';
import {PatientInfoModalComponent} from './patient-info-modal/patient-info-modal.component';
import {PatientInfoPrintModalComponent} from './patient-info-modal/patient-info-print-modal/patient-info-print-modal.component';
import {PatientNameDisplayComponent} from './patient-name-display.component';
import {PatientInfoModalHttpService} from './resources/services/http/patient-info-modal-http.service';

@NgModule({

  declarations: [PatientNameDisplayComponent, PatientInfoModalComponent, PatientInfoPrintModalComponent],
  imports: [
    CommonModule,
    CommonPipesModule,
    PipeModule,
    MatIconModule,
    ProfilePicModule,
    ReadOnlyInputModule,
    SwiperModule,
    NgxPrintModule,
    MatProgressSpinnerModule,
    FlexModule,
    GridModule,
    MatDialogModule,
    MatButtonModule,
    TranslocoModule,
    PrintMedicalRecordStickerModule,
    InfoItemModule
  ],
  entryComponents: [PatientInfoModalComponent],
  exports: [
    PatientNameDisplayComponent
  ],
  providers: [PatientInfoModalHttpService]
})
export class PatientNameDisplayModule {
}
