import {Location} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {AuthService} from '../../../core';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'authorization'}]
})
export class ForbiddenComponent implements OnInit {

  constructor(private router: Router,
              private authService: AuthService,
              private location: Location) {
  }

  ngOnInit() {
  }

  onHome() {
    this.router.navigateByUrl(this.authService.getRelativeHomeUrl()).then();
  }

  onBack() {
    const currentUrl = localStorage.getItem('currentUrl');
    if (currentUrl) {
      this.router.navigateByUrl(currentUrl).then();
    } else {
      this.location.back();
    }
  }
}
