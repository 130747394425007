import {FormControl} from '@angular/forms';


export function matchOtherValidator(otherControlName: string, comparison: string = 'equal') {

  let thisControl: FormControl;
  let otherControl: FormControl;

  return function matchOtherValidate(control: FormControl) {
    if (control.value) {
      if (!control.parent) {
        return null;
      }

      // Initializing the validator.
      if (!thisControl) {
        thisControl = control;
        otherControl = control.parent.get(otherControlName) as FormControl;
        if (!otherControl) {
          throw new Error('matchOtherValidator(): other control is not found in parent group');
        }
        otherControl.valueChanges.subscribe(() => {
          thisControl.updateValueAndValidity();
        });
      }

      if (!otherControl) {
        return null;
      }

      let thisControlValue = thisControl.value;
      let otherControlValue = otherControl.value;
      if (Number(thisControlValue) && Number(otherControlValue)) {
        thisControlValue = Number(thisControlValue)
        otherControlValue = Number(otherControlValue)
      }

      let result: any = null;
      switch (comparison) {
        case 'equal':
          if (thisControl.errors && !thisControl.errors.matchOther) {
            return;
          } else {
            if (otherControlValue !== thisControlValue) {
              result = {
                matchOther: true
              };
            }
          }
          break;
        case 'greater-than':
          if ((otherControlValue < thisControlValue) || (otherControlValue === thisControlValue)) {
            result = {
              matchOther: true
            };
          }
          break;
        case 'less-than':
          if ((otherControlValue > thisControlValue) || (otherControlValue === thisControlValue)) {
            result = {
              matchOther: true
            };
          }
          break;
        case 'greater-than-equal':
          if (otherControlValue !== thisControlValue) {
            if (otherControlValue > thisControlValue) {
              result = {
                matchOther: true
              };
            }
          }
          break;
        case 'less-than-equal':
          if (otherControlValue !== thisControlValue) {
            if (otherControlValue < thisControlValue) {
              result = {
                matchOther: true
              };
            }
          }
          break;
      }

      return result;
    } else {
      return null;
    }
  };


}
