import {IPharmacyOutlet} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';

export class PharmacyOutletViewModel extends ConcurrencyRequest {

  id: string;
  outletXref: string;
  outletName: string;
  associatedDepartment: string;
  associatedDepartmentId: string;
  outletStatus: string;
  reception: string;
  floorName: string;
  buildingName: string;
  attachedReceptionId: string;

  static mapFromV2HttpModel(apiData: IPharmacyOutlet): PharmacyOutletViewModel {
    const outletInfo = new PharmacyOutletViewModel();
    outletInfo.id = apiData.id;
    outletInfo.outletXref = apiData.xref;
    outletInfo.outletName = apiData.title
    outletInfo.attachedReceptionId = apiData.attachReception && apiData.attachReception.id;
    return outletInfo;
  }

  static mapFromApi(apiData: IPharmacyOutlet): PharmacyOutletViewModel {
    const outletInfo = new PharmacyOutletViewModel();
    outletInfo.id = apiData.id;
    outletInfo.outletXref = apiData.xref;
    outletInfo.outletName = apiData.title;
    outletInfo.outletStatus = apiData.status ? 'Active' : 'Inactive';

    if (apiData.department) {
      outletInfo.associatedDepartment = apiData.department.name;
    }

    if (apiData.attachReception) {
      outletInfo.reception = apiData.attachReception.name;
    }

    if (apiData.floor) {
      outletInfo.floorName = apiData.floor.name;
    }

    if (apiData.building) {
      outletInfo.buildingName = apiData.building.name;
    }
    outletInfo.mapConcurrencyRequest(apiData);

    return outletInfo;
  }

}
