import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MAT_MOMENT_DATE_FORMATS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_LABEL_GLOBAL_OPTIONS} from '@angular/material/core';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MAT_EXPANSION_PANEL_DEFAULT_OPTIONS} from '@angular/material/expansion';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {ChunkLoadingErrorHandlerService} from '@v2/core/services/chunk-loading-error-handler.service';
import {NgxMaskModule} from 'ngx-mask';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import {ToastrModule} from 'ngx-toastr';
import {SatNativeDateModule} from 'saturn-datepicker';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from './auth';
import {AuthGuard} from './auth/auth-guard.service';
import {HttpTokenInterceptor} from './core';
import {InitService} from './core/services/init.service';
import {CustomDateAdapter} from './shared/custom-date-adapter';
import {LayoutModule} from './shared/layout/layout.module';
import {TranslocoRootModule} from './transloco-root.module';
import {GlobalErrorHandlerService} from './v2/shared-v2/components/error-handler/global-error-handler.service';
import {StaleDataToastrComponent} from './v2/shared-v2/components/stale-data-toastr/stale-data-toastr.component';
import {StaleDataToastrModule} from './v2/shared-v2/components/stale-data-toastr/stale-data-toastr.module';
import {ClearBrowserCacheModalModule} from './v2/shared-v2/modals/clear-browser-cache-modal/clear-browser-cache-modal.module';

export function startupServiceFactory(initService: InitService) {
  return () => initService.loadConfigs();
}

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent
  ],
  providers: [
    InitService,
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [InitService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000
      }
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        shouldLabelFloat: true
      }
    },
    {
      provide: MAT_LABEL_GLOBAL_OPTIONS,
      useValue: {
        float: 'always'
      }
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        panelClass: 'myapp-no-padding-dialog',
        width: '900px',
        hasBackdrop: true,
        closeOnNavigation: true
      }
    },
    {
      provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
      useValue: {
        collapsedHeight: '45px',
        expandedHeight: '45px'
      }
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {
      provide: ErrorHandler,
      useClass: ChunkLoadingErrorHandlerService,
      deps: [GlobalErrorHandlerService]
    },
    AuthGuard
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    AuthModule,
    StaleDataToastrModule,
    FormsModule,
    ClearBrowserCacheModalModule,
    HttpClientModule,
    TranslocoRootModule,
    NgxMaterialTimepickerModule,
    ToastrModule.forRoot({
      toastComponent: StaleDataToastrComponent, // added custom toast!
      preventDuplicates: true
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: true}),
    NgxMaskModule.forRoot(),
    SatNativeDateModule,
    SwiperModule,
  ],
  entryComponents: [StaleDataToastrComponent],
  bootstrap: [AppComponent],
})
export class AppModule {
}
