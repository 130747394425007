import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'isEmpty'
})
export class IsEmptyPipe implements PipeTransform {
  transform(value: any, placeHolder: string = '-'): any {
    return value || placeHolder;
  }
}
