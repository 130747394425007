import {IPatientOrder} from '@v2/core/models/masterdata/IPatientOrder.master-data';

export class FuturePatientOrderViewModel {
  testDateTime: string
  testName: string[]
  orderType: string
  orderBy: string
  department: string

  constructor() {
  }

  static mapFromApiModel(apiData: IPatientOrder): FuturePatientOrderViewModel {
    let doctorName = '-';
    let departmentName = '-';
    if (apiData.investigations.length > 0) {
      if (apiData.investigations[0].orderingDepartment) {
        departmentName = apiData.investigations[0].orderingDepartment.name;
      }
      if (apiData.investigations[0].orderingDoctor) {
        doctorName = `Dr. ${apiData.investigations[0].orderingDoctor.firstName} ${ apiData.investigations[0].orderingDoctor.lastName}`;
      }
    }
    const testNames = apiData.investigations.map(investigation => {
      if (investigation.catalogGroupElement) {
        return investigation.catalogGroupElement.name;
      } else {
        return investigation.catalogElement.englishName;
      }
    });

    const viewModel = new FuturePatientOrderViewModel();

    viewModel.testDateTime = apiData.preferredDateTime ? apiData.preferredDateTime : ''
    viewModel.testName = testNames
    viewModel.orderType = apiData.orderType && apiData.orderType.label ? apiData.orderType.label : '-'
    viewModel.orderBy = doctorName
    viewModel.department = departmentName

    return viewModel;
  }

}

export interface IAdmissionAppointmentExistInfo {
  isAdmissionReqExists: boolean;
  selectedAdmissionDate?: string;
}
