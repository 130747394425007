import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'isEmpty'
})
export class IsEmptyPipe implements PipeTransform {
  transform(value: any, placeHolder: string = '-', excludeZeroValues = false): any {
    return excludeZeroValues && value === 0 ? 0 : (value || placeHolder);
  }
}
