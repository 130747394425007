import {getName, getStartOfDay} from '@v2/core/functions/functions';
import {IMasterData, IMedication} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {FrequencyDataViewModel} from '@v2/core/models/view/frequency-data.view.model';
import {PrescriptionMedicineViewModel} from '@v2/core/models/view/prescription-medicine.view.model';

export class CurrentMedicationViewModel extends ConcurrencyRequest {
  id: string;
  medicine: PrescriptionMedicineViewModel;
  dosage: number;
  dosageUnit: IMasterData;
  frequency: string;
  frequencyType: IMasterData;
  isCustomFrequency: boolean;
  frequencyData: FrequencyDataViewModel
  intake: IMasterData;
  route: IMasterData;
  days: number | string;
  instruction: string;
  prescribedBy: string;
  startDate: string
  isExternalSource: boolean;

  static mapFromHttpModel(httpData: IMedication): CurrentMedicationViewModel {
    const viewData = new CurrentMedicationViewModel();
    viewData.id = httpData.id;
    viewData.medicine = httpData.medicine && PrescriptionMedicineViewModel.mapFromHttpModel(httpData.medicine);
    viewData.dosage = httpData.dosage as number;
    viewData.dosageUnit = httpData.dosageUnit;
    viewData.frequency = httpData.frequency;
    viewData.frequencyType = httpData.frequencyType;
    viewData.isCustomFrequency = httpData.isCustomFrequency;
    viewData.frequencyData = httpData.frequencyData;
    viewData.intake = httpData.intake;
    viewData.route = httpData.route;
    viewData.days = httpData.days;
    viewData.instruction = httpData.instruction;
    viewData.prescribedBy = httpData.prescribedBy ? getName(httpData.prescribedBy.firstName, httpData.prescribedBy.lastName) : '';
    viewData.startDate = getStartOfDay(httpData.startDate);
    viewData.isExternalSource = httpData.isExternalSource;
    viewData.mapConcurrencyRequest(httpData);
    return viewData;
  }
}
