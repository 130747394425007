import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar} from '@angular/material';
import {TranslocoService} from '@ngneat/transloco';
import {refineExternalRouteURL} from '@v2/core/functions/functions';
import * as copy from 'copy-to-clipboard';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private translocoService: TranslocoService,
              private snackBar: MatSnackBar) {
  }

  get closeTranslation(): string {
    return this._translate('Close');
  }

  get internalServerErrorMessage(): string {
    return this._translate('Internal Server Error');
  }

  get serverOverloadedTranslation(): string {
    return this._translate('Server Overloaded, Please try again after sometime');
  }

  showSnackBarCloseLabel(translatedMessage: string, config?: MatSnackBarConfig): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(translatedMessage, this.closeTranslation, config);
  }

  getFirstErrorMessage(error: any, defaultValue = ''): string {
    try {
      const errorCode = this.getErrorCode(error);
      if (errorCode === 500) {
        return this.internalServerErrorMessage;
      }
      const message = this.getFirstError(error).message;
      return message || defaultValue;
    } catch (e) {
      return defaultValue;
    }
  }

  getFirstError(error: any) {
    try {
      return error.error.errors[0];
    } catch (e) {
      return error;
    }
  }

  displayErrorSnackbar(error?: any, config?: MatSnackBarConfig) {
    const errorCode = this.getErrorCode(error);
    if (errorCode === 500) {
      this.showSomethingWentWrongSnackBar(error, config)
    } else if (errorCode === 503) {
      this.showSnackBarCloseLabel(this.serverOverloadedTranslation, config)
    } else if (errorCode !== 409 && errorCode !== 423) {
      this.showSnackBarFirstError(error, config);
    }
  }

  copyToClipboard(linkUrl: string, translatedSuccessMessage?: string, translatedErrorMessage?: string) {
    if (copy(refineExternalRouteURL(linkUrl))) {
      this.showSnackBarCloseLabel(
        translatedSuccessMessage ? translatedSuccessMessage : this._translate('Copied to Clipboard')
      );
    } else {
      this.showSnackBarCloseLabel(
        translatedErrorMessage ? translatedErrorMessage : this._translate('Could not copy link')
      );
    }
  }

  selectTranslate(key: string, params: {} = {}, scope?: string): Observable<any> {
    return this.translocoService.selectTranslate(key, params, scope);
  }

  private showSomethingWentWrongSnackBar(error?: any, config?: MatSnackBarConfig) {
    if (!error || (!!error && !!error.error && error.error.code !== 409 && error.error.code !== 423)) {
      this.snackBar.open(
        this.internalServerErrorMessage,
        this.closeTranslation,
        config
      );
    }
  }

  private showSnackBarFirstError(error: any, config?: MatSnackBarConfig) {
    try {
      const message = error.error.errors[0].message;
      if (!!message) {
        this.showSnackBarCloseLabel(message, config);
      } else {
        this.showSomethingWentWrongSnackBar(error, config);
      }
    } catch (e) {
      this.showSomethingWentWrongSnackBar(error, config);
    }
  }

  private getErrorCode(error: any): number {
    try {
      return error.error.code;
    } catch (e) {
      return 500;
    }
  }

  // add new word into client/src/assets/i18n/en.json & client/src/assets/i18n/th.json
  private _translate(message: string): string {
    return this.translocoService.translate(message);
  }
}
