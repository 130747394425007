import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PipeModule} from '@v2/core/pipes/pipe.module';
import {CommonPipesModule} from '../../../../shared/pipes/common-pipes/common-pipes.module';
import {ProfilePicComponent} from './profile-pic.component';

@NgModule({
  declarations: [ProfilePicComponent],
  imports: [
    CommonModule,
    PipeModule,
    CommonPipesModule
  ],
  exports: [ProfilePicComponent]
})
export class ProfilePicModule {
}
