import {getElementPrices, isNullOrUndefined} from '@v2/core/functions/functions';
import {ICatalogElement, IMasterData} from '@v2/core/models/masterdata';

export class MedicineViewModel {
  id: string;
  xref: string;
  brandName: string;
  genericName: string;
  category: string;
  dispensingUnit: IMasterData;
  strength: string;
  opdPrice?: number;
  ipdPrice?: number;
  baseSellingPrice?: number;
  masterCatalogPrice?: number;
  name?: string;
  route?: string;
  routeObj?: IMasterData;
  dosage?: string;
  dosageUnit?: IMasterData;
  frequencyType?: IMasterData;
  days?: number;
  intake?: IMasterData;
  usageTemplate?: string;
  usageMethod?: string;
  thaiUsageTemplate?: string;
  thaiUsageMethod?: string;

  static mapFromHttpModel(httpData: ICatalogElement): MedicineViewModel {
    const prices = getElementPrices(httpData.elementPrices);
    const viewData = new MedicineViewModel();
    viewData.id = httpData.id;
    viewData.xref = httpData.xref;
    viewData.brandName = httpData.brandName;
    viewData.genericName = httpData.genericName;
    viewData.category = httpData.catalogCategory ? httpData.catalogCategory.name : '';
    viewData.dispensingUnit = httpData.dispensingUnit;
    viewData.strength = httpData.strengthUnit;
    viewData.opdPrice = prices.opdPrice;
    viewData.ipdPrice = prices.ipdPrice;
    viewData.baseSellingPrice = prices.baseSellingPrice;
    viewData.masterCatalogPrice = prices.masterCatalogCost;
    viewData.name = httpData.brandName;
    viewData.route = httpData.route ? httpData.route.label : '';
    viewData.routeObj = httpData.route;
    viewData.dosage = !isNullOrUndefined(httpData.dose) ? httpData.dose.toString() : '';
    viewData.dosageUnit = httpData.dosageUnit;
    viewData.frequencyType = httpData.frequency;
    viewData.days = httpData.days;
    viewData.intake = httpData.intake;
    viewData.usageTemplate = httpData.englishTemplate;
    viewData.usageMethod = httpData.englishInstruction;
    viewData.thaiUsageTemplate = httpData.thaiTemplate;
    viewData.thaiUsageMethod = httpData.thaiInstruction;
    return viewData;
  }
}
