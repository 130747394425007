import {Component, Input, OnInit} from '@angular/core';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {AgeDisplayFormatEnum} from '../../../../shared-v2/components/age-display/age-display-format.enum';
import {AdmitTransferRequestViewModel} from '../../../resources/models/view/admit-transfer-request/admit-transfer-request.view.model';
import {BedTransferRequestViewModel} from '../../../resources/models/view/admit-transfer-request/bed-transfer-request.view.model';

@Component({
  selector: 'app-print-medical-record-sticker',
  templateUrl: './print-medical-record-sticker.component.html',
  styleUrls: ['./print-medical-record-sticker.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'adtManagement'}]
})
export class PrintMedicalRecordStickerComponent implements OnInit {

  @Input() patientData: BedTransferRequestViewModel | AdmitTransferRequestViewModel

  ageDisplayFormatEnum = AgeDisplayFormatEnum;

  constructor() {
  }

  ngOnInit() {
  }

}
