import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {setFilter} from '@v2/core/functions/functions';
import {IBase, IPageableData} from '@v2/core/models/masterdata';
import {ICustomFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {Observable} from 'rxjs';
import {
  NurseOpdDashboardAvailableDoctorApiModel,
  NurseOpdDashboardCheckInApiModel,
  NurseOpdDashboardNextAppointmentApiModel,
  NurseOpdDashboardPatientListApiModel
} from '../../../../../visits/opd/nurse-opd/dashboard/models/api.model';
import {BaseHttpService} from '../../../base-http.service';
import {BaseModel} from '../../../models';

@Injectable({
  providedIn: 'root'
})
export class NurseOpdDashboardApiService {

  endPoint = 'opd/ns';

  constructor(
    private baseHttpService: BaseHttpService
  ) {
  }

  getCheckInPatient(nurseId: string, filter): Observable<BaseModel<NurseOpdDashboardCheckInApiModel>> {
    const params = new HttpParams()
      .set('filters', JSON.stringify(filter));
    return this.baseHttpService.get(`${this.endPoint}/${nurseId}/dashboard/checked-in/patients/count`, params)
  }

  getPatientList(nurseId: string, filters: ICustomFilters = {}): Observable<IBase<IPageableData<NurseOpdDashboardPatientListApiModel>>> {
    return this.baseHttpService.get<IBase<IPageableData<NurseOpdDashboardPatientListApiModel>>>(`${this.endPoint}/${nurseId}/dashboard/checked-in/patients/appointments`, setFilter(filters))
  }

  getNextAppointments(nurseId: string, filters: ICustomFilters = {}): Observable<IBase<IPageableData<NurseOpdDashboardNextAppointmentApiModel>>> {
    return this.baseHttpService.get<IBase<IPageableData<NurseOpdDashboardNextAppointmentApiModel>>>(`${this.endPoint}/${nurseId}/dashboard/future/patients/appointments`, setFilter(filters))
  }

  getAvailableDoctor(nurseId: string, filters: ICustomFilters = {}): Observable<BaseModel<IPageableData<NurseOpdDashboardAvailableDoctorApiModel>>> {
    return this.baseHttpService.get<IBase<IPageableData<NurseOpdDashboardAvailableDoctorApiModel>>>(`${this.endPoint}/${nurseId}/dashboard/available-doctors/appointment-count`, setFilter(filters));
  }

}
