import {AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {getCurrentDateTime} from '@v2/core/functions/functions';
import {fromEvent, merge, Observable, Observer} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'footer'}]
})
export class FooterComponent implements OnInit, AfterViewInit, OnDestroy {
  checked = false;
  @ViewChild('counter', {static: false})
  public myCounter: ElementRef;

  private interval: any;

  constructor(private zone: NgZone,
              private translocoService: TranslocoService,
              private renderer: Renderer2) {
  }

  ngOnInit() {
    const activeLang = this.translocoService.getActiveLang();
    this.checked = activeLang !== 'en';
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

  onLangChange(langChanged: boolean) {
    this.checked = langChanged;
    const currentLang = langChanged ? 'th' : 'en';
    this.translocoService.setActiveLang(currentLang);
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      this.interval = setInterval(() => {
        if (this.myCounter) {
          this.renderer.setProperty(this.myCounter.nativeElement, 'textContent', getCurrentDateTime('DD-MM-YYYY, hh:mm A'));
        }
      }, 1000);
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
