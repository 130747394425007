import {Directive, HostBinding, HostListener, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[appExpandMenu]'
})
export class ExpandMenuDirective implements OnInit {
  @Input() defaultOpen: boolean;
  @HostBinding('class.active') isOpen;

  constructor() {
  }

  ngOnInit(): void {
    this.isOpen = this.defaultOpen;
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    this.isOpen = !this.isOpen;
  }
}
