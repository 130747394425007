import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {take} from 'rxjs/operators';
import {ArchivedDataService} from './service/archived-data.service';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';

@Component({
  selector: 'app-archived-data',
  templateUrl: './archived-data.component.html',
  styleUrls: ['./archived-data.component.scss'],
  providers: [ArchivedDataService, {provide: TRANSLOCO_SCOPE, useValue: 'common'}],
})
export class ArchivedDataComponent implements OnInit {

  @Input() isArchiveMode = false;
  @Output() clicked = new EventEmitter<void>();
  hasMigratedData = false;

  constructor(
    private route: ActivatedRoute,
    private archivedDataService: ArchivedDataService
  ) {
  }

  ngOnInit() {
    const patientId = this.route.snapshot.queryParams.patientId;
    this.archivedDataService.checkPatientHasMigratedData(patientId).pipe(take(1)).subscribe(res => this.hasMigratedData = res);
  }

  onArchiveDataClick() {
    if (this.hasMigratedData) {
      this.clicked.emit();
    }
  }
}
