import {DOCTOR_PREFIX, getName} from '@v2/core/functions/functions';
import * as moment from 'moment';
import {OrRequestScheduleApiModel} from '../../api/operation-room/or-request-schedule-api.model';

export class OrScheduleEventsViewModel {
  constructor(
    public id: string,
    public resourceId: string,
    public doctorName: string,
    public fromTime: string,
    public fromTimeDisplay: string,
    public toTime: string,
    public toTimeDisplay: string,
    public roomName: string,
    public start: string,
    public end: string,
    public color: string,
    public eventType: string
  ) {
  }

  static mapFromApiModel(element: OrRequestScheduleApiModel, surgeonId: string, anesthesiologistId: string) {
    let eventType = 'or-event';
    let color = '#EEA4A4';
    if (element.doctorId === surgeonId) {
      eventType = 'surgeon-event';
      color = '#D2D2D2';
    } else if (element.doctorId === anesthesiologistId) {
      eventType = 'anesthesiologist-event';
      color = '#B3C1E4';
    }

    return new OrScheduleEventsViewModel(
      element.id,
      moment(element.scheduleStartTime).format('YYYY-MM-DD'),
      element.doctor && getName(element.doctor.firstName, element.doctor.lastName, DOCTOR_PREFIX),
      element.scheduleStartTime,
      moment(element.scheduleStartTime).format(),
      element.scheduleEndTime,
      element.scheduleEndTime,
      (eventType === 'or-event' && element.operationRoom) ? element.operationRoom.name : (element.opdRoom) ? element.opdRoom.name : '-',
      moment(element.scheduleStartTime).format(),
      moment(element.scheduleEndTime).format(),
      color,
      eventType
    )
  }
}
