export enum CatalogTypeEnum {
  lab = 'Lab',
  radiology = 'Radiology',
  pharmacy = 'Pharmacy',
  supplies = 'Supplies',
  services = 'Services',
  housekeeping = 'Housekeeping',
  nutritionMeal = 'Nutrition & Meal',
  equipment = 'Equipment',
  stationery = 'Stationery',
  internalOperational = 'InternalOperational',
  other = 'Other',
  SUPPLY_UI_TEXT = 'Supply' // not an actual catalog type, but used only for display purpose => Medical Supply -> Supply
}
