import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexModule, GridModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {TranslocoModule} from '@ngneat/transloco';
import {PipeModule} from '@v2/core/pipes/pipe.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ReadOnlyInputModule} from '../../../../shared/components/read-only-input/read-only-input.module';
import {CommonDirectiveModule} from '../../../../shared/directive/common-directive.module';
import {LoadingButtonModule} from '../../components/loading-button/loading-button.module';
import {PatientNameDisplayModule} from '../../components/patient-name-display/patient-name-display.module';
import {ProfilePicModule} from '../../components/profile-pic/profile-pic.module';
import {MasterDataDropdownModule} from '../../paginated-dropdown-control/controls/master-data-dropdown/master-data-dropdown.module';
import {NotificationAreasDropdownModule} from '../../paginated-dropdown-control/controls/notification-areas-dropdown/notification-areas-dropdown.module';
import {AddEditNoteAlertModalComponent} from './add-edit-note-alert-modal/add-edit-note-alert-modal.component';
import {NoteAlertListModalComponent} from './note-alert-list-modal/note-alert-list-modal.component';
import {NoteAlertLogsModalComponent} from './note-alert-logs-modal/note-alert-logs-modal.component';
import {NoteAlertComponent} from './note-alert.component';

@NgModule({
  declarations: [NoteAlertComponent, AddEditNoteAlertModalComponent, NoteAlertLogsModalComponent, NoteAlertListModalComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    GridModule,
    ReadOnlyInputModule,
    FlexModule,
    LoadingButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    NotificationAreasDropdownModule,
    MatBadgeModule,
    MatMenuModule,
    MatSlideToggleModule,
    MasterDataDropdownModule,
    ProfilePicModule,
    PipeModule,
    MatProgressSpinnerModule,
    InfiniteScrollModule,
    PatientNameDisplayModule,
    CommonDirectiveModule
  ],
  entryComponents: [AddEditNoteAlertModalComponent, NoteAlertLogsModalComponent, NoteAlertListModalComponent],
  exports: [NoteAlertComponent, NoteAlertListModalComponent],
})
export class NoteAlertModule {
}
