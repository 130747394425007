import { Injectable } from '@angular/core';
import { BaseHttpService } from '@v2/core/services/base-http.service';
import { Observable } from 'rxjs';
import { IBase, IData, IPageableData } from '@v2/core/models/masterdata';
import { IWalletBalance, IWalletHistoryLog, WalletListApiModel } from 'src/app/billing/wallet-management/models/api/wallet-list-api.model';
import { HttpParams } from '@angular/common/http';
import { ConcurrencyScopeEnum } from '@v2/core/enums/concurrency-scope.enum';


@Injectable()

export class WalletManagementApiService {

  constructor(private baseHttpService: BaseHttpService) {
  }

  getWalletList(params = new HttpParams()): Observable<IBase<IData<IWalletBalance>>> {
    return this.baseHttpService.get<IBase<IData<IWalletBalance>>>(`v2/patient/wallet`, params);
  }

  getWalletBalance(patientId: string) {
    return this.baseHttpService.get<IBase<IWalletBalance>>(`v2/patient/${patientId}/wallet`);
  }

  getWalletHistory(patientId: string, params = new HttpParams()) {
    return this.baseHttpService.get<IBase<IPageableData<IWalletHistoryLog>>>(`v2/patient/${patientId}/wallet-history`, params);
  }

  saveWalletAdjust(patientId: string, payload: any, params = new HttpParams()) {
    return this.baseHttpService.postWrapperV2<IBase<{ status: true}>>(`v2/patient/${patientId}/wallet`, payload, ConcurrencyScopeEnum.WALLET_MANAGEMENT_ADJUST, params);
  }

  getActiveWallet(patientId: string): Observable<IBase<{claimId: string, appointmentId: string, admissionOrVisit: string}>> {
   return  this.baseHttpService.get<IBase<{claimId: string, appointmentId: string, admissionOrVisit: string}>>(`v2/patient/${patientId}/active-wallet-visit`)
  }
}
