export enum PaymentTypeEnum {
  socialSecurity = 'Social Security',
  insurance = 'Insurance',
  companyContract = 'Corporate/Company contract',
  selfPay = 'Self Pay',
  companyContractPaidByCompany = 'Company Contract (Paid by Company)',
  companyContractPaidByEmployee = 'Company Contract (Paid by Employee)',
  UCEP = 'UCEP',
  NHSO = 'NHSO',
  other = 'Other',
  all = 'Payment Type (All)' // added for dashboard graph, 'all' is not an actual payment type
}

export enum PaymentByEnum {
  COMPANY = 'Company',
  EMPLOYEE = 'Employee'
}
