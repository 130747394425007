import {FormControl, Validators} from '@angular/forms';
import {OrderTypeEnum} from '@v2/core/enums/order-type.enum';
import {PatientOrderStatusEnum} from '@v2/core/enums/patient-order-status.enum';
import {
  getCurrentDateTime,
  getCurrentTime,
  getStartOfDay,
  getTimeFromDate,
  getUTCDateTime,
  mapDepartmentDataToDepartmentMinimal,
  mapFullNameInDoctor,
  objectValidator
} from '@v2/core/functions/functions';
import {ICatalogElement, ICatalogGroup, IDepartmentMinimal, IDoctor, IMasterData} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {IMedicalPackage} from '@v2/core/models/masterdata/IMedicalPackage.master-data';
import {IFavoriteOrderElement, IPatientOrder} from '@v2/core/models/masterdata/IPatientOrder.master-data';
import {FavoriteFormDataModel} from './favorite-form.model';

interface IRadiologyOrderForm {
  id: string | FormControl;
  name: ICatalogElement | string | FormControl;
  severityId: number | FormControl;
  severityName: string | FormControl;
  requestedDateTime: string | FormControl;
  preferredDate: string | FormControl;
  preferredTime: string | FormControl;
  note: string | FormControl;
  catalogElementId: string | FormControl;
  radiologyType: string | FormControl;
  orderType: string | FormControl;
  orderOrigin: string | FormControl;
  orderStatus: string | FormControl;
  orderXref: string | FormControl;
  isOrderCancelled: boolean | FormControl;
  orderCancellationNote: string | FormControl;
  isMedicalPackageOrder: boolean | FormControl;
  department: IDepartmentMinimal | FormControl;
  doctor: IDoctor | FormControl;
  isReordered: boolean | FormControl;

  index?: number | FormControl;
  isSubmitting?: boolean | FormControl;
  isSubmitted?: boolean | FormControl;
  errorMessage?: string | FormControl;
  isAdded?: boolean | FormControl;
  isFormValid?: boolean | FormControl;
}

export class RadiologyOrderForm implements IRadiologyOrderForm {
  id = new FormControl();
  name = new FormControl(null, [Validators.required]);
  severityId = new FormControl(null, [Validators.required]);
  severityName = new FormControl();
  requestedDateTime = new FormControl();
  preferredDate = new FormControl(getCurrentDateTime(), [Validators.required]);
  preferredTime = new FormControl(getCurrentTime(), [Validators.required]);
  note = new FormControl();
  catalogElementId = new FormControl(null, [Validators.required]);
  radiologyType = new FormControl();
  orderType = new FormControl(OrderTypeEnum.radiology);
  orderStatus = new FormControl();
  orderXref = new FormControl();
  isOrderCancelled = new FormControl(false);
  orderCancellationNote = new FormControl();
  isMedicalPackageOrder = new FormControl();
  updatedAt = new FormControl();
  department = new FormControl(null, [Validators.required, objectValidator]);
  doctor = new FormControl(null, [Validators.required, objectValidator]);
  orderOrigin = new FormControl();
  isReordered = new FormControl();
  isSubmitting = new FormControl();
  isSubmitted = new FormControl();
  errorMessage = new FormControl();
  index = new FormControl();
  isAdded = new FormControl();
  isFormValid = new FormControl(false);
}

export class RadiologyFormDataModel extends ConcurrencyRequest implements IRadiologyOrderForm {
  id: string;
  name: ICatalogElement | string;
  severityId: number;
  severityName: string;
  requestedDateTime: string;
  preferredDate: string;
  preferredTime: string;
  note: string;
  catalogElementId: string;
  radiologyType: string;
  orderType: string;
  orderOrigin: string;
  orderStatus: string;
  orderXref: string;
  isOrderCancelled: boolean;
  orderCancellationNote: string;
  isMedicalPackageOrder: boolean;
  medicalPackage: IMedicalPackage;
  packageSelectionItemId: string;
  department: IDepartmentMinimal;
  doctor: IDoctor;
  pkgSelectionItemUpdatedAt: string;

  updatedAt: string;

  isSelectedForReorder: boolean;
  reorderError: boolean;
  isReordered: boolean;

  index?: number;
  isSubmitting?: boolean;
  isSubmitted?: boolean;
  errorMessage?: string;
  isAdded?: boolean;
  isFormValid?: boolean;

  static mapFromHttpModel(httpData: IPatientOrder): RadiologyFormDataModel {
    const formData = new RadiologyFormDataModel();
    formData.id = httpData.id;
    formData.catalogElementId = httpData.catalogElementId;
    if (httpData.catalogElement) {
      formData.name = httpData.catalogElement.englishName;
      formData.catalogElementId = httpData.catalogElement.id;
      formData.radiologyType = httpData.catalogElement.investigationTypeId;
    }
    if (httpData.severity) {
      formData.severityId = httpData.severity.key;
      formData.severityName = httpData.severity.label;
    }
    formData.pkgSelectionItemUpdatedAt = httpData.pkgSelectionItem ? getUTCDateTime(httpData.pkgSelectionItem.pkgSelectionItemUpdatedAt) : undefined;
    formData.requestedDateTime = httpData.requestedDateTime;
    formData.preferredDate = getStartOfDay(httpData.preferredDateTime);
    formData.preferredTime = getTimeFromDate(httpData.preferredDateTime);
    formData.note = httpData.note;
    formData.orderType = httpData.orderType ? httpData.orderType.label : '';
    formData.orderOrigin = httpData.orderOrigin ? httpData.orderOrigin.label : '';
    formData.orderStatus = httpData.radiologyOrderStatus && httpData.radiologyOrderStatus.label;
    formData.orderXref = httpData.xref;
    formData.isOrderCancelled = httpData.orderStatus && httpData.orderStatus.label === PatientOrderStatusEnum.CANCELED;
    formData.orderCancellationNote = httpData.statusNote;
    formData.isMedicalPackageOrder = httpData.isPkgOrder;
    formData.medicalPackage = httpData.medicalPackage;
    formData.packageSelectionItemId = httpData.packageSelectionItemId;
    formData.doctor = mapFullNameInDoctor(httpData.orderingDoctor);
    formData.department = mapDepartmentDataToDepartmentMinimal(httpData.orderingDepartment);
    formData.isSubmitted = null;
    formData.errorMessage = null;
    formData.mapConcurrencyRequest(httpData);
    return formData;
  }

  static mapFromGroupOrderModel(groupOrderData: RadiologyGroupOrderFormDataModel, normalSeverityStatus: IMasterData): RadiologyFormDataModel[] {
    const data: RadiologyFormDataModel[] = [];
    if (groupOrderData) {
      groupOrderData.group.catalogElements.forEach(element => {
        const formData = new RadiologyFormDataModel();
        formData.id = null;
        formData.name = element.name;
        if (normalSeverityStatus) {
          formData.severityId = normalSeverityStatus.key;
          formData.severityName = normalSeverityStatus.label;
        }
        formData.requestedDateTime = null;
        formData.preferredDate = getCurrentDateTime();
        formData.preferredTime = getCurrentTime();
        formData.note = element.notes;
        formData.catalogElementId = element.id;
        formData.radiologyType = element.catalogCategoryId;
        formData.orderType = OrderTypeEnum.radiology;
        formData.orderStatus = null;
        formData.department = groupOrderData.department;
        formData.doctor = groupOrderData.doctor;
        formData.isSubmitted = null;
        formData.isFormValid = true;
        data.push(formData);
      });
    }
    return data;
  }

  static mapFromFavoriteOrder(favoriteData: FavoriteFormDataModel, element: IFavoriteOrderElement, normalSeverityStatus: IMasterData): RadiologyFormDataModel {
    const formData = new RadiologyFormDataModel();
    formData.id = null;
    formData.name = element.catalogElement.name;
    if (normalSeverityStatus) {
      formData.severityId = normalSeverityStatus.key;
      formData.severityName = normalSeverityStatus.label;
    }
    formData.requestedDateTime = null;
    formData.preferredDate = getCurrentDateTime();
    formData.preferredTime = getCurrentTime();
    formData.note = element.instruction;
    formData.catalogElementId = element.catalogElement.id;
    formData.radiologyType = element.catalogElement.catalogCategoryId;
    formData.orderType = OrderTypeEnum.radiology;
    formData.orderStatus = null;
    formData.department = favoriteData.department;
    formData.doctor = favoriteData.doctor;
    formData.isFormValid = true;
    formData.isSubmitted = null;
    return formData;
  }

  static mapForRadiologyReorder(order: RadiologyFormDataModel, appointmentDepartmentInfo: IDepartmentMinimal, appointmentDoctorInfo: IDoctor) {
    return {
      ...order,
      id: null,
      requestedDateTime: null,
      preferredDate: getCurrentDateTime(),
      preferredTime: getCurrentTime(),
      department: {...appointmentDepartmentInfo},
      doctor: {...appointmentDoctorInfo},
      isSubmitted: null,
      isFormValid: true,
      orderStatus: null,
      isOrderCancelled: false,
      orderXref: null,
      isMedicalPackageOrder: false,
      isReordered: true
    } as RadiologyFormDataModel;
  }
}

interface IRadiologyGroupOrderForm {
  group: ICatalogGroup | FormControl;
  department: IDepartmentMinimal | FormControl;
  doctor: IDoctor | FormControl;
}

export class RadiologyGroupOrderForm implements IRadiologyGroupOrderForm {
  group = new FormControl(null, [objectValidator]);
  department = new FormControl(null, [Validators.required, objectValidator]);
  doctor = new FormControl(null, [Validators.required, objectValidator]);
  updatedAt = new FormControl();
}

export class RadiologyGroupOrderFormDataModel extends ConcurrencyRequest implements IRadiologyGroupOrderForm {
  group: ICatalogGroup;
  department: IDepartmentMinimal;
  doctor: IDoctor;
  updatedAt: string
}
