export enum AppointmentStatusEnum {
  DRAFT = 'Draft',
  CHECK_IN = 'Check In',
  CHECKED_IN = 'Checked In',
  ADMITTED = 'Admitted',
  COMPLETED = 'Completed',
  CANCELLED = 'Canceled',
  SCHEDULED = 'Scheduled',
  RE_SCHEDULED = 'Rescheduled',
  NO_SHOW = 'No show',
  COMPLETE_TRANSFER = 'Complete-Transfer',
  ACCEPTED = 'Accepted',
  REQUESTED = 'Requested',
  TRIAGE = 'Triage',
  TRIAGE_SCHEDULED = 'Triage-Scheduled',
  BOOKED = 'Booked',
  PENDING = 'Pending',
  IN_PROGRESS = 'In Progress'
}

export enum DoctorRequestStatusEnum {
  REQUESTED = 'Requested',
  ACCEPTED = 'Accepted',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  CANCELED = 'Canceled'
}
