// region View Model
import {DOCTOR_PREFIX, getName} from '@v2/core/functions/functions';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {IJointAdmissionRequest} from '@v2/core/models/masterdata/IPatientManagementRequest';

export class JointAdmissionViewModel extends ConcurrencyRequest {
  id: number;
  departmentName: string;
  departmentId: string;
  doctorName: string;
  doctorId: string;
  doctorRole: string;
  doctorRoleId: number;
  jointDate: string;
  doctorRequestStatus: string;
  note: string;

  static mapFromApiModel(jointAdmissionData: IJointAdmissionRequest): JointAdmissionViewModel {
    const viewData = new JointAdmissionViewModel();
    viewData.id = jointAdmissionData.jointDoctorId;
    viewData.departmentName = jointAdmissionData.department.name;
    viewData.departmentId = jointAdmissionData.department.id;
    viewData.doctorName = getName(jointAdmissionData.doctor.firstName, jointAdmissionData.doctor.lastName, DOCTOR_PREFIX);
    viewData.doctorId = jointAdmissionData.doctor.id;
    viewData.doctorRole = jointAdmissionData.doctorRole.label;
    viewData.doctorRoleId = jointAdmissionData.doctorRole.key;
    viewData.jointDate = jointAdmissionData.joinDate;
    viewData.doctorRequestStatus = jointAdmissionData.doctorRequestStatus ? jointAdmissionData.doctorRequestStatus.label : '';
    viewData.note = jointAdmissionData.note;
    viewData.mapConcurrencyRequest(jointAdmissionData);
    return viewData;
  }
}
