import {IBillingAppointment, IDoctor} from '@v2/core/models/masterdata';

export class TransferViewModel {
  constructor(
    public id: string,
    public aptDateTime: string,
    public departmentId: string,
    public departmentName: string,
    public doctorId: string,
    public doctorName: string,
    public note: string,
    public nurseStationId: string,
    public nurseStationName: string,
    public serviceType: number,
    public serviceTypeName: string,
  ) {
  }

  static mapFromApiModel(apiData: IBillingAppointment): TransferViewModel {
    if (!!apiData) {
      return new TransferViewModel(
        apiData.id,
        apiData.scheduleDate,
        apiData.department && apiData.department.id,
        apiData.department && apiData.department.name,
        apiData.doctor && apiData.doctor.id,
        this.getDoctorName(apiData.doctor),
        apiData.note,
        apiData.nurseStation && apiData.nurseStation.id,
        apiData.nurseStation && apiData.nurseStation.name,
        apiData.serviceType && apiData.serviceType.key,
        apiData.serviceType && apiData.serviceType.label
      )
    } else {
      return null;
    }
  }

  static getDoctorName(doctor: IDoctor): string {
    if (doctor && doctor.lastName) {
      return `Dr. ${doctor.firstName} ${doctor.lastName}`
    } else {
      return `Dr. ${doctor.firstName}`
    }
  }
}
