import {Component, Input, OnInit} from '@angular/core';
import {IPatient} from '@v2/core/models/masterdata';
import {AgeDisplayFormatEnum} from './age-display-format.enum';

@Component({
  selector: 'app-age-display',
  templateUrl: './age-display.component.html',
  styleUrls: ['./age-display.component.scss']
})
export class AgeDisplayComponent implements OnInit {

  @Input() ageOnDate: string;
  @Input() age: number;
  @Input() displayFormat: AgeDisplayFormatEnum = AgeDisplayFormatEnum.NONE;
  @Input() tooltipDisplayFormat: AgeDisplayFormatEnum = AgeDisplayFormatEnum.FULL;
  @Input() patientDOBInfo: Partial<IPatient>;
  @Input() displayInlineContent = true;

  constructor() {
  }

  ngOnInit() {
  }

}
