import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-date-display',
  template: `
      {{ date | date: 'dd-MM-yyyy'  | isEmpty}}
  `
})
export class DateDisplayComponent {

  @Input() date: string;

}
