export enum PaymentModeEnum {
  cash = 'Cash',
  card = 'Card',
  other = 'Other',
  bankTransfer = 'Bank Transfer',
  cheque = 'Cheque',
  wallet = 'Patient Wallet',
  debitWallet = 'Wallet - Debit (Pay)',
  creditWallet = 'Wallet - Credit (Top-up)'
}

export enum PatientWalletTransactionTypeEnum {
  ADJUSTED = 'Adjusted',
  BILL_CANCELED = 'Bill Canceled',
  BILL_CANCELED_CREDITED = 'Bill Canceled-Credited',
  BILL_CANCELED_DEBITED = 'Bill Canceled-Debited',
  DEBITED = 'Debited',
  CREDITED = 'Credited'
}
