import {HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {UtilityService} from '@v2/core/services/utility.service';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {CommonEnum} from '../../shared/enums';
import {FORBIDDEN_URL} from '../../shared/layout/main-menu-routes';
import {GlobalErrorHandlerService} from '../../v2/shared-v2/components/error-handler/global-error-handler.service';
import {AuthService, JwtService} from '../services';
import {IpAddressService} from '../services/ip-address.service';

interface Header {
  'Content-Type'?: string;
  Accept?: string;
  Authorization?: string;
  'X-Forwarded-For'?: string;

  [name: string]: string;
}

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private jwtService: JwtService,
    private authService: AuthService,
    private utilityService: UtilityService,
    private globalErrorHandlerService: GlobalErrorHandlerService,
    private ipAddressService: IpAddressService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const regex = /oauth/g;
    const configApi = /api\/init/g;

    if (!req.url.match(regex) && !req.url.match(configApi)) {

      const headersConfig: Header = {};

      // Clone HttpParams to allow modification
      let paramsClone = new HttpParams({fromString: req.params.toString()});

      headersConfig['Content-Type'] = 'application/json';
      headersConfig['X-Forwarded-For'] = this.ipAddressService.getIpv4();

      if (paramsClone.has(CommonEnum.FileUploadParam) || paramsClone.has(CommonEnum.uploadLogo)) {
        paramsClone = paramsClone.delete(CommonEnum.FileUploadParam);
        paramsClone = paramsClone.delete(CommonEnum.uploadLogo);
        delete headersConfig['Content-Type'];
      }

      const token = this.jwtService.getToken();

      if (token) {
        headersConfig.Authorization = `Bearer ${token}`;
      }

      // Add extra params
      paramsClone = paramsClone.append('apiVersion', '1.0');

      // Clone the request with params instead of setParams
      const request = req.clone({
        params: paramsClone,
        setHeaders: headersConfig
      });

      return next.handle(request).pipe(catchError(err => {

        switch (err.status) {

          case 401: {
            const errMessage = this.utilityService.getFirstErrorMessage(err.error.data, 'Unauthorized access');
            const errorMessage = !!token ? 'Your session has expired' : errMessage;
            this.authService.flushTokenAndUserInfo();
            this.dialog.closeAll();
            this.router.navigate(['/login']).then(() => this.snackBar.open(errorMessage, 'Ok'));
            return EMPTY;
          }

          case 403: {
            this.dialog.closeAll();
            this.router.navigate([FORBIDDEN_URL]).then(() => {
              this.snackBar.open('Forbidden access', 'Close');
              this.authService.setupUserAndPermission().subscribe();
            });
            return EMPTY;
          }

          case 413: {
            this.snackBar.open('Request Entity Too Large', 'Close');
            break;
          }
        }

        return throwError(err);
      }));

    } else {
      const request = req.clone({});
      return next.handle(request);
    }
  }
}
