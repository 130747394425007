import {Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {MAT_DATE_FORMATS} from '@angular/material';
import {MatDatepicker} from '@angular/material/datepicker';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {Subscription} from 'rxjs';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-date-month-year',
  templateUrl: './date-month-year.component.html',
  styleUrls: ['./date-month-year.component.scss'],
  providers: [
    {provide: TRANSLOCO_SCOPE, useValue: 'common'},
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateMonthYearComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateMonthYearComponent),
      multi: true,
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})


export class DateMonthYearComponent implements ControlValueAccessor, OnDestroy, OnInit {
  subscriptions: Subscription[] = [];
  monthYear = new FormControl('', [Validators.required]);
  today = new Date();
  monthYearValidation = [{type: 'required', message: 'Month/Year is required'}];

  constructor() {
    this.subscriptions.push(
      this.monthYear.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnInit(): void {
    // this.value = this.monthYear.value;
  }

  get value(): any {
    return this.monthYear.value;
  }

  set value(value: any) {
    // const date = moment(value).tz('UTC');
    this.monthYear.setValue(value);
    this.onChange(value);
    this.onTouched();
  }


  chosenYearHandler(event) {
    try {
      this.value = event;
    } catch (ex) {
    } finally {

    }

  }

  chosenMonthHandler(event, datepicker: MatDatepicker<any>) {
    try {
      this.value = event;
    } catch (ex) {
    } finally {
      datepicker.close();
    }
  }


  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    } else {
      this.value = null;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.monthYear.valid ? null : {invalid: true};
  }

}
