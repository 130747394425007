import {Directive, Input} from '@angular/core';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {ICommonFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {noop} from 'rxjs';

@Directive({
  selector: '[appInfiniteScroll]'
})
export class InfiniteScrollDirective {

  @Input() callBackFunc: () => void;
  @Input() elementReference: MatAutocomplete | MatSelect
  @Input() pageFilters: ICommonFilters
  @Input() increaseLastIndex: boolean = false

  panelElement: HTMLDivElement
  lastActiveIndex: number;
  lastIndex: number;

  constructor() {
  }

  setActiveItem(searchTextChanged) {
    this.lastActiveIndex = this.getActiveItemIndex();
    if (!searchTextChanged) {
      setTimeout(() => {
        this.elementReference._keyManager.setActiveItem(this.lastActiveIndex);
      }, 50);
    }
  }

  checkForLastIndex() {
    this.lastIndex = (this.pageFilters.page * +this.pageFilters.filters.page_size - 1);
    if (this.increaseLastIndex) {
      this.lastIndex += 1;
    }
    this.lastActiveIndex = this.getActiveItemIndex();
    if (this.lastIndex === this.lastActiveIndex) {
      setTimeout(() => {
        this.panelElement.scrollTop = this.panelElement.scrollHeight;
      }, 0);
    }
  }

  getActiveItemIndex() {
    return this.elementReference._keyManager.activeItemIndex;
  }

  open() {
    if (this.elementReference) {
      const panel = this.elementReference.panel;
      if (panel) {
        this.panelElement = panel.nativeElement as HTMLDivElement;
        this.panelElement.addEventListener('scroll', () => {
          if (this.panelElement.offsetHeight + this.panelElement.scrollTop >= (this.panelElement.scrollHeight - 20)) {
            this.callBackFunc();
          }
        })
      }
    }
  }

  close() {
    if (this.panelElement) {
      this.panelElement.removeEventListener('scroll', noop);
    }
  }
}
