import {Injectable} from '@angular/core';
import {getStartOfDay, setFilter} from '@v2/core/functions/functions';
import {IBase, IBookedNextAppointment, IData} from '@v2/core/models/masterdata';
import {ICustomFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {IPatientOrder} from '@v2/core/models/masterdata/IPatientOrder.master-data';
import {BookedAppointmentPatientInfoViewModel, BookedAppointmentViewModel} from '@v2/core/models/view/appointment.view.model';
import {BaseHttpService} from '@v2/core/services/base-http.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, pluck} from 'rxjs/operators';
import {AppointmentHttpService} from '../../resources/http-service/appointment.http.service';
import {NextAppointmentPayload} from './resources/models/payload/next-appointment.payload';
import {FuturePatientOrderViewModel, IAdmissionAppointmentExistInfo} from './resources/models/view/next-appointment.model';

@Injectable()
export class NextAppointmentService {

  private _admitRequestError = new BehaviorSubject<IAdmissionAppointmentExistInfo>({isAdmissionReqExists: false});

  constructor(
    private appointmentHttpService: AppointmentHttpService,
    private baseHttpService: BaseHttpService
  ) {
  }

  setAdmitRequestError(admitRequestInfo: IAdmissionAppointmentExistInfo): void {
    this._admitRequestError.next(admitRequestInfo);
  }

  getAdmitRequestError(): Observable<IAdmissionAppointmentExistInfo> {
    return this._admitRequestError.asObservable();
  }

  postNextAppointment(appointmentId: string, nextAppointmentData: NextAppointmentPayload): Observable<BookedAppointmentViewModel> {
    return this.baseHttpService.post<IBase<IBookedNextAppointment>>(
      `appointment/${appointmentId}/next_appointment`,
      nextAppointmentData
    ).pipe(
      pluck('data'),
      map(appointment => BookedAppointmentViewModel.mapFromHttpModel(appointment, appointmentId))
    );
  }

  getFuturePatientOrders(patientId: string): Observable<FuturePatientOrderViewModel[]> {
    return this.baseHttpService.get<IBase<IData<IPatientOrder>>>(
      `patient/${patientId}/patient-future-orders/${getStartOfDay()}`
    ).pipe(
      pluck('data', 'items'),
      map(orders => orders.map(order => FuturePatientOrderViewModel.mapFromApiModel(order))),
      map(orders => orders.sort((a, b) => (new Date(a.testDateTime) as any) - (new Date(b.testDateTime) as any)))
    );
  }

  getBookedAppointment(patientId: string, filters: ICustomFilters = {}, currentAppointmentId?: string): Observable<BookedAppointmentViewModel[]> {
    return this.baseHttpService.get(`patient/${patientId}/appointments`, setFilter(filters)).pipe(
      pluck('data'),
      map((bookedAppointmentArrayApiData: IBookedNextAppointment[]) => {
        return bookedAppointmentArrayApiData.map(bookedAppointment => BookedAppointmentViewModel.mapFromHttpModel(bookedAppointment, currentAppointmentId))
      })
    )
  }

  getAppointmentInfo(appointmentId: string, filters: ICustomFilters = {}, fetchFromApi = false): Observable<BookedAppointmentPatientInfoViewModel> {
    return this.appointmentHttpService.getAppointmentInfoV2(appointmentId, filters, fetchFromApi).pipe(
      pluck('data'),
      map(response => BookedAppointmentPatientInfoViewModel.mapFromAppointmentHttpModel(response))
    );
  }

  unlinkOPDAppointmentFromAdmission(appointmentId: string, admissionApptId: string): Observable<boolean> {
    return this.baseHttpService.patch<IBase<{ status: boolean }>>(`v2/appointment/${appointmentId}/unlink-opd-appt`, {admissionApptId}).pipe(
      pluck('data', 'status')
    );
  }

  linkOPDAppointmentWithAdmission(appointmentId: string, admissionApptId: string): Observable<boolean> {
    return this.baseHttpService.patch<IBase<{ status: boolean }>>(`v2/appointment/${appointmentId}/link-opd-appt`, {admissionApptId}).pipe(
      pluck('data', 'status')
    );
  }
}
