export function toFormData(obj: object, form = null, namespace = null) {
  const formData: FormData = form || new FormData();
  let formKey: string;

  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        toFormData(obj[property], formData, property);
      } else {
        // if it's a string or a File object
        formData.append(formKey, obj[property]);
      }
    }
  }

  return formData;
}
