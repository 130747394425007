import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {FORBIDDEN_URL} from '../../shared/layout/main-menu-routes';
import {CurrentAppointmentInfoService} from '../../visits/shared/services/current-appointment-info.service';
import {DR_IPD_PD} from '../constants/sub-menu.constant';

@Injectable()
export class PatientDataAuthGuard implements CanActivate {
  // subject = new Subject<AppSecurityRoles>();
  constructor(
    private currentAppointmentInfoService: CurrentAppointmentInfoService,
    private router: Router,
    private location: Location) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> {
    return new Promise<boolean>(((resolve, reject) => {
      const {appointmentId, doctorId} = route.queryParams;
      this.currentAppointmentInfoService.canDischargeOrTransfer(appointmentId, doctorId)
        .pipe(take(1))
        .subscribe(response => {
          const hasPermission = !!DR_IPD_PD.find(x => x.url === route.routeConfig.path && x.allowedRoles.includes(response.role));
          if (!hasPermission) {
            this.router.navigate([FORBIDDEN_URL]);
            resolve(false);
          }
          resolve(true);
        });
    }));
  }
}
