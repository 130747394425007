import {ChangeDetectorRef, Inject, OnDestroy, Optional, Pipe, PipeTransform} from '@angular/core';
import {MaybeArray, TRANSLOCO_LANG, TRANSLOCO_SCOPE, TranslocoScope, TranslocoService} from '@ngneat/transloco';
import {TranslationLanguageEnum} from '@v2/core/enums/translation-language.enum';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/internal/operators/take';

interface IncomeTypeTranslation {
  name: string;
  thaiIncomeType: string;
}

@Pipe({
  name: 'incomeTypeTranslation',
  pure: false // required to update the value when language is changed
})
export class IncomeTypeTranslationPipe implements PipeTransform, OnDestroy {

  private subscription: Subscription | null = null;
  private lastValue = '';
  private lastKey: string | undefined;
  private listenToLangChange: boolean;

  constructor(
    private translocoService: TranslocoService,
    @Optional() @Inject(TRANSLOCO_SCOPE) private providerScope: MaybeArray<TranslocoScope>,
    @Optional() @Inject(TRANSLOCO_LANG) private providerLang: string | null,
    private cdr: ChangeDetectorRef
  ) {
    this.listenToLangChange = this.shouldListenToLangChanges(this.translocoService, this.providerLang);
  }

  transform(key: IncomeTypeTranslation): string {
    if (!key) {
      return '-';
    }

    const keyName = key.name;

    if (keyName === this.lastKey) {
      return this.lastValue;
    }

    this.lastKey = keyName;
    this.unsubscribe();

    this.subscription = this.translocoService.langChanges$.pipe(
      this.listenOrNotOperator(this.listenToLangChange)
    ).subscribe((activeLang: string) => {
      this.updateValue(key, activeLang)
    });

    return this.lastValue;
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  private unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private updateValue(key: IncomeTypeTranslation, activeLang: string) {
    this.lastValue = this.resolveValue(key, activeLang);
    this.cdr.markForCheck();
  }

  private resolveValue(value: IncomeTypeTranslation, activeLanguage: string): string {
    if (!activeLanguage || !value) {
      return null;
    }
    switch (activeLanguage) {
      case TranslationLanguageEnum.ENGLISH:
        return value.name ? value.name : value.thaiIncomeType;
      case TranslationLanguageEnum.THAI:
        return value.thaiIncomeType ? value.thaiIncomeType : value.name;
      default:
        return value.name;
    }
  }

  private listenOrNotOperator(listenToLangChange: boolean) {
    return listenToLangChange ? source => source : take(1);
  }

  private getPipeValue(str: string, value: string, char = '|'): [boolean, string] {
    if (typeof str === 'string') {
      const splitted = str.split(char);
      const lastItem = splitted.pop();
      return lastItem === value ? [true, splitted.toString()] : [false, lastItem];
    }

    return [false, ''];
  }

  private shouldListenToLangChanges(service: TranslocoService, lang: string) {
    const [hasStatic] = this.getPipeValue(lang, 'static');
    if (hasStatic === false) {
      // If we didn't get 'lang|static' check if it's set in the global level
      return service.config.reRenderOnLangChange;
    }

    // We have 'lang|static' so don't listen to lang changes
    return false;
  }

}
