import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {getModalWidth} from '@v2/core/functions/functions';
import {PaymentTypeEnum} from '../../../../../v2/shared-v2/resources/enums/payment-type.enum';
import {PaymentPlanDetailModalComponent} from '../../payment-type-plan-chip/payment-plan-detail-modal/payment-plan-detail-modal.component';

@Component({
  selector: 'app-payment-plan-single-chip',
  templateUrl: './payment-plan-single-chip.component.html',
  styleUrls: ['./payment-plan-single-chip.component.scss'],
})
export class PaymentPlanSingleChipComponent implements OnInit {

  @Input() paymentPlanId: string;
  @Input() paymentPlanType: string;
  @Input() priority: number;
  @Input() shortName: string;
  @Input() appointmentId: string

  constructor(
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
  }

  showPaymentPlanDetails() {
    if (this.paymentPlanType === PaymentTypeEnum.selfPay) {
      return;
    }
    const [modalWidth] = getModalWidth(0);
    this.dialog.open(PaymentPlanDetailModalComponent, {
      panelClass: 'myapp-no-padding-dialog',
      width: modalWidth,
      data: {
        paymentPlanId: this.paymentPlanId,
        appointmentId: this.appointmentId
      }
    });
  }

}
