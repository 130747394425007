import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PatientNameTranslation, PatientNameTranslationV2} from '@v2/core/functions/patient-name-translation';

@Component({
  selector: 'app-profile-pic',
  templateUrl: './profile-pic.component.html',
  styleUrls: ['./profile-pic.component.scss']
})
export class ProfilePicComponent implements OnInit, OnChanges {
  @Input() imageSrc;
  @Input() disableImageLazyLoading = false;
  @Input() width = 45;
  @Input() height: any = 45;
  @Input() borderRadius = 13;
  @Input() profileClass: string;
  @Input() imgClass: string;
  @Input() name: PatientNameTranslation | PatientNameTranslationV2;
  @Input() useV2TranslationPipe = false;

  isProfilePic = false;

  constructor() {
  }

  ngOnInit() {
    this.setProfilePic();
  }

  setProfilePic() {
    if (this.imageSrc) {
      const profilePic = new Image();
      profilePic.src = this.imageSrc;
      profilePic.onerror = () => {
        this.isProfilePic = false;
      };
      this.isProfilePic = true;
    } else {
      this.isProfilePic = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.imageSrc && !changes.imageSrc.firstChange) {
      this.setProfilePic();
    }
  }
}
