import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';

@Component({
  selector: 'app-signature-display',
  templateUrl: './signature-display.component.html',
  styleUrls: ['./signature-display.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'common'}]
})
export class SignatureDisplayComponent implements OnInit {

  @Input() signature: any;
  @Input() showReset: boolean;
  @Input() blockBorderNone: boolean = false;
  @Input() controlTitle: string;
  @Input() imageWrapperClass: string = '';
  @Input() imageLoadingStrategy: 'lazy' | 'eager' = 'lazy';

  @Output() resetSignature = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  resetSign() {
    this.resetSignature.emit();
  }

}
