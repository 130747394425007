// confirm with your route before use
export const PD_INITIALS = {
  nsVisit: '/opd/nurse/patient-data/appointment/add/visit',
  nsAdmission: '/ipd/nurse/patient-data/appointment/add/admission',
  drVisit: '/opd/doctor/patient-data/appointment/add/visit',
  drAdmission: '/ipd/doctor/patient-data/appointment/add/admission'
}

export const ARCHIVE_VISIT_PD_PR_ROUTES = {
  patientProfile: 'patient-profile',
  patientData: 'patient-data',
  patientOrder: 'patient-order',
}

export const ARCHIVE_ADMISSION_PD_PR_ROUTES = {
  patientProfile: 'patient-profile',
  patientData: 'patient-data',
  patientOrder: 'patient-order',
}

export const ADMISSION_PD_PR_ROUTES = {
  opdSummary: 'opd-summary',
  admissionNote: 'admission-note',
  vitals: 'vitals',
  nurseNote: 'nurse-note',
  progressNote: 'progress-note',
  patientOrder: 'patient-order',
  diagnosis: 'diagnosis',
  treatment: 'treatment',
  nextAppointment: 'next-appointment',
  serviceBilling: 'service-billing',
  prescriptionOnDischarge: 'prescription-on-discharge',
  discharge: 'discharge',
  reports: 'reports',
  orSurgicalIns: 'or-surgical-ins',
  insuranceForm: 'insurance-form',
  medicalCertificate: 'medical-certificate',
  patientManagement: 'patient-management',
  medicalRecords: 'medical-records',
}

export const VISIT_PD_PR_ROUTES = {
  vitalsChiefComplaint: 'vitals-chief-complaint',
  nurseNote: 'nurse-note',
  physicalExamination: 'physical-examination',
  physicalExamination_physicalExamination: 'physical-examination',
  physicalExamination_systemicReview: 'systemic-review',
  patientOrder: 'patient-order',
  diagnosis: 'diagnosis',
  opdTreatment: 'opd-treatment',
  prescription: 'prescription',
  reports: 'reports',
  doctorNote: 'doctor-note',
  serviceBilling: 'service-billing',
  discharge: 'discharge',
  orSurgicalIns: 'or-surgical-ins',
  nextAppointment: 'next-appointment',
  insuranceForm: 'insurance-form',
  medicalCertificate: 'medical-certificate',
  consultationTransfer: 'consultation-transfer',
  medicalRecords: 'medical-records',
  emr: 'emr',
}
