import {IDepartment, IMasterData} from "@v2/core/models/masterdata";
import {ISoldBy} from "@v2/core/models/masterdata/ISoldBy";

export class SoldByViewModel {
  xref: number
  staffIduuid: string
  staffFullName: string
  firstName: string
  middleName: any
  lastName: string
  department: IDepartment
  employmentStartDate: string
  employmentEndDate: string
  isDoctor: number
  isMedicalParty: boolean
  staffType: IMasterData

  static mapFromHttpModel(item: ISoldBy): SoldByViewModel {
    const viewModel = new SoldByViewModel();
    viewModel.staffIduuid = item.staffIduuid ? item.staffIduuid : null;
    viewModel.xref = item.staffId
    viewModel.staffFullName = item.staffName
    viewModel.firstName = item.staffFirstName
    viewModel.middleName = item.staffMiddleName
    viewModel.lastName = item.staffLastName
    viewModel.employmentStartDate = item.employmentStartDate
    viewModel.employmentEndDate = item.employmentEndDate
    viewModel.isDoctor = item.isDoctor
    viewModel.isMedicalParty = item.isMedicalParty
    viewModel.staffType = item.staffType

    return viewModel;
  }
}
