import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-form-add-button',
  templateUrl: './form-add-button.component.html',
  styleUrls: ['./form-add-button.component.scss']
})
export class FormAddButtonComponent implements OnInit {
  @Input() trigger = false;
  @Input() type = 'submit';
  @Output() clickEvent = new EventEmitter();

  constructor() {
  }

  // tslint:disable-next-line:variable-name
  private _disableClass: boolean;

  get disableClass(): boolean {
    return this._disableClass
  }

  @Input() set disabled(value: boolean) {
    this._disableClass = value
  };

  ngOnInit() {
  }

  onClick(event) {
    this.clickEvent.emit(event);
  }

}
