import {Injectable} from '@angular/core';
import {
  DepartmentNsValidationHttpModel,
  IAppointmentCompletionStatusAtStation,
  IBase,
  IDischargeInitiationInfo,
  IForwardToDoctorInfo,
  IPrimaryDoctorCheckerHttpModel
} from '@v2/core/models/masterdata';
import {IPatientManagementRequestStatus} from '@v2/core/models/masterdata/IPatientManagementRequest';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, pluck} from 'rxjs/operators';
import {BaseHttpService} from '../../base-http.service';
import {BaseModel} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ValidationHttpService {

  dischargeInfo$ = new BehaviorSubject<IDischargeInitiationInfo>(null);

  constructor(private baseHttpService: BaseHttpService) {
  }

  validateDepartmentForNursingStation(deptId: string): Observable<BaseModel<DepartmentNsValidationHttpModel>> {
    return this.baseHttpService.get<BaseModel<DepartmentNsValidationHttpModel>>(`department-validate/${deptId}/opd-appt`);
  }

  isAppointmentCompleted(appointmentId: string): Observable<BaseModel<IAppointmentCompletionStatusAtStation>> {
    return this.baseHttpService.get<BaseModel<IAppointmentCompletionStatusAtStation>>(`appointment/${appointmentId}/is-completed-at-station`);
  }

  isForwardedToDoctor(nurseId: string, appointmentId: string): Observable<BaseModel<IForwardToDoctorInfo>> {
    return this.baseHttpService.get<BaseModel<IForwardToDoctorInfo>>(`opd/ns/${nurseId}/appointment/${appointmentId}/forward-to-doctor`)
  }

  canDischargeOrTransfer(appointmentId: string, doctorId: string): Observable<IPrimaryDoctorCheckerHttpModel> {
    return this.baseHttpService.get<BaseModel<IPrimaryDoctorCheckerHttpModel>>(
      `appointment/${appointmentId}/doctor/${doctorId}/can-discharge-or-transfer`,
    ).pipe(
      pluck('data')
    );
  }

  checkConsultationAppointmentStatus(doctorId: string, appointmentId: string, eventId: string): Observable<IPatientManagementRequestStatus> {
    return this.baseHttpService.get<IBase<IPatientManagementRequestStatus>>(`v2/ipd/doctor/${doctorId}/appointment/${appointmentId}/consultation/${eventId}/doc-status`).pipe(
      pluck('data')
    );
  }

  isDischargeInitiated(appointmentId: string): Observable<IDischargeInitiationInfo> {
    return this.baseHttpService.get<IBase<IDischargeInitiationInfo>>(`appointment/${appointmentId}/is-initiated-at-station`).pipe(
      pluck('data'),
      catchError(() => of({isInitiatedByDoctor: true, isInitiatedByNurse: true, isBillFinalized: true} as IDischargeInitiationInfo))
    );
  }

  setDischargeInitiatedInfo(info: IDischargeInitiationInfo) {
    this.dischargeInfo$.next(info);
  }
}
