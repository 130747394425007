export enum BedTransferRequestStatusEnum {
  OPEN = 'Open',
  RELEASED = 'Released',
  TRANSFERRED = 'Transferred',
  ADDED = 'Added',
  CANCELED = 'Canceled',
  CANCELLED = 'Cancelled',
  COMPLETED = 'Completed',
  IN_PROGRESS = 'In-progress'
}
