import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {APP_SETTINGS} from './setting';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  config: {};
  baseUrl: string;
  apiPrefix: string;
  baseUrlmoke: string;
  endpoint: string;

  constructor(
    public http: HttpClient
  ) {
    this.baseUrl = APP_SETTINGS.api_url;
    this.apiPrefix = APP_SETTINGS.api_prefix;

    // this.baseUrl = 'http://localhost:8000/';
    // this.apiPrefix = '';

  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }


  public get<T>(endPoint: string, itemType: any, params: HttpParams = new HttpParams()): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}${this.apiPrefix}${endPoint}`, {params})
      .pipe(
        map((response: any) => {
          return response.data;
        })
      );
  }


  getSingle(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${this.baseUrl}${this.apiPrefix}${path}`, {params}).pipe(catchError(this.formatErrors));
  }

  public getAll<T>(endpoint, itemType: any, params: HttpParams = new HttpParams()): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}${this.apiPrefix}${endpoint}`, {params})
      .pipe(
        map((response: any) => {

          const {data: {items, ...pagination}} = response;
          // const responseData = items.map((item: any) => {
          //   return new ModelMapper(itemType).map(item);
          // });
          return {
            items,
            ...pagination
          };
        }));
  }

  put<T>(path: string, modelObject: T | any): Observable<any> {
    const formData = {
      data: modelObject
    };
    return this.http.put(`${this.baseUrl}${this.apiPrefix}${path}`,
      JSON.stringify(formData)).pipe(catchError(this.formatErrors));
  }

  patch<T>(path: string, modelObject: T | any): Observable<any> {
    const formData = {
      data: modelObject
    };
    return this.http.patch(`${this.baseUrl}${this.apiPrefix}${path}`,
      JSON.stringify(formData)).pipe(catchError(this.formatErrors));
  }


  post<T>(path: string, modelObject: T | any, httpOptions?): Observable<any> {

    const formData = {
      data: modelObject
    };

    return this.http.post(`${this.baseUrl}${this.apiPrefix}${path}`,
      JSON.stringify(formData), httpOptions).pipe(catchError(this.formatErrors));
  }

  delete<T>(path): Observable<any> {
    return this.http.delete(`${this.baseUrl}${this.apiPrefix}${path}`).pipe(catchError(this.formatErrors));
  }


}
