export enum ConcurrencyScopeEnum {

  PATIENT_CHECK_IN = 'PATIENT_CHECK_IN',
  PATIENT_NOTE_ALERT = 'PATIENT_NOTE_ALERT',
  PATIENT_NOTE_ALERT_INACTIVATE = 'PATIENT_NOTE_ALERT_INACTIVATE',

  DOCTOR_OPD_ACCEPT_PATIENT = 'DOCTOR_OPD_ACCEPT_PATIENT',

  // <editor-fold desc="NURSE IPD">
  NURSE_NOTE = 'note',
  FORWARD_TO_DOCTOR = 'FORWARD_TO_DOCTOR',
  VITALS = 'VITALS',
  CHIEF_COMPLAINTS = 'CHIEF_COMPLAINTS',
  PRESENT_ILLNESS = 'PRESENT_ILLNESS',

  APPOINTMENT_STATUS = 'APPOINTMENT_STATUS',

  PATIENT_ORDER = 'PATIENT_ORDER',
  RADIOLOGY_PATIENT_ORDER = 'RADIOLOGY_PATIENT_ORDER',
  PATHOLOGY_PATIENT_ORDER = 'PATHOLOGY_PATIENT_ORDER',
  PROCEDURE_PATIENT_ORDER = 'PROCEDURE_PATIENT_ORDER',
  MEDICATION_PATIENT_ORDER = 'MEDICATION_PATIENT_ORDER',
  SUPPLY_PATIENT_ORDER = 'SUPPLY_PATIENT_ORDER',
  NUTRITION_MEAL_PATIENT_ORDER = 'NUTRITION_MEAL_PATIENT_ORDER',
  DOCTOR_FEE_PATIENT_ORDER = 'DOCTOR_FEE_PATIENT_ORDER',

  PATIENT_ORDER_FAVORITES = 'PATIENT_ORDER_FAVORITES',
  MEDICAL_PACKAGE_SUBMIT = 'MEDICAL_PACKAGE_SUBMIT',
  MEDICAL_PACKAGE_REMOVE = 'MEDICAL_PACKAGE_REMOVE',
  MEDICAL_PACKAGE_SUBMIT_ORDER = 'MEDICAL_PACKAGE_SUBMIT_ORDER',
  PROCEDURE_COMPLY = 'PROCEDURE_COMPLY',
  OPD_TREATMENT = 'OPD_TREATMENT',
  OR_REQUEST = 'OR_REQUEST',
  CONSULTATION = 'CONSULTATION',
  MEDICAL_RECORDS = 'MEDICAL_RECORDS',
  MEDICAL_CODING_PATIENT_ORDER = 'MEDICAL_CODING_PATIENT_ORDER',
  MEDICAL_CODING_DIAGNOSIS = 'MEDICAL_CODING_DIAGNOSIS',
  MEDICAL_CODING_TREATMENT = 'MEDICAL_CODING_TREATMENT',
  MEDICAL_CODING_CHANGE_STATUS = 'MEDICAL_CODING_CHANGE_STATUS',

  MEDICAL_PACKAGE_CANCEL_ORDER = 'MEDICAL_PACKAGE_CANCEL_ORDER',
  MEDICAL_PACKAGE_DETACH_ORDER = 'MEDICAL_PACKAGE_DETACH_ORDER',
  MEDICAL_PACKAGE_CANCEL = 'MEDICAL_PACKAGE_CANCEL',
  MEDICAL_PACKAGE_PART_CANCEL = 'MEDICAL_PACKAGE_PART_CANCEL',
  MEDICAL_PACKAGE_CANCEL_IF_NOT_USED = 'MEDICAL_PACKAGE_CANCEL_IF_NOT_USED',
  // </editor-fold>

  PHYSICAL_EXAMINATION = 'PHYSICAL_EXAMINATION',
  SYSTEMIC_REVIEW = 'SYSTEMIC_REVIEW',
  DIAGNOSIS = 'DIAGNOSIS',
  DOCTOR_NOTE = 'DOCTOR_NOTE',
  FORWARD_TO_NS = 'FORWARD_TO_NS',
  INSURANCE_FORM = 'INSURANCE_FORM',
  MEDICAL_CERTIFICATE = 'MEDICAL_CERTIFICATE',

  VITALS_PULSE = 'VITALS_PULSE',
  VITALS_BODY_TEMP = 'VITALS_BODY_TEMP',
  VITALS_O2_SAT = 'VITALS_O2_SAT',
  VITALS_BLOOD_PRESSURE_SYSTOLIC = 'VITALS_BLOOD_PRESSURE_SYSTOLIC',
  VITALS_BLOOD_PRESSURE_DIASTOLIC = 'VITALS_BLOOD_PRESSURE_DIASTOLIC',
  VITALS_RESPIRATORY_RATE = 'VITALS_RESPIRATORY_RATE',
  VITALS_HCT = 'VITALS_HCT',
  VITALS_WEIGHT = 'VITALS_WEIGHT',
  VITALS_HEIGHT = 'VITALS_HEIGHT',
  VITALS_FLUID_INTAKE = 'VITALS_FLUID_INTAKE',
  VITALS_FLUID_OUTPUT = 'VITALS_FLUID_OUTPUT',
  VITALS_STOOLS = 'VITALS_STOOLS',
  VITALS_DM = 'VITALS_DM',
  VITALS_NOTE = 'VITALS_NOTE',

  PROGRESS_NOTE = 'PROGRESS_NOTE',
  TREATMENT_EXECUTION = 'TREATMENT_EXECUTION',
  TREATMENT_MEDICINE = 'TREATMENT_MEDICINE',
  TREATMENT_PROCEDURE = 'TREATMENT_PROCEDURE',
  DELETE_TREATMENT_PROCEDURE = 'DELETE_TREATMENT_PROCEDURE',
  SERVICE_BILLING = 'SERVICE_BILLING',
  PATIENT_MANAGEMENT = 'PATIENT_MANAGEMENT',
  PATIENT_MANAGEMENT_TRANSFER = 'PATIENT_MANAGEMENT_TRANSFER',
  TREATMENT_SPECIAL_INSTRUCTION = 'TREATMENT_SPECIAL-INSTRUCTION',
  DISCHARGE = 'DISCHARGE',
  FINALIZE_NURSE_DISCHARGE = 'FINALIZE_NURSE_DISCHARGE',
  REVERT_DISCHARGE_FINALIZATION = 'REVERT_DISCHARGE_FINALIZATION',
  PRESCRIPTION_ON_DISCHARGE = 'PRESCRIPTION_ON_DISCHARGE',
  PATIENT_ACCEPT = 'PATIENT_ACCEPT',
  START_CONSULTATION = 'START_CONSULTATION',
  END_CONSULTATION = 'END_CONSULTATION',
  JOINT_ADMISSION = 'JOINT_ADMISSION',
  CASE_TRANSFER = 'CASE_TRANSFER',
  BED_TRANSFER = 'BED_TRANSFER',
  CANCEL_BED_TRANSFER = 'CANCEL_BED_TRANSFER',

  ALLERGY_HISTORY = 'ALLERGY_HISTORY',
  ADDICTION_HISTORY = 'ADDICTION_HISTORY',
  CURRENT_MEDICATION = 'CURRENT_MEDICATION',
  PATIENT_HISTORY = 'PATIENT_HISTORY',
  VACCINATION = 'VACCINATION',

  PATIENT_PROFILE = 'PATIENT_PROFILE',
  PATIENT_ADD_PAYMENT = 'PATIENT_ADD_PAYMENT',
  PATIENT_REMOVE_PAYMENT = 'PATIENT_REMOVE_PAYMENT',
  PATIENT_TRANSFER_OPEN_APPOINTMENT_ORDERS_TO_SELF_PAY = 'PATIENT_TRANSFER_OPEN_APPOINTMENT_ORDERS_TO_SELF_PAY',
  PATIENT_APPOINTMENT_ACTIONS = 'PATIENT_APPOINTMENT_ACTIONS',
  TRIAGE = 'TRIAGE',
  BOOK_BED = 'BOOK_BED',
  ATTACH_PENDING_ORDER = 'ATTACH_PENDING_ORDER',
  PATIENT_DISCHARGE = 'PATIENT_DISCHARGE',

  HOSPITAL_BED = 'HOSPITAL_BED',
  PATIENT_ROOM = 'PATIENT_ROOM',
  HOSPITAL_WARD = 'HOSPITAL_WARD',

  BILLING_ADD_ITEM = 'BILLING_ADD_ITEM',
  BILLING_SERVICE = 'BILLING_SERVICE',
  BILLING_PIPELINE_MERGE_CLAIMS = 'BILLING_PIPELINE_MERGE_CLAIMS',
  BILLING_PAYMENT_DETAILS = 'BILLING_PAYMENT_DETAILS',
  BILLING_GENERATE_STATEMENT = 'BILLING_GENERATE_STATEMENT',
  BILLING_UPDATE_DAILY_BED_CHARGES = 'BILLING_UPDATE_DAILY_BED_CHARGES',
  FINALIZE_BILL = 'FINALIZE_BILL',
  BILLING_NOTE = 'BILLING_NOTE',
  ADVANCE_PAYMENT = 'ADVANCE_PAYMENT',
  REFUND_PAYMENT = 'REFUND_PAYMENT',
  BILLING_INVOICE_NOTE = 'BILLING_INVOICE_NOTE',
  BILLING_CANCEL_REAPPLY_CHARGE = 'BILLING_CANCEL_REAPPLY_CHARGE',
  BILLING_RECONCILE_PAID_BILL = 'BILLING_RECONCILE_PAID_BILL',
  BILLING_RECONCILE_FINALIZED_BILL = 'BILLING_RECONCILE_FINALIZED_BILL',
  BILLING_PRINT_RECEIPT = 'BILLING_PRINT_RECEIPT',

  PHARMACY = 'PHARMACY',
  PHARMACY_DRUG_ALLERGY = 'PHARMACY_DRUG_ALLERGY',
  DRUG_INTERACTION = 'DRUG_INTERACTION',
  PHARMACY_CATALOG_ACTION = 'PHARMACY_CATALOG_ACTION',
  PHARMACY_OUTLET = 'PHARMACY_OUTLET',
  RETURN_MEDICINE = 'RETURN_MEDICINE',
  VERIFY_MEDICINE = 'VERIFY_MEDICINE',
  PREPARE_MEDICINE = 'PREPARE_MEDICINE',
  DISPENSE_MEDICINE = 'DISPENSE_MEDICINE',
  PREPARE_ORDER = 'PREPARE_ORDER',
  PHARMACY_NOTE = 'PHARMACY_NOTE',

  // <editor-fold desc="Lab Enums">
  LAB_BACKLOG_CHECKIN = 'LAB_BACKLOG_CHECKIN',
  LAB_SAMPLE_COLLECTION = 'LAB_SAMPLE_COLLECTION',
  LAB_RESULT_UPLOAD = 'LAB_RESULT_UPLOAD',
  LAB_REPORT_ENTRY = 'LAB_REPORT_ENTRY',
  LAB_CATALOG = 'LAB_CATALOG',
  // </editor-fold>

  OR_UPDATE = 'OR_UPDATE',
  OR_SCHEDULE = 'OR_SCHEDULE',
  OR_RESCHEDULE = 'OR_RESCHEDULE',
  OR_ORDER_VIEW = 'OR_ORDER_VIEW',
  ANESTHESIA_NOTE = 'ANESTHESIA_NOTE',
  OR_RECOVERY_ROOM_TIME = 'OR_RECOVERY_ROOM_TIME',
  OR_INTRA_OPERATION_TIME = 'OR_INTRA_OPERATION_TIME',
  OR_POST_OPERATION_TIME = 'OR_POST_OPERATION_TIME',
  OR_INTRA_VITAL = 'OR_INTRA_VITAL',
  OR_POST_VITAL = 'OR_POST_VITAL',
  OR_SURGEON_NOTE = 'OR_SURGEON_NOTE',


  NUTRITION_MEAL_DELIVER_ORDER = 'NUTRITION_MEAL_DELIVER_ORDER',
  NUTRITION_MEAL_DELIVERY_NOTE = 'NUTRITION_MEAL_DELIVERY_NOTE',
  NUTRITION_MEAL_CATALOG_GROUP = 'NUTRITION_MEAL_CATALOG_GROUP',
  NUTRITION_MEAL_CATALOG_ELEMENT = 'NUTRITION_MEAL_CATALOG_ELEMENT',

  RADIOLOGY_BACKLOG_CHECKIN = 'RADIOLOGY_BACKLOG_CHECKIN',
  RADIOLOGY_PATIENT_INVESTIGATION = 'RADIOLOGY_PATIENT_INVESTIGATION',
  RADIOLOGY_RESULT_UPLOAD = 'RADIOLOGY_RESULT_UPLOAD',
  RADIOLOGY_REPORT_ENTRY = 'RADIOLOGY_REPORT_ENTRY',
  RADIOLOGY_CATALOG_ELEMENT = 'RADIOLOGY_CATALOG_ELEMENT',
  RADIOLOGY_CATALOG_GROUP = 'RADIOLOGY_CATALOG_GROUP',

  BED_MANAGEMENT_HOSPITAL_BED = 'BED_MANAGEMENT_HOSPITAL_BED',

  INVENTORY_TRANSFER_REQUEST = 'INVENTORY_TRANSFER_REQUEST',
  UPDATE_INVENTORY = 'UPDATE_INVENTORY',
  UPDATE_BATCH_DETAILS = 'UPDATE_BATCH_DETAILS',
  INVENTORY_FORCE_COMPLETE_PO = 'INVENTORY_FORCE_COMPLETE_PO',

  PROCUREMENT_CANCEL_PO = 'PROCUREMENT_CANCEL_PO',
  PROCUREMENT_FORCE_COMPLETE_PO = 'PROCUREMENT_FORCE_COMPLETE_PO',
  PROCUREMENT_ADD_PO = 'PROCUREMENT_ADD_PO',
  PROCUREMENT_PO_BACKLOG_SAVE_DRAFT = 'PROCUREMENT_PO_BACKLOG_SAVE_DRAFT',
  PROCUREMENT_PO_SEND_RFA = 'PROCUREMENT_PO_SEND_RFA',
  PROCUREMENT_PO_UPDATE_APPROVAL_STATUS = 'PROCUREMENT_PO_UPDATE_APPROVAL_STATUS',
  PROCUREMENT_PO_SEND_TO_VENDOR = 'PROCUREMENT_PO_SEND_TO_VENDOR',
  PROCUREMENT_PO_GENERATE_PO = 'PROCUREMENT_PO_GENERATE_PO',

  INVENTORY_RECEIVE_PO_ADD_INVENTORY = 'INVENTORY_RECEIVE_PO_ADD_INVENTORY',
  INVENTORY_CANCEL_PURCHASE_REQUEST = 'INVENTORY_CANCEL_PURCHASE_REQUEST',
  INVENTORY_PURCHASE_REQUEST_UPDATE_STATUS = 'INVENTORY_PURCHASE_REQUEST_UPDATE_STATUS',
  INVENTORY_PURCHASE_REQUEST_SEND_TO_RFA = 'INVENTORY_PURCHASE_REQUEST_SEND_TO_RFA',
  INVENTORY_UPDATE_PURCHASE_REQUEST = 'INVENTORY_UPDATE_PURCHASE_REQUEST',

  SUB_STOCK_FORCE_COMPLETE_TRANSFER_REQUEST = 'SUB_STOCK_FORCE_COMPLETE_TRANSFER_REQUEST',
  SUB_STOCK_CANCEL_TRANSFER_REQUEST = 'SUB_STOCK_CANCEL_TRANSFER_REQUEST',
  SUB_STOCK_ADD_INVENTORY = 'SUB_STOCK_ADD_INVENTORY',
  SUB_STOCK_UPDATE_TRANSFER_REQUEST = 'SUB_STOCK_UPDATE_TRANSFER_REQUEST',
  SUB_STOCK_TRANSFER_REQUEST_SEND_FOR_RFA = 'SUB_STOCK_TRANSFER_REQUEST_SEND_FOR_RFA',
  SUB_STOCK_CANCEL_DISPATCH_ITEMS = 'SUB_STOCK_CANCEL_DISPATCH_ITEMS',
  SUB_STOCK_DISPATCH_ITEMS = 'SUB_STOCK_DISPATCH_ITEMS',
  UPDATE_TRANSFER_REQUEST_STATUS = 'UPDATE_TRANSFER_REQUEST_STATUS',
  SUB_STOCK_HOUSEKEEPING_SAVE_USAGE = 'SUB_STOCK_HOUSEKEEPING_SAVE_USAGE',
  SUB_STOCK_STATIONARY_SAVE_USAGE = 'SUB_STOCK_STATIONARY_SAVE_USAGE',
  SUB_STOCK_EQUIPMENT_SAVE_USAGE = 'SUB_STOCK_EQUIPMENT_SAVE_USAGE',

  SUPPLY_CATALOG_UPDATE_ELEMENT = 'SUPPLY_CATALOG_UPDATE_ELEMENT',
  SUPPLY_CATALOG_UPDATE_GROUP = 'SUPPLY_CATALOG_UPDATE_GROUP',
  SERVICE_CATALOG_UPDATE_ELEMENT = 'SERVICE_CATALOG_UPDATE_ELEMENT',
  SERVICE_CATALOG_UPDATE_GROUP = 'SERVICE_CATALOG_UPDATE_GROUP',

  INS_SS_CC_COMPANY_CONTRACT_COMPANY = 'INS_SS_CC_COMPANY_CONTRACT_COMPANY',
  INS_SS_CC_COMPANY_CONTRACT_CONTRACT = 'INS_SS_CC_COMPANY_CONTRACT_CONTRACT',
  INS_SS_CC_COMPANY_CONTRACT_PACKAGE = 'INS_SS_CC_COMPANY_CONTRACT_PACKAGE',
  INS_SS_CC_COMPANY_CONTRACT_EMPLOYEE = 'INS_SS_CC_COMPANY_CONTRACT_EMPLOYEE',
  INS_SS_CC_INSURANCE_COMPANY = 'INS_SS_CC_INSURANCE_COMPANY',
  INS_SS_CC_INSURANCE_CONTRACT = 'INS_SS_CC_INSURANCE_CONTRACT',
  INS_SS_CC_INSURANCE_PLAN = 'INS_SS_CC_INSURANCE_PLAN',

  TRANSFER_REQUEST_MANAGEMENT = 'TRANSFER_REQUEST_MANAGEMENT',
  WALLET_MANAGEMENT_ADJUST = 'WALLET_MANAGEMENT_ADJUST'
}
