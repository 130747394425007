export enum MedicalPackageOrderActionEnum {
  SELECT_ACTION = 'Select Action',
  ADD_TO_EXECUTE_TREATMENT = 'Add To Execute Treatment',
  ADD_TO_SERVICE_BILLING = 'Add To Service Billing',
  ADD_TO_OR_BOOKING = 'Add To OR Booking',
  ADD_TO_CREATE_ORDER = 'Add to Create Order',
  CREATE_ORDER = 'Create Order',
  SERVICE_BILLING = 'Service Billing',
  SEND_TO_TREATMENT = 'Send To Treatment',
  PRESCRIPTION_ON_DISCHARGE = 'Prescription On Discharge',
  PRESCRIPTION = 'Prescription'
}
