import {getElementPrices} from '@v2/core/functions/functions';
import {ICatalogElement} from '@v2/core/models/masterdata';

export class ProcedureViewModel {
  id: string;
  xref: string;
  name: string;
  englishName: string;
  category: string;
  opdPrice: number;
  ipdPrice: number;
  baseSellingPrice: number;
  masterCatalogPrice: number;

  static mapFromHttpModel(httpData: ICatalogElement): ProcedureViewModel {
    const prices = getElementPrices(httpData.elementPrices);

    const viewData = new ProcedureViewModel();
    viewData.id = httpData.id;
    viewData.xref = httpData.xref;
    viewData.name = httpData.englishName || httpData.name;
    viewData.englishName = httpData.englishName || httpData.name;
    viewData.category = httpData.catalogCategory ? httpData.catalogCategory.name : '';
    if (prices) {
      viewData.opdPrice = prices.opdPrice;
      viewData.ipdPrice = prices.ipdPrice;
      viewData.baseSellingPrice = prices.baseSellingPrice;
      viewData.masterCatalogPrice = prices.masterCatalogCost;
    }
    return viewData;
  }
}
