import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PageLoaderComponent} from './page-loader.component';

@NgModule({
  declarations: [PageLoaderComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  exports: [PageLoaderComponent]
})
export class PageLoaderModule {
}
