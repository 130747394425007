import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CustomModulePreloadingStrategy} from '@v2/core/custom-module-preloading-strategy/custom-module-preloading-strategy';
import {NgxPermissionsGuard, NgxPermissionsModule} from 'ngx-permissions';
import {ForgotPasswordComponent, LoginComponent, ResetPasswordComponent} from './auth';
import {AuthGuard} from './auth/auth-guard.service';
import {NoAuthGuard} from './auth/no-auth-guard.service';
import {TwoStepVerificationComponent} from './auth/two-step-verification/two-step-verification.component';
import {ForbiddenComponent} from './shared/layout/forbidden/forbidden.component';
import {MainLayoutComponent} from './shared/layout/main-layout/main-layout.component';
import {FORBIDDEN_ROUTE, FORBIDDEN_URL, MAIN_MENU_ROUTES, MAIN_MENUS_KEYS} from './shared/layout/main-menu-routes';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },

  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'two-step-verification',
    component: TwoStepVerificationComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: FORBIDDEN_ROUTE,
        component: ForbiddenComponent,
      },
      /*{
        path: 'dashboard', // TODO - MAIN_MENU needs to check usage
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },*/
      {
        path: 'help', // TODO - MAIN_MENU needs to check usage
        loadChildren: () => import('./help-content/help-content.module').then(m => m.HelpContentModule)
      },
      {
        path: 'profile', // TODO - MAIN_MENU needs to check usage
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: MAIN_MENU_ROUTES.rapidRegistration,
        loadChildren: () => import('./rapid-registration/rapid-registration.module').then(m => m.RapidRegistrationModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.rapidRegistration,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.adtManagement,
        loadChildren: () => import('./v2/adt-management/adt-management.module').then(m => m.AdtManagementModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.adtManagement,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.bedManagement,
        loadChildren: () => import('./v2/bed-management/bed-management.module').then(m => m.BedManagementModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.bedManagement,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.opd,
        loadChildren: () => import('./visits/opd/opd.module').then(m => m.OpdModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: [MAIN_MENUS_KEYS.nurseOpd, MAIN_MENUS_KEYS.doctorOpd],
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.ipd,
        loadChildren: () => import('./visits/ipd/ipd.module').then(m => m.IpdModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: [MAIN_MENUS_KEYS.nurseIpd, MAIN_MENUS_KEYS.doctorIpd],
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.wardManagement,
        loadChildren: () => import('./ward-management/ward-management.module').then(m => m.WardManagementModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.wardManagement,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.radiology,
        loadChildren: () => import('./radiology/radiology.module').then(m => m.RadiologyModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.radiology,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.lab,
        loadChildren: () => import('./pathology/pathology.module').then(m => m.PathologyModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.lab,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.pharmacy,
        loadChildren: () => import('./v2/pharmacy/pharmacy.module').then(m => m.PharmacyModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.pharmacy,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.nutritionMeal,
        loadChildren: () => import('./nutrition-meal/nutrition-meal.module').then(m => m.NutritionMealModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.nutritionMeal,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.operationRoom,
        loadChildren: () => import('./v2/operation-room/operation-room.module').then(m => m.OperationRoomModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.operationRoom,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.insuranceSSCC,
        loadChildren: () => import('./insurance-ss-cc/insurance-ss-cc.module').then(m => m.InsuranceSsCcModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.insuranceSSCC,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.billing,
        loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.billing,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.medicalCoding,
        loadChildren: () => import('./medical-coding/medical-coding.module').then(m => m.MedicalCodingModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.medicalCoding,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.corpManagement,
        loadChildren: () => import('./corp-management/corp-management.module').then(m => m.CorpManagementModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.corpManagement,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.inventory,
        loadChildren: () => import('./stock-management/stock-management.module').then(m => m.StockManagementModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.inventory,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.procurement,
        loadChildren: () => import('./procurement/procurement.module').then(m => m.ProcurementModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.procurement,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.subStockManagement,
        loadChildren: () => import('./v2/sub-stock-management/sub-stock-management.module').then(m => m.SubStockManagementModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.subStockManagement,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.medicalParty,
        loadChildren: () => import('./medical-party/medical-party.module').then(m => m.MedicalPartyModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.medicalParty,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.nonMedicalParty,
        loadChildren: () => import('./non-medical-party/non-medical-party.module').then(m => m.NonMedicalPartyModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.nonMedicalParty,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.facilityResource,
        loadChildren: () => import('./medical-resource/medical-resource.module').then(m => m.MedicalResourceModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.facilityResource,
            redirectTo: FORBIDDEN_URL
          }
        }
      },

      {
        path: MAIN_MENU_ROUTES.corpSetup,
        loadChildren: () => import('./corp-setup/corp-setup.module').then(m => m.CorpSetupModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.corpSetup,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.userManagement,
        loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.userManagement,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.aimSetup,
        loadChildren: () => import('./aim-setup/aim-setup.module').then(m => m.AimSetupModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.aimSetup,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.supportCenter,
        loadChildren: () => import('./support-center/support-center.module').then(m => m.SupportCenterModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.supportCenter,
            redirectTo: FORBIDDEN_URL
          }
        }
      },
      {
        path: MAIN_MENU_ROUTES.reports,
        loadChildren: () => import('./v2/reports/reports.module').then(m => m.ReportsModule),
        data: {
          permissions: {
            only: MAIN_MENUS_KEYS.reports,
            redirectTo: FORBIDDEN_URL
          }
        }
      }
    ],
  },
  {
    path: '**',
    redirectTo: 'login'
  },

];

@NgModule({
  imports: [
    NgxPermissionsModule.forRoot(),
    RouterModule.forRoot(routes, {preloadingStrategy: CustomModulePreloadingStrategy})
  ],
  providers: [CustomModulePreloadingStrategy],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

