import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AbsoluteNumberPipe} from './absolute-number.pipe';
import {CombineWithPipe} from './combine-with.pipe';
import {CommonDateTimePipe} from './common-date-time.pipe';
import {CommonDatePipe} from './common-date.pipe';
import {CommonTimePipe} from './common-time.pipe';
import {PatientNameTranslationPipe} from './patient-name-translation.pipe';

@NgModule({
  declarations: [CommonDatePipe, CommonTimePipe, CommonDateTimePipe, CombineWithPipe, AbsoluteNumberPipe, PatientNameTranslationPipe],
  imports: [
    CommonModule
  ],
  exports: [CommonDatePipe, CommonTimePipe, CommonDateTimePipe, CombineWithPipe, AbsoluteNumberPipe, PatientNameTranslationPipe]
})
export class CommonPipesModule {
}
