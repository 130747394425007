import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styles: [
    `
      .is-icon-button {
        min-width: 0 !important;
        padding: 0 10px !important;
      }

      .has-span-text-overflow {
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `
  ]
})
export class FilterButtonComponent implements OnInit {

  @Input() text: string;
  @Input() className: string;
  @Input() iconClass = 'ic-filter';

  constructor() { }

  ngOnInit() {
  }

}
