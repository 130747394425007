export enum OrderOriginEnum {
  nsOpd = 'nsOpd',
  nsIpd = 'nsIpd',
  drOpd = 'drOpd',
  drIpd = 'drIpd',
  lab = 'lab',
  radiology = 'radiology',
  or = 'or',
  patientReviewDailyCharge = 'patientReviewDailyCharge',
  patientReviewVisitCharge = 'patientReviewVisitCharge',
  IPD = 'IPD', // not an actual order origin. used only for comparison
  OPD = 'OPD'// not an actual order origin. used only for comparison
}

