import {Component, Input, OnInit} from '@angular/core';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'common'}]
})
export class NoDataComponent implements OnInit {

  @Input() message = 'No data found';

  constructor(private translocoService: TranslocoService) {
  }

  ngOnInit() {
  }
}
