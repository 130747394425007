import { Injectable } from '@angular/core';
import { WalletManagementApiService } from 'src/app/billing/wallet-management/services/wallet-management-api.service';
import { map, pluck } from 'rxjs/operators';
import { IData, IPageableData } from '@v2/core/models/masterdata';
import { WalletHistoryLogViewModel } from 'src/app/billing/wallet-management/models/view/wallet-list-view.model';
import { Observable } from 'rxjs';
import { ICommonFilters } from '@v2/core/models/masterdata/IFilters.master-data';
import { setFilter } from '@v2/core/functions/functions';
import { IWalletBalance } from 'src/app/billing/wallet-management/models/api/wallet-list-api.model';

@Injectable()

export class WalletManagementService {

  constructor(private walletManagementApiService: WalletManagementApiService) {
  }

  getWalletList(filter: ICommonFilters): Observable<IData<IWalletBalance>> {
    return this.walletManagementApiService.getWalletList(setFilter(filter)).pipe(
      pluck('data')
    );
  }

  getPatientWalletBalance(patientId: string) {
    return this.walletManagementApiService.getWalletBalance(patientId).pipe(
      map(response => response.data)
    );
  }

  getPatientWalletHistoryLog(patientId: string, filters: ICommonFilters) {
    return this.walletManagementApiService.getWalletHistory(patientId, setFilter(filters)).pipe(
      pluck('data'),
      map((response: IPageableData<any>) => {
        const {items, ...pagination} = response;
        const data = items.map(item => WalletHistoryLogViewModel.mapForViewModel(item));
        return {items: data, ...pagination};
      })
    );
  }

  saveWalletAdjust(patientId: string, payload: { data: { adjustedAmount: string, actualDateTime: string, note: string } }): Observable<{ status: boolean }> {
    return this.walletManagementApiService.saveWalletAdjust(patientId, payload).pipe(
      map(response => response.data)
    );
  }

  getActiveWallet(patientId: string): Observable<{claimId: string, appointmentId: string, admissionOrVisit: string}> {
    return this.walletManagementApiService.getActiveWallet(patientId).pipe(
      map(response => response.data)
    )
  }

}
