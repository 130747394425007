export enum OpdSummarySectionEnum {
  VITALS = 'Vitals',
  CHIEF_COMPLAINT = 'Chief Complaint',
  PRESENT_ILLNESS = 'Present Illness',
  PHYSICAL_EXAMINATION_SYSTEMIC_REVIEW = 'Physical Examination / Systemic Review',
  NURSE_NOTE = 'Nurse Note',
  INVESTIGATION = 'Investigations',
  DIAGNOSIS = 'Diagnosis',
  ADVICE_TREATMENT_PLAN = 'Advice and Treatment Plan',
  PRESCRIPTION = 'Prescription',
  TREATMENT = 'Treatment',
  SUPPLY = 'Supply',
  NEXT_APPOINTMENT = 'Next Appointment'
}
