import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {RouterModule} from '@angular/router';
import {TranslocoModule} from '@ngneat/transloco';
import {ProfilePicModule} from '../../v2/shared-v2/components/profile-pic/profile-pic.module';
import {NoteAlertModule} from '../../v2/shared-v2/module/note-alert/note-alert.module';
import {CommonDirectiveModule} from '../directive/common-directive.module';
import {FooterComponent} from './footer/footer.component';
import {ForbiddenComponent} from './forbidden/forbidden.component';
import {HeaderComponent} from './header/header.component';

import {MainLayoutComponent} from './main-layout/main-layout.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {PageTopbarComponent} from './topbar/topbar.component';

@NgModule({
  declarations: [HeaderComponent, ForbiddenComponent, MainLayoutComponent, PageTopbarComponent, FooterComponent, SidebarComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    MatButtonModule,
    FlexLayoutModule,
    MatSlideToggleModule,
    RouterModule,
    CommonDirectiveModule,
    ProfilePicModule,
    FlexModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    NoteAlertModule
  ],
  exports: [HeaderComponent, PageTopbarComponent]
})
export class LayoutModule {
}
