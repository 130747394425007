import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TRANSLOCO_SCOPE, TranslocoService} from '@ngneat/transloco';
import {ConcurrencyScopeEnum} from '@v2/core/enums/concurrency-scope.enum';
import {getCurrentDateTime, getEndOfDay, getStartOfDay, getUTCDateTime} from '@v2/core/functions/functions';
import {IPatient} from '@v2/core/models/masterdata';
import {ICommonFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {ConcurrencyService} from '@v2/core/services/concurrency.service';
import {MasterDataHttpService} from '@v2/core/services/MasterData.http.service';
import {UtilityService} from '@v2/core/services/utility.service';
import {BaseSubscriptionHandlerClass} from '@v2/core/subscription-handler/subscription-handler.class';
import {finalize} from 'rxjs/operators';
import {AddEditNoteAlertModalComponent} from '../add-edit-note-alert-modal/add-edit-note-alert-modal.component';
import {NoteAlertLogsModalComponent} from '../note-alert-logs-modal/note-alert-logs-modal.component';
import {NoteAlertService} from '../note-alert.service';
import {NoteAlertSeverityStatusEnum} from '../resources/model/enum/note-alert-severity-status.enum';
import {NoteAlertListViewModel} from '../resources/model/view/note-alert-list-view.model';

@Component({
  selector: 'app-note-alert-list-modal',
  templateUrl: './note-alert-list-modal.component.html',
  styleUrls: ['./note-alert-list-modal.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'common'}]
})
export class NoteAlertListModalComponent extends BaseSubscriptionHandlerClass implements OnInit, OnChanges {

  @Input() patient: IPatient;
  @Input() appointmentId: string
  @Input() currentOAId: string
  @Input() useV2TranslationPipe: boolean;
  @Input() hasWriteAccess = true;
  @Input() allowVisitRestriction = true;

  noteAlertList: NoteAlertListViewModel[] = [];
  notificationCount: number;
  showLoader = false;
  patientId: string;
  patientXref: string;
  patientProfilePic: string;
  noteAlertSeverityStatusEnum = NoteAlertSeverityStatusEnum;
  filters: ICommonFilters = {
    filters: {
      filters: {
        patientId: this.patient && (this.patient.id || this.patient.patientId),
        currentOAId: this.currentOAId,
        showInactiveNote: false,
        validityStartDT: getStartOfDay(),
        validityEndDT: getEndOfDay(),
        showAllOANote: false
      }
    }
  }

  constructor(
    private matDialog: MatDialog,
    private noteAlertService: NoteAlertService,
    private utilityService: UtilityService,
    private concurrencyService: ConcurrencyService,
    private translocoService: TranslocoService,
    private masterDataHttpService: MasterDataHttpService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.patient && !changes.patient.firstChange) {
      this.initializeComponent();
    }
  }

  ngOnInit() {
    this.handleConcurrency();
    this.initializeComponent();
  }

  initializeComponent() {
    this.setPatientDetails();
    this.setFilters();
    this.getNoteAlertList();
    this.getPatientNoteAlertCount();
  }

  setPatientDetails() {
    if (this.patient) {
      this.patientId = this.patient.id || this.patient.patientId;
      this.patientProfilePic = this.patient.profilePicPath || this.patient.profilePic;
      this.patientXref = this.patient.patientXref || this.patient.xref || this.patient.patientId_xref;
    }
  }

  setFilters() {
    this.filters.filters.filters.patientId = this.patientId;
    this.filters.filters.filters.currentOAId = this.currentOAId;
  }

  handleConcurrency() {
    this.concurrencyService.handler([
      {scope: ConcurrencyScopeEnum.PATIENT_NOTE_ALERT, refreshDataHandler: this.getNoteAlertList.bind(this)},
      {scope: ConcurrencyScopeEnum.PATIENT_NOTE_ALERT_INACTIVATE, refreshDataHandler: this.getNoteAlertList.bind(this)}
    ], this.unsubscribe$);
  }

  showAllNotes(showAllNote = false) {
    this.filters.filters.filters.showAllOANote = showAllNote;
    this.filters.filters.filters.currentOAId = showAllNote ? null : this.currentOAId;
    this.getNoteAlertList();
  }

  showInactiveNotes(showInactiveNotes = false) {
    this.filters.filters.filters.showInactiveNote = showInactiveNotes;
    this.filters.filters.filters.validityEndDT = showInactiveNotes ? null : getEndOfDay();
    this.filters.filters.filters.validityStartDT = showInactiveNotes ? null : getStartOfDay();
    this.getNoteAlertList();
  }

  getNoteAlertList() {
    this.showLoader = true;
    this.noteAlertService.getNoteAlertList(this.filters)
      .pipe(finalize(() => this.showLoader = false))
      .subscribe((noteAlertList) => {
        this.noteAlertList = noteAlertList;
      }, (error) => {
        this.utilityService.displayErrorSnackbar(error);
      })
  }

  getPatientNoteAlertCount() {
    const filters: ICommonFilters = {
      filters: {
        filters: {
          patientIds: [this.patientId],
          currentOAId: this.currentOAId,
          validityStartDT: getStartOfDay(),
          validityEndDT: getEndOfDay()
        }
      }
    };
    this.masterDataHttpService.getPatientNoteAlertCount(filters)
      .pipe(this.pickUntil())
      .subscribe((noteAlertCounts) => {
        this.notificationCount = noteAlertCounts && noteAlertCounts.length && noteAlertCounts[0].alertNoteCount;
        this.onNoteAlertCountUpdated();
      }, (error) => {
        this.utilityService.displayErrorSnackbar(error);
      })
  }

  closeSidebarModal() {
    this.noteAlertService.toggleNoteAlertSidebarModal.next({showAlertNoteSidebar: false})
  }

  openNoteAlertModal(noteAlert?: NoteAlertListViewModel) {
    if (!this.hasWriteAccess) {
      return;
    }
    this.matDialog.open(AddEditNoteAlertModalComponent, {
      data: {
        patientId: this.patientId,
        appointmentId: this.appointmentId,
        alertNoteId: noteAlert && noteAlert.id,
        currentOAId: this.currentOAId,
        allowVisitRestriction: this.allowVisitRestriction
      }
    }).afterClosed().subscribe((data) => {
      if (data) {
        this.getNoteAlertList();
        this.getPatientNoteAlertCount();
      }
    })
  }

  onNoteAlertCountUpdated() {
    this.noteAlertService.noteAlertCountUpdated.next({
      patientId: this.patientId,
      appointmentId: this.appointmentId,
      currentOA: this.currentOAId,
      notificationCount: this.notificationCount
    });
  }

  inActivateNoteAlert(noteAlert: NoteAlertListViewModel) {
    const payload = {
      validityEndDT: getCurrentDateTime(),
      updatedAt: getUTCDateTime(noteAlert.updatedAt)
    }
    this.noteAlertService.inActivateNoteAlert(noteAlert.id, payload)
      .subscribe(() => {
        this.utilityService.showSnackBarCloseLabel(this.translocoService.translate('Inactivated successfully', {}, 'common'));
        this.getNoteAlertList();
        this.getPatientNoteAlertCount();
      }, (error) => {
        this.utilityService.displayErrorSnackbar(error)
      })
  }

  openNoteAlertLogModal(noteAlert: NoteAlertListViewModel) {
    this.matDialog.open(NoteAlertLogsModalComponent, {
      data: {alertNoteId: noteAlert.id}
    });
  }

}
