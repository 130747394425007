import {IBillingAppointment, IDoctor, IMasterData} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';

export class ConsultationListViewModel extends ConcurrencyRequest {
  id: string;
  aptDate: string;
  department: string;
  departmentId: string;
  doctor: string;
  doctorId: string;
  note: string;
  appointmentStatus: string;
  nsPatientRoutingStatus: string;
  nurseStationId: string;
  nurseStationName: string;
  serviceType: IMasterData;
  serviceTypeId: number;
  requestedByDoctorName?: string;
  billingClaimProcess?: string;
  consultationStatus?: string;

  static mapFromApiModel(apiData: IBillingAppointment): ConsultationListViewModel {
    const viewData = new ConsultationListViewModel();
    viewData.id = apiData.id;
    viewData.serviceType = apiData.serviceType;
    viewData.serviceTypeId = apiData.serviceType && apiData.serviceType.key;
    viewData.aptDate = apiData.scheduleDate;
    viewData.department = apiData.department && apiData.department.name;
    viewData.departmentId = apiData.department && apiData.department.id;
    viewData.doctor = this.getDoctorName(apiData.doctor);
    viewData.doctorId = apiData.doctor && apiData.doctor.id;
    viewData.note = apiData.note;
    viewData.appointmentStatus = apiData.apptStatus ? apiData.apptStatus.label : '';
    viewData.nsPatientRoutingStatus = apiData.nsPatientRoutingStatus ? apiData.nsPatientRoutingStatus.label : '';
    viewData.nurseStationId = apiData.nurseStation && apiData.nurseStation.id;
    viewData.nurseStationName = apiData.nurseStation && apiData.nurseStation.name;
    viewData.mapConcurrencyRequest(apiData);
    return viewData;
  }

  static getDoctorName(doctor: IDoctor): string {
    if (doctor && doctor.lastName) {
      return `Dr. ${doctor.firstName} ${doctor.lastName}`
    } else {
      return `Dr. ${doctor.firstName}`
    }
  }
}
