import {isArray} from '@v2/core/functions/functions';
import {IAttachment, IPaymentType} from '@v2/core/models/masterdata';

export class PaymentPlanViewModel {
  paymentPlanId: string;
  paymentPlanTypeName: string;
  paymentTypeShortName: string;
  validateFrom: string;
  validateTo: string;
  attachment: IAttachment[];
  note: string;
  coverageAmount: number;
  insuranceCompanyId: string;
  insuranceCompanyXref: string;
  insuranceCompanyName: string;
  contractId: string;
  contractXref: string;
  contractName: string;
  planId: string;
  planXref: string;
  planName: string;
  companyId: string;
  companyXref: string;
  companyName: string;
  packageId: string;
  packageXref: string;
  packageName: string;
  employeeId: string;
  socialSecurityNumber: string;
  paymentPlanTypeCategory: string;
  priority: number;

  static mapFromHttpModel(httpData: IPaymentType): PaymentPlanViewModel {
    const viewData = new PaymentPlanViewModel();
    viewData.paymentPlanId = httpData.paymentPlanId;
    if (httpData.paymentType) {
      viewData.paymentPlanTypeCategory = httpData.paymentType.category ? httpData.paymentType.category.label : '';
      viewData.paymentPlanTypeName = httpData.paymentType.name;
    }
    viewData.paymentTypeShortName = httpData.paymentTypeShortName;
    viewData.validateFrom = httpData.validateFrom;
    viewData.validateTo = httpData.validateTo;
    viewData.attachment = isArray(httpData.attachment) ? httpData.attachment : [];
    viewData.note = httpData.note;
    viewData.coverageAmount = httpData.coverageAmount;
    viewData.insuranceCompanyId = httpData.insuranceCompanyId;
    viewData.companyId = httpData.companyId;
    if (httpData.insuranceCompany) {
      viewData.insuranceCompanyXref = httpData.insuranceCompany.xref;
      viewData.insuranceCompanyName = httpData.insuranceCompany.name;
    }
    if (httpData.company) {
      viewData.companyXref = httpData.company.xref;
      viewData.companyName = httpData.company.name;
    }
    viewData.contractId = httpData.contractId;
    if (httpData.contract) {
      viewData.contractXref = httpData.contract.xref;
      viewData.contractName = httpData.contract.name;
    }
    viewData.planId = httpData.planId;
    if (httpData.plan) {
      viewData.planXref = httpData.plan.planId_xref;
      viewData.planName = httpData.plan.planName;
    }
    viewData.packageId = httpData.packageId;
    if (httpData.package) {
      viewData.packageXref = httpData.package.planId_xref;
      viewData.packageName = httpData.package.planName;
    }
    viewData.employeeId = httpData.employeeId;
    viewData.socialSecurityNumber = httpData.socialSecurityNumber;
    viewData.priority = httpData.priority;
    return viewData;
  }
}
