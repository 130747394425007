import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'commonDate'
})
export class CommonDatePipe extends DatePipe implements PipeTransform {

  private readonly DATE_FMT = `dd-MM-yyyy`;

  transform(value: any, format = this.DATE_FMT): any {
    try {
      return super.transform(value, format);
    } catch (e) {
    }
  }

}
