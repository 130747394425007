import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'commonDateTime'
})
export class CommonDateTimePipe extends DatePipe implements PipeTransform {

  private readonly DATE_FMT = `dd-MM-yyyy`;
  private readonly TIME_FMT = 'shortTime';

  transform(value: any, separator: string = ' '): any {
    if (value) {
      return super.transform(value, this.DATE_FMT) + separator + super.transform(value, this.TIME_FMT);
    }

    return '';
  }

}
