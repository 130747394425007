import {FormControl, Validators} from '@angular/forms';
import {getCurrentDateTime} from '@v2/core/functions/functions';
import {INoteAlert} from '../http/INoteAlert';

export interface INoteAlertForm {
  note: string | FormControl
  notificationArea: string[] | FormControl
  onlyForCurrentAppointment: boolean | FormControl
  validityStartDT: string | FormControl
  validityEndDT: string | FormControl
  severity: number | FormControl
  updatedAt: string | FormControl
}

export class NoteAlertFormModel implements INoteAlertForm {
  note = new FormControl(null, Validators.required)
  notificationArea = new FormControl([], Validators.required)
  onlyForCurrentAppointment = new FormControl()
  validityStartDT = new FormControl(getCurrentDateTime())
  validityEndDT = new FormControl(null, Validators.required)
  severity = new FormControl(null, Validators.required)
  updatedAt = new FormControl(null)
}

export class NoteAlertFormDataModel implements INoteAlertForm {
  note: string
  notificationArea: string[]
  onlyForCurrentAppointment: boolean
  validityStartDT: string
  validityEndDT: string
  severity: number
  updatedAt: string;

  static mapFromApiModel(apiData: INoteAlert): NoteAlertFormDataModel {
    const formData = new NoteAlertFormDataModel();

    formData.note = apiData.note;
    if (apiData.notificationArea && apiData.notificationArea.length) {
      formData.notificationArea = apiData.notificationArea.map((notificationArea) => notificationArea.id);
    }
    formData.onlyForCurrentAppointment = apiData.onlyForCurrentAppointment;
    formData.validityStartDT = apiData.validityStartDT;
    formData.validityEndDT = apiData.validityEndDT;
    formData.severity = apiData.severity && apiData.severity.key;
    formData.updatedAt = apiData.updatedAt;

    return formData;
  }
}
