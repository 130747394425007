import {IPharmacyOutlet} from '@v2/core/models/masterdata';

export class PharmacyOutletViewModel {
  constructor(
    public id: string,
    public xref: string,
    public title: string,
    public status: boolean,
  ) {
  }

  static mapFromApiModel(apiData: IPharmacyOutlet): PharmacyOutletViewModel {
    return new PharmacyOutletViewModel(
      apiData.id,
      apiData.xref,
      apiData.title,
      apiData.status
    )
  }
}
