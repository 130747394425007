import {Injectable} from '@angular/core';
import {OrderOriginEnum} from '@v2/core/enums/order-origin.enum';
import {OrderTypeEnum} from '@v2/core/enums/order-type.enum';
import {PatientOrderStatusEnum} from '@v2/core/enums/patient-order-status.enum';
import {getGenericOriginName, getUTCDateTime} from '@v2/core/functions/functions';
import {IGroupCommercialOrder, IMasterData, IPageableData} from '@v2/core/models/masterdata';
import {ICustomFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {IMedicalPackage} from '@v2/core/models/masterdata/IMedicalPackage.master-data';
import {IOrderStatus, IPatientMedicineAllergyStatus, IPatientOrder} from '@v2/core/models/masterdata/IPatientOrder.master-data';
import {IDeletableEntity} from '@v2/core/models/masterdata/IPost.master-data';
import {CurrentMedicationViewModel} from '@v2/core/models/view/current-medication.view.model';
import {MedicationInteractionAllergyViewModel} from '@v2/core/models/view/medication-interaction-allergy.view.model';
import {ReturnedPatientOrderViewModel} from '@v2/core/models/view/patient-order.view.model';
import {PharmacyOutletViewModel} from '@v2/core/models/view/pharmacy-outlet.view.model';
import {MasterDataHttpService} from '@v2/core/services/MasterData.http.service';
import {Observable, of, Subject} from 'rxjs';
import {catchError, map, pluck} from 'rxjs/operators';
import {CancelOrDetachOrderPayload} from '../../../../shared/module/medical-package/models/post/cancel-or-detach-order.payload';
import {MedicalPackageHttpService} from '../../../../shared/module/medical-package/services/medical-package-http.service';
import {LabOrderHttpService} from '../../resources/http-service/lab-order.http.service';
import {MedicationOrderHttpService} from '../../resources/http-service/medication-order-http.service';
import {NutritionMealOrderHttpService} from '../../resources/http-service/nutrition-meal-order-http.service';
import {ProcedureOrderHttpService} from '../../resources/http-service/procedure-order.http.service';
import {RadiologyOrderHttpService} from '../../resources/http-service/radiology-order.http.service';
import {SupplyOrderHttpService} from '../../resources/http-service/supply-order-http.service';
import {MedicineInteractionAllergyPayload} from '../../resources/models/payload/medicine-interaction-allergy.payload';
import {PatientOrderHttpService} from './resources/http-service/patient-order.http.service';
import {DoctorFeeFormDataModel} from './resources/models/form/doctor-fee-form.model';
import {MedicationFormDataModel} from './resources/models/form/medication-form.model';
import {NutritionMealFormDataModel} from './resources/models/form/nutrition-meal-form.model';
import {PathologyFormDataModel} from './resources/models/form/pathology-form.model';
import {ProcedureFormDataModel} from './resources/models/form/procedure-form.model';
import {RadiologyFormDataModel} from './resources/models/form/radiology-form.model';
import {SupplyFormDataModel} from './resources/models/form/supply-form.model';
import {IPatientOrderRouteInfo} from './resources/models/IPatientOrderRouteInfo';
import {DoctorFeeOrderPayload} from './resources/models/payload/doctor-fee-order.payload';
import {MedicationOrderPayload} from './resources/models/payload/medication-order.payload';
import {NutritionMealOrderPayload} from './resources/models/payload/nutrition-meal-order.payload';
import {OrOrderPayload} from './resources/models/payload/or-order.payload';
import {PathologyOrderPayload} from './resources/models/payload/pathology-order.payload';
import {PharmacyOutletPayload} from './resources/models/payload/pharmacy-outlet.payload';
import {ProcedureOrderPayload} from './resources/models/payload/procedure-order.payload';
import {RadiologyOrderPayload} from './resources/models/payload/radiology-order.payload';
import {SupplyOrderPayload} from './resources/models/payload/supply-order.payload';

@Injectable()
export class PatientOrderService {

  private newlyAddedOrders = new Subject<Array<RadiologyFormDataModel | PathologyFormDataModel | ProcedureFormDataModel | NutritionMealFormDataModel | DoctorFeeFormDataModel | SupplyFormDataModel | MedicationFormDataModel>>();
  private dbMedicineOrders = new Subject<MedicationFormDataModel[]>();

  private updatedOrder = new Subject<boolean>();

  constructor(
    private patientOrderHttpService: PatientOrderHttpService,
    private procedureOrderHttpService: ProcedureOrderHttpService,
    private radiologyOrderHttpService: RadiologyOrderHttpService,
    private nutritionMealOrderHttpService: NutritionMealOrderHttpService,
    private labOrderHttpService: LabOrderHttpService,
    private supplyOrderHttpService: SupplyOrderHttpService,
    private medicationOrderHttpService: MedicationOrderHttpService,
    private masterDataHttpService: MasterDataHttpService,
    private medicalPackageHttpService: MedicalPackageHttpService
  ) {
  }

  addNewlySavedOrders(data: Array<RadiologyFormDataModel | PathologyFormDataModel | ProcedureFormDataModel | NutritionMealFormDataModel | DoctorFeeFormDataModel | SupplyFormDataModel | MedicationFormDataModel>) {
    this.newlyAddedOrders.next(data);
  }

  getNewlySavedOrders(): Observable<Array<RadiologyFormDataModel | PathologyFormDataModel | ProcedureFormDataModel | NutritionMealFormDataModel | DoctorFeeFormDataModel | SupplyFormDataModel | MedicationFormDataModel>> {
    return this.newlyAddedOrders.asObservable();
  }

  setUpdatedOrders(value: boolean) {
    this.updatedOrder.next(value);
  }

  getUpdatedOrders(): Observable<boolean> {
    return this.updatedOrder.asObservable();
  }

  setDbMedicineOrders(orders: MedicationFormDataModel[]) {
    this.dbMedicineOrders.next(orders);
  }

  getDbMedicineOrders(): Observable<Array<MedicationFormDataModel>> {
    return this.dbMedicineOrders.asObservable();
  }

  getRadiologyOrders(appointmentId: string, filters: ICustomFilters = {}, newlyAddedOrders: RadiologyFormDataModel[] = []): Observable<IPageableData<RadiologyFormDataModel>> {
    return this.radiologyOrderHttpService.getRadiologyOrders(appointmentId, filters).pipe(
      pluck('data'),
      map(response => {
        const {items, ...pagination} = response;
        const filteredItems = this.getFilteredOrders(newlyAddedOrders, 'id', items, 'id');
        const data = filteredItems.map(patientOrder => RadiologyFormDataModel.mapFromHttpModel(patientOrder));
        return {items: data, ...pagination};
      }),
      catchError(_ => of({items: []} as IPageableData<RadiologyFormDataModel>))
    );
  }

  getLabOrders(appointmentId: string, filters: ICustomFilters = {}, newlyAddedOrders: PathologyFormDataModel[] = []): Observable<IPageableData<PathologyFormDataModel>> {
    return this.labOrderHttpService.getLabOrders(appointmentId, filters).pipe(
      pluck('data'),
      map(response => {
        const {items, ...pagination} = response;
        const filteredItems = this.getFilteredOrders(newlyAddedOrders, 'id', items, 'id');
        const data = filteredItems.map(patientOrder => PathologyFormDataModel.mapFromHttpModel(patientOrder));
        return {items: data, ...pagination};
      }),
      catchError(_ => of({items: []} as IPageableData<PathologyFormDataModel>))
    );
  }

  getProcedureOrders(appointmentId: string, filters: ICustomFilters = {}, newlyAddedOrders: ProcedureFormDataModel[] = []): Observable<IPageableData<ProcedureFormDataModel>> {
    return this.procedureOrderHttpService.getProcedureOrders(appointmentId, filters).pipe(
      pluck('data'),
      map(response => {
        const {items, ...pagination} = response;
        const filteredItems = this.getFilteredOrders(newlyAddedOrders, 'id', items, 'pdProcedureId');
        const data = filteredItems.map(patientOrder => ProcedureFormDataModel.mapFromHttpModel(patientOrder));
        return {items: data, ...pagination};
      }),
      catchError(_ => of(null))
    );
  }

  getNutritionMealOrders(appointmentId: string, filters: ICustomFilters = {}, newlyAddedOrders: NutritionMealFormDataModel[] = []): Observable<IPageableData<NutritionMealFormDataModel>> {
    return this.nutritionMealOrderHttpService.getNutritionMealOrders(appointmentId, filters).pipe(
      pluck('data'),
      map(response => {
        const {items, ...pagination} = response;
        const filteredItems = this.getFilteredOrders(newlyAddedOrders, 'id', items, 'id');
        const data = filteredItems.map(patientOrder => NutritionMealFormDataModel.mapFromHttpModel(patientOrder));
        return {items: data, ...pagination};
      }),
      catchError(_ => of(null))
    );
  }

  getSupplyOrders(appointmentId: string, filters: ICustomFilters = {}, newlyAddedOrders: SupplyFormDataModel[] = []): Observable<IPageableData<SupplyFormDataModel>> {
    return this.supplyOrderHttpService.getSupplyOrders(appointmentId, filters).pipe(
      pluck('data'),
      map(response => {
        const {items, ...pagination} = response;
        const filteredItems = items.filter(order => !newlyAddedOrders.some(newOrder => newOrder.poSupplyId === (order.supply && order.supply.poSupplyId)));
        const data = filteredItems.map(patientOrder => SupplyFormDataModel.mapFromHttpModel(patientOrder));
        return {items: data, ...pagination};
      }),
      catchError(_ => of({items: []} as IPageableData<SupplyFormDataModel>))
    );
  }

  getMedicationOrders(appointmentId: string, orderOrigin: string, filters: ICustomFilters = {}, newlyAddedOrders: MedicationFormDataModel[] = []): Observable<IPageableData<MedicationFormDataModel>> {
    return this.medicationOrderHttpService.getMedicationOrder(appointmentId, filters).pipe(
      pluck('data'),
      map(response => {
        const {items, ...pagination} = response;
        const filteredItems = items.filter(order => !newlyAddedOrders.some(newOrder => newOrder.id === order.id || newOrder.id === order.pdMedicationId));
        const data = filteredItems.map(patientOrder => MedicationFormDataModel.mapFromHttpModel(patientOrder, orderOrigin));
        return {items: data, ...pagination};
      }),
      catchError(_ => of({items: []} as IPageableData<MedicationFormDataModel>))
    );
  }

  getMedicationPlanSpecs(appointmentId: string, orderOrigin: string, preferredPharmacyOutlet: PharmacyOutletViewModel, filters: ICustomFilters = {}): Observable<IPageableData<MedicationFormDataModel>> {
    return this.medicationOrderHttpService.getMedicationPlanSpecs(appointmentId, filters).pipe(
      pluck('data'),
      map(response => {
        const {items, ...pagination} = response;
        const data = items.map(patientOrder => {
          const formData = MedicationFormDataModel.mapFromHttpModel(patientOrder, orderOrigin);
          formData.pharmacyOutlet = preferredPharmacyOutlet;
          return formData;
        });
        return {...pagination, items: data}
      }),
      catchError(_ => of({items: []} as IPageableData<MedicationFormDataModel>))
    );
  }

  getPharmacyOrderStatus(medicineOrders: MedicationFormDataModel[]): Observable<Array<MedicationFormDataModel>> {
    const orderIds = medicineOrders.map((item) => item.id);
    return this.medicationOrderHttpService.getPharmacyOrderStatus(orderIds).pipe(
      pluck('data'),
      map(response => {
        medicineOrders.map((model) => {
          const orderObj = response.find(item => item.plannedSpecId === model.id);
          if (orderObj) {
            model.isOrderCancelled = orderObj.status && orderObj.status.label === PatientOrderStatusEnum.CANCELED;
            model.cancelOrderNote = orderObj.cancellationNote;
          }
          return model;
        })
        return medicineOrders;
      })
    );
  }

  getDoctorFeeOrders(appointmentId: string, isVisit: boolean, stationId: string, orderOrigin: string): Observable<Array<DoctorFeeFormDataModel>> {
    return this.patientOrderHttpService.getDoctorFeeOrders(appointmentId, isVisit, stationId, orderOrigin).pipe(
      pluck('data', 'procedures'),
      map(response => response.map(patientOrder => DoctorFeeFormDataModel.mapFromHttpModel(patientOrder, isVisit))),
      catchError(_ => of([]))
    );
  }

  getPatientOrderOrigin(orderOrigin: string): Observable<number> {
    return this.masterDataHttpService.getPatientOrderOrigins().pipe(
      map((response: Array<IMasterData>) => {
        const genericOrigin = getGenericOriginName(orderOrigin as OrderOriginEnum);
        const selectedOrderOrigin = response.find(origin => origin.label.toLowerCase() === genericOrigin.toLowerCase());
        return selectedOrderOrigin && selectedOrderOrigin.key;
      })
    );
  }

  placeRadiologyOrder(payload: RadiologyOrderPayload): Observable<RadiologyFormDataModel> {
    return this.radiologyOrderHttpService.placeRadiologyOrder(payload).pipe(
      pluck('data'),
      map(response => RadiologyFormDataModel.mapFromHttpModel(response))
    );
  }

  updateRadiologyOrder(orderId: string, payload: RadiologyOrderPayload): Observable<RadiologyFormDataModel> {
    return this.radiologyOrderHttpService.updateRadiologyOrder(orderId, payload).pipe(
      pluck('data'),
      map(response => RadiologyFormDataModel.mapFromHttpModel(response))
    );
  }

  deleteRadiologyOrder(appointmentId: string, orderId: string, updatedAt: string): Observable<boolean> {
    return this.radiologyOrderHttpService.deleteRadiologyOrder(appointmentId, orderId, !!updatedAt ? getUTCDateTime(updatedAt) : undefined).pipe(
      pluck('data'),
      map(response => response.status)
    );
  }

  cancelMedicalPackageOrder(orderId: string, payload: CancelOrDetachOrderPayload): Observable<boolean> {
    return this.medicalPackageHttpService.cancelOrder(payload).pipe(
      pluck('data'),
      map((response: boolean) => response)
    );
  }

  placeLabOrder(payload: PathologyOrderPayload): Observable<PathologyFormDataModel> {
    return this.labOrderHttpService.placeLabOrder(payload).pipe(
      pluck('data'),
      map(response => PathologyFormDataModel.mapFromHttpModel(response))
    );
  }

  updateLabOrder(orderId: string, payload: PathologyOrderPayload): Observable<PathologyFormDataModel> {
    return this.labOrderHttpService.updateLabOrder(orderId, payload).pipe(
      pluck('data'),
      map(response => PathologyFormDataModel.mapFromHttpModel(response))
    );
  }

  deleteLabOrder(appointmentId: string, orderId: string, updatedAt: string): Observable<boolean> {
    return this.labOrderHttpService.deleteLabOrder(appointmentId, orderId, !!updatedAt ? getUTCDateTime(updatedAt) : undefined).pipe(
      pluck('data'),
      map(response => response.status)
    );
  }

  placeNutritionMealOrder(payload: NutritionMealOrderPayload): Observable<NutritionMealFormDataModel> {
    return this.nutritionMealOrderHttpService.placeNutritionMealOrder(payload).pipe(
      pluck('data'),
      map(response => NutritionMealFormDataModel.mapFromHttpModel(response))
    );
  }

  updateNutritionMealOrder(orderId: string, payload: NutritionMealOrderPayload): Observable<NutritionMealFormDataModel> {
    return this.nutritionMealOrderHttpService.updateNutritionMealOrder(orderId, payload).pipe(
      pluck('data'),
      map(response => NutritionMealFormDataModel.mapFromHttpModel(response))
    );
  }

  deleteNutritionMealOrder(appointmentId: string, orderId: string, updatedAt: string): Observable<boolean> {
    return this.nutritionMealOrderHttpService.deleteNutritionMealOrder(appointmentId, orderId, !!updatedAt ? getUTCDateTime(updatedAt) : undefined).pipe(
      pluck('data'),
      map(response => response.status)
    );
  }

  placeProcedureOrder(payload: ProcedureOrderPayload): Observable<ProcedureFormDataModel> {
    return this.procedureOrderHttpService.placeProcedureOrder(payload).pipe(
      pluck('data'),
      map(response => ProcedureFormDataModel.mapFromHttpModel(response)),
    );
  }

  updateProcedureOrder(orderId: string, payload: ProcedureOrderPayload): Observable<ProcedureFormDataModel> {
    return this.procedureOrderHttpService.updateProcedureOrder(orderId, payload).pipe(
      pluck('data'),
      map(response => ProcedureFormDataModel.mapFromHttpModel(response)),
    );
  }

  deleteProcedureOrder(appointmentId: string, orderId: string, updatedAt: string): Observable<boolean> {
    return this.procedureOrderHttpService.deleteProcedureOrder(appointmentId, orderId, !!updatedAt ? getUTCDateTime(updatedAt) : undefined).pipe(
      pluck('data'),
      map(response => response.status)
    );
  }

  placeDoctorFeeOrder(stationId: string, appointmentId: string, orderOrigin: string, isVisit: boolean, payload: IDeletableEntity<DoctorFeeOrderPayload, string>): Observable<DoctorFeeFormDataModel[]> {
    return this.patientOrderHttpService.placeDoctorFeeOrders(stationId, appointmentId, orderOrigin, isVisit, payload).pipe(
      pluck('data', 'procedures'),
      map(orders => orders.map(order => DoctorFeeFormDataModel.mapFromHttpModel(order, isVisit)))
    );
  }

  placeSupplyOrder(appointmentId: string, payload: SupplyOrderPayload): Observable<SupplyFormDataModel> {
    return this.supplyOrderHttpService.placeSupplyOrder(appointmentId, payload).pipe(
      pluck('data'),
      map(order => SupplyFormDataModel.mapFromHttpModel(order))
    )
  }

  updateSupplyOrder(appointmentId: string, orderId: number, payload: SupplyOrderPayload): Observable<SupplyFormDataModel> {
    return this.supplyOrderHttpService.updateSupplyOrder(appointmentId, orderId, payload).pipe(
      pluck('data'),
      map(response => SupplyFormDataModel.mapFromHttpModel(response))
    );
  }

  deleteSupplyOrder(appointmentId: string, orderId: string, updatedAt: string, groupCommercialOrder: IGroupCommercialOrder): Observable<boolean> {
    const orderUpdatedAt = !!updatedAt ? getUTCDateTime(updatedAt) : undefined;
    const groupCommercialId = groupCommercialOrder && groupCommercialOrder.id;
    const groupCommercialUpdatedAt = groupCommercialOrder && groupCommercialOrder.updatedAt ? getUTCDateTime(groupCommercialOrder.updatedAt) : undefined;
    return this.supplyOrderHttpService.deleteSupplyOrder(appointmentId, orderId, orderUpdatedAt, groupCommercialId, groupCommercialUpdatedAt).pipe(
      pluck('data'),
      map(response => response.status)
    );
  }

  placeMedicationOrder(appointmentId: string, payload: MedicationOrderPayload): Observable<MedicationFormDataModel> {
    return this.medicationOrderHttpService.placeMedicationOrder(appointmentId, payload).pipe(
      pluck('data'),
      map(order => MedicationFormDataModel.mapFromHttpModel(order))
    )
  }

  updateMedicationOrder(appointmentId: string, orderId: string, payload: MedicationOrderPayload): Observable<MedicationFormDataModel> {
    return this.medicationOrderHttpService.updateMedicationOrder(appointmentId, orderId, payload).pipe(
      pluck('data'),
      map(order => MedicationFormDataModel.mapFromHttpModel(order))
    );
  }

  deleteMedicationOrder(appointmentId: string, orderId: string, updatedAt: string): Observable<boolean> {
    const orderUpdatedAt = !!updatedAt ? getUTCDateTime(updatedAt) : undefined;
    return this.medicationOrderHttpService.deleteMedicationOrder(appointmentId, orderId, orderUpdatedAt).pipe(
      pluck('data'),
      map(response => response.status)
    );
  }

  checkIfItemExistsInPharmacyOutlet(formData: SupplyFormDataModel | MedicationFormDataModel, isSupplyOrder = false): Observable<IOrderStatus> {
    if (formData.pharmacyOutlet && formData.pharmacyOutlet.id) {
      const postData: PharmacyOutletPayload[] = [];
      const elementId = isSupplyOrder ? (formData as SupplyFormDataModel).catalogElementId.id : (formData as MedicationFormDataModel).medicine.id;
      postData.push(PharmacyOutletPayload.mapFromFormModel(elementId, formData.qty || 0, formData.pharmacyOutlet.id));
      const payload: IDeletableEntity<PharmacyOutletPayload, string> = {items: postData, deletedItems: []};
      return this.masterDataHttpService.isItemExistsInPharmacyOutlet(payload).pipe(
        map(response => {
          const result = response.find(x => x.id === elementId);
          if (result && result.isExist && !result.isOutOfStock) {
            return {isExist: true, isOutOfStock: false} as IOrderStatus;
          } else {
            return {isExist: result.isExist, isOutOfStock: result.isOutOfStock} as IOrderStatus;
          }
        })
      );
    } else {
      return of({isExist: true, isOutOfStock: false} as IOrderStatus);
    }
  }

  placeSingleOrder(
    orderType: string,
    payload: RadiologyOrderPayload | PathologyOrderPayload | NutritionMealOrderPayload | ProcedureOrderPayload | SupplyOrderPayload | MedicationOrderPayload,
    appointmentId?: string
  ): Observable<RadiologyFormDataModel | PathologyFormDataModel | NutritionMealFormDataModel | ProcedureFormDataModel | SupplyFormDataModel | MedicationFormDataModel> {
    if (orderType === OrderTypeEnum.radiology) {
      return this.placeRadiologyOrder(payload as RadiologyOrderPayload);
    } else if (orderType === OrderTypeEnum.lab) {
      return this.placeLabOrder(payload as PathologyOrderPayload);
    } else if (orderType === OrderTypeEnum.nutritionMeal) {
      return this.placeNutritionMealOrder(payload as NutritionMealOrderPayload);
    } else if (orderType === OrderTypeEnum.Procedure || orderType === OrderTypeEnum.TreatmentPlanProcedure || orderType === OrderTypeEnum.TreatmentProcedure) {
      return this.placeProcedureOrder(payload as ProcedureOrderPayload);
    } else if (orderType === OrderTypeEnum.Supply) {
      return this.placeSupplyOrder(appointmentId, payload as SupplyOrderPayload);
    } else if (orderType === OrderTypeEnum.Medication || orderType === OrderTypeEnum.TreatmentPlan) {
      return this.placeMedicationOrder(appointmentId, payload as MedicationOrderPayload);
    }
  }

  placeGroupOrder(orderType: string, stationId: string, appointmentId: string, orderOrigin: string, isVisit: boolean, payload: IDeletableEntity<DoctorFeeOrderPayload, string>): Observable<DoctorFeeFormDataModel[]> {
    if (orderType === OrderTypeEnum.DoctorFee) {
      return this.placeDoctorFeeOrder(stationId, appointmentId, orderOrigin, isVisit, payload);
    }
  }

  updatePatientOrder(
    orderType: string,
    orderId: string | number,
    appointmentId: string,
    payload: RadiologyOrderPayload | PathologyOrderPayload | NutritionMealOrderPayload | ProcedureOrderPayload | SupplyOrderPayload | IDeletableEntity<DoctorFeeOrderPayload, string>,
    patientOrderRouteInfo?: IPatientOrderRouteInfo
  ): Observable<RadiologyFormDataModel | PathologyFormDataModel | NutritionMealFormDataModel | ProcedureFormDataModel | SupplyFormDataModel | DoctorFeeFormDataModel[]> {
    if (orderType === OrderTypeEnum.radiology) {
      return this.updateRadiologyOrder(orderId as string, payload as RadiologyOrderPayload);
    } else if (orderType === OrderTypeEnum.lab) {
      return this.updateLabOrder(orderId as string, payload as PathologyOrderPayload);
    } else if (orderType === OrderTypeEnum.nutritionMeal) {
      return this.updateNutritionMealOrder(orderId as string, payload as NutritionMealOrderPayload);
    } else if (orderType === OrderTypeEnum.Procedure || orderType === OrderTypeEnum.TreatmentPlanProcedure || orderType === OrderTypeEnum.TreatmentProcedure) {
      return this.updateProcedureOrder(orderId as string, payload as ProcedureOrderPayload);
    } else if (orderType === OrderTypeEnum.DoctorFee) {
      const {isVisit, stationId, orderOrigin} = patientOrderRouteInfo;
      return this.placeDoctorFeeOrder(stationId, appointmentId, orderOrigin, isVisit, payload as IDeletableEntity<DoctorFeeOrderPayload, string>);
    } else if (orderType === OrderTypeEnum.Supply) {
      return this.updateSupplyOrder(appointmentId, orderId as number, payload as SupplyOrderPayload);
    } else if (orderType === OrderTypeEnum.Medication || orderType === OrderTypeEnum.TreatmentPlan) {
      return this.updateSupplyOrder(appointmentId, orderId as number, payload as SupplyOrderPayload);
    }
  }

  deletePatientOrder(orderType: string, orderId: string, updatedAt: string, patientOrderRouteInfo?: IPatientOrderRouteInfo, groupCommercialOrder?: IGroupCommercialOrder): Observable<boolean> {
    const {isVisit, stationId, orderOrigin, appointmentId} = patientOrderRouteInfo;
    if (orderType === OrderTypeEnum.radiology) {
      return this.deleteRadiologyOrder(appointmentId, orderId, updatedAt);
    } else if (orderType === OrderTypeEnum.lab) {
      return this.deleteLabOrder(appointmentId, orderId, updatedAt);
    } else if (orderType === OrderTypeEnum.nutritionMeal) {
      return this.deleteNutritionMealOrder(appointmentId, orderId, updatedAt);
    } else if (orderType === OrderTypeEnum.Procedure || orderType === OrderTypeEnum.TreatmentPlanProcedure || orderType === OrderTypeEnum.TreatmentProcedure) {
      return this.deleteProcedureOrder(appointmentId, orderId, updatedAt);
    } else if (orderType === OrderTypeEnum.DoctorFee) {
      return this.placeDoctorFeeOrder(stationId, appointmentId, orderOrigin, isVisit, {items: [], deletedItems: [orderId]}).pipe(
        map(response => !!response)
      );
    } else if (orderType === OrderTypeEnum.Supply) {
      return this.deleteSupplyOrder(appointmentId, orderId, updatedAt, groupCommercialOrder);
    } else if (orderType === OrderTypeEnum.Medication || orderType === OrderTypeEnum.TreatmentPlan) {
      return this.deleteMedicationOrder(appointmentId, orderId, updatedAt);
    }
  }

  checkPatientHasAllergyOfMedicine(patientId: string, filters: ICustomFilters = {}): Observable<IPatientMedicineAllergyStatus> {
    return this.masterDataHttpService.checkPatientHasAllergy(patientId, filters).pipe(
      pluck('data')
    );
  }

  checkPatientHasAllergyForMedicines(patientId: string, medicineIds: string[]): Observable<IPatientMedicineAllergyStatus[]> {
    return this.masterDataHttpService.checkPatientHasAllergies(patientId, medicineIds).pipe(
      pluck('data', 'items')
    );
  }

  checkMedicineInteractionAllergy(patientId: string, payload: MedicineInteractionAllergyPayload): Observable<MedicationInteractionAllergyViewModel> {
    return this.masterDataHttpService.checkMedicineInteractionAllergy(patientId, payload).pipe(
      pluck('data'),
      map(response => MedicationInteractionAllergyViewModel.mapFromHttpModel(response, payload))
    );
  }

  getCurrentMedication(patientId: string): Observable<CurrentMedicationViewModel[]> {
    return this.masterDataHttpService.getCurrentMedication(patientId).pipe(
      pluck('data', 'items'),
      map(response => response.map(data => CurrentMedicationViewModel.mapFromHttpModel(data)))
    );
  }

  placeOrBookingOrder(appointmentId: string, payload: OrOrderPayload): Observable<IPatientOrder> {
    return this.patientOrderHttpService.placeOrBookingOrder(appointmentId, payload).pipe(
      pluck('data'),
    );
  }

  getMedicineItemReturnLog(pdMedicationId: string, filters: ICustomFilters = {}): Observable<IPageableData<ReturnedPatientOrderViewModel>> {
    return this.medicationOrderHttpService.getMedicineItemReturnLog(pdMedicationId, filters).pipe(
      pluck('data'),
      map(response => {
        const {items, ...pagination} = response;
        const data = items.map(item => ReturnedPatientOrderViewModel.mapFromReturnMedicineHttp(item))
        return {items: data, ...pagination}
      })
    );
  }

  getSupplyItemReturnLog(planPatientOrderId: string, filters: ICustomFilters = {}): Observable<IPageableData<ReturnedPatientOrderViewModel>> {
    return this.supplyOrderHttpService.getSupplyItemReturnLog(planPatientOrderId, filters).pipe(
      pluck('data'),
      map(response => {
        const {items, ...pagination} = response;
        const data = items.map(item => ReturnedPatientOrderViewModel.mapFromReturnMedicineHttp(item))
        return {items: data, ...pagination}
      })
    );
  }

  getUsedMedicalPackageDetail(
    data: Array<RadiologyFormDataModel | PathologyFormDataModel | MedicationFormDataModel | ProcedureFormDataModel | NutritionMealFormDataModel | SupplyFormDataModel>,
    currentPageData: Array<RadiologyFormDataModel | PathologyFormDataModel | MedicationFormDataModel | ProcedureFormDataModel | NutritionMealFormDataModel | SupplyFormDataModel>
  ): Observable<Array<RadiologyFormDataModel | PathologyFormDataModel | MedicationFormDataModel | ProcedureFormDataModel | NutritionMealFormDataModel | SupplyFormDataModel>> {
    const selectionItemIds = currentPageData.filter(item => item.isMedicalPackageOrder).map(item => item.packageSelectionItemId);
    return this.masterDataHttpService.getUsedMedicalPackageDetail(data, {
      selectionItemIds,
      callbackFn: (obj, usedMedicalPackages) => {
        const packageInfo = usedMedicalPackages.find(pkg => pkg.packageSelectionItemId === obj.packageSelectionItemId);
        if (packageInfo) {
          obj.medicalPackage = {name: packageInfo.packageName || '', xref: packageInfo.packageXref || ''} as IMedicalPackage;
        }
        return obj;
      }
    })
  }

  private getFilteredOrders<T, U>(newlyAddedOrders: Array<T>, uniqueIdKeyForNewOrder: keyof T, orders: Array<U>, uniqueIdKey: keyof U): Array<U> {
    if (!newlyAddedOrders.length) {
      return orders;
    }
    // @ts-ignore
    return orders.filter(order => !newlyAddedOrders.some(newOrder => newOrder[uniqueIdKeyForNewOrder] === order[uniqueIdKey]));
  }
}
