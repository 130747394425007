import {isArray} from '@v2/core/functions/functions';
import {IDoctorNote} from '@v2/core/models/masterdata/IDoctorNote';

export class DoctorNoteViewModel {
  id: string;
  finalDiagnosis: string;
  treatmentSummary: string;
  adviceFollowup: string;
  instruction: string;

  static mapFromHttpModel(httpData: IDoctorNote): DoctorNoteViewModel {
    const viewData = new DoctorNoteViewModel();
    viewData.id = httpData.id;
    viewData.finalDiagnosis = isArray(httpData.finalDiagnosis) ? httpData.finalDiagnosis.map(diagnosis => diagnosis.diagnosis).join(', ') : '';
    viewData.treatmentSummary = httpData.treatmentSummary;
    viewData.adviceFollowup = httpData.adviceFollowUp;
    viewData.instruction = httpData.instructionNote;
    return viewData;
  }
}
