import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslocoModule} from '@ngneat/transloco';
import {PipeModule} from '@v2/core/pipes/pipe.module';
import {CommonPipesModule} from '../../../../../shared/pipes/common-pipes/common-pipes.module';
import {AgeDisplayModule} from '../../../../shared-v2/components/age-display/age-display.module';
import {DobDisplayModule} from '../../../../shared-v2/components/dob-display/dob-display.module';
import {PrintMedicalRecordStickerComponent} from './print-medical-record-sticker.component';

@NgModule({
  declarations: [PrintMedicalRecordStickerComponent],
  exports: [
    PrintMedicalRecordStickerComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    PipeModule,
    CommonPipesModule,
    DobDisplayModule,
    AgeDisplayModule
  ]
})
export class PrintMedicalRecordStickerModule {
}
