import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, pluck} from 'rxjs/operators';
import {BaseHttpService} from '../../../../core/http/base-http.service';
import {BaseModel} from '../../../../core/http/models';

@Injectable()
export class ArchivedDataService {

  constructor(
    private baseHttpService: BaseHttpService
  ) { }

  checkPatientHasMigratedData(patientId: string): Observable<boolean> {
    return this.baseHttpService.get<BaseModel<boolean>>(`patient/${patientId}/has-archived-data`).pipe(
      pluck('data'),
      catchError(_ => of(false))
    );
  }
}
