import { Pipe, PipeTransform } from '@angular/core';
import {MedicalResultOutputEnum} from '@v2/core/enums/medical-result-output.enum';

@Pipe({
  name: 'medicalResultOutputClass'
})
export class MedicalResultOutputClassPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case MedicalResultOutputEnum.LOW:
      case MedicalResultOutputEnum.HIGH:
        return 'text-tertiary';
      case MedicalResultOutputEnum.ABNORMAL:
      case MedicalResultOutputEnum.LOWER_PANIC:
      case MedicalResultOutputEnum.UPPER_PANIC:
        return 'text-burnt-sienna';
      case MedicalResultOutputEnum.NORMAL:
        return 'text-eucalyptus';
      case MedicalResultOutputEnum.NOT_AVAILABLE:
        return 'text-gray';
      default:
        return '';
    }
  }

}
