import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateChecker'
})
export class DateCheckerPipe implements PipeTransform {

  transform(value: string, args: any, operator: string = 'ge'): boolean {
    const elementDate = value && moment(value).format('YYYY-MM-DD')
    const packageDate = args && moment(args).format('YYYY-MM-DD')
    if (elementDate && packageDate) {
      if (operator === 'gt') {
        return elementDate > packageDate;
      } else if (operator === 'ge') {
        return elementDate >= packageDate;
      } else if (operator === 'lt') {
        return elementDate < packageDate;
      } else if (operator === 'le') {
        return elementDate <= packageDate;
      } else if (operator === 'e') {
        return elementDate === packageDate;
      } else {
        return false
      }
    }
    return false

  }

}
