import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ArchivedDataComponent} from './archived-data.component';
import {MatRadioModule} from '@angular/material/radio';
import {TRANSLOCO_SCOPE, TranslocoModule} from '@ngneat/transloco';

@NgModule({
  declarations: [ArchivedDataComponent],
  exports: [
    ArchivedDataComponent
  ],
  imports: [
    CommonModule,
    MatRadioModule,
    TranslocoModule
  ],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'common'}],
})
export class ArchivedDataModule {
}
