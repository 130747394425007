import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CommonDatePipe} from '@v2/core/pipes/common-date.pipe';
import {CommonTimePipe} from '@v2/core/pipes/common-time.pipe';
import {DateCheckerPipe} from '@v2/core/pipes/date-checker.pipe';
import {HighlightTextPipe} from '@v2/core/pipes/highlight-text.pipe';
import {IsEmptyPipe} from '@v2/core/pipes/is-empty.pipe';
import {SeverityStatusClassPipe} from '@v2/core/pipes/severity-status-class.pipe';
import {TimeDurationPipe} from '@v2/core/pipes/time-duration.pipe';
import {AdmitTransferRequestClassPipe} from './admit-transfer-request.pipe';
import {AppointmentStatusClassPipe} from './appointment-status-class.pipe';
import {ArraySlicePipe} from './array-slice.pipe';
import {CommonDateTimePipe} from './common-date-time.pipe';
import {DurationFromNowPipe} from './duration-from-now.pipe';
import {FsoBankNameTranslationPipe} from './fso-bank-name-translation.pipe';
import {FsoFacilityResourceTranslationPipe} from './fso-facility-resource-translation.pipe';
import {GetAgeFromDOBPrefPipe} from './get-age-from-dob-pref.pipe';
import {GetDOBPipe} from './get-dob.pipe';
import {IncomeTypeTranslationPipe} from './income-type-translation.pipe';
import {MasterDataTranslationPipe} from './master-data-translation.pipe';
import {MedicalResultOutputClassPipe} from './medical-result-output-class.pipe';
import {OrOrderStatusClassPipe} from './or-order-status-class.pipe';
import {OrderSeverityStatusClassPipe} from './order-severity.pipe';
import {PatientNameTranslationV2Pipe} from './patient-name-translation-v2.pipe';
import {PharmacyOrderTypeClassPipe} from './pharmacy-order-type-class.pipe';
import {SecondsToMinutePipe} from './seconds-to-minute.pipe';
import { OrdinalDatePipe } from '@v2/core/pipes/ordinal-date-pipe';

@NgModule({
  declarations: [
    IsEmptyPipe,
    CommonDatePipe,
    CommonTimePipe,
    CommonDateTimePipe,
    PharmacyOrderTypeClassPipe,
    OrderSeverityStatusClassPipe,
    TimeDurationPipe,
    SeverityStatusClassPipe,
    OrdinalDatePipe,
    AdmitTransferRequestClassPipe,
    MasterDataTranslationPipe,
    MedicalResultOutputClassPipe,
    ArraySlicePipe,
    HighlightTextPipe,
    SecondsToMinutePipe,
    IncomeTypeTranslationPipe,
    PatientNameTranslationV2Pipe,
    DateCheckerPipe,
    OrOrderStatusClassPipe,
    GetDOBPipe,
    GetAgeFromDOBPrefPipe,
    DurationFromNowPipe,
    AppointmentStatusClassPipe,
    FsoBankNameTranslationPipe,
    FsoFacilityResourceTranslationPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ArraySlicePipe,
    IsEmptyPipe,
    CommonDatePipe,
    CommonTimePipe,
    CommonDateTimePipe,
    PharmacyOrderTypeClassPipe,
    OrderSeverityStatusClassPipe,
    TimeDurationPipe,
    SeverityStatusClassPipe,
    AdmitTransferRequestClassPipe,
    MasterDataTranslationPipe,
    MedicalResultOutputClassPipe,
    SecondsToMinutePipe,
    IncomeTypeTranslationPipe,
    PatientNameTranslationV2Pipe,
    HighlightTextPipe,
    DateCheckerPipe,
    OrOrderStatusClassPipe,
    GetAgeFromDOBPrefPipe,
    GetDOBPipe,
    AppointmentStatusClassPipe,
    DurationFromNowPipe,
    OrdinalDatePipe,
  ],
  providers: [DateCheckerPipe]
})
export class PipeModule {
}
