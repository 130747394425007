import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IBase, IPatient} from '@v2/core/models/masterdata';

import {BaseHttpService} from '@v2/core/services/base-http.service';
import {Observable} from 'rxjs';
import {map, pluck} from 'rxjs/operators';
import {PatientInfoModalViewModel} from '../../models/view/patient-info-modal.view.model';

@Injectable()
export class PatientInfoModalHttpService {

  constructor(private baseHttpService: BaseHttpService) {

  }

  getPatientModelInfo(patientId: string, showRegistrationNote = false): Observable<PatientInfoModalViewModel> {
    const filters = {
      contact: true,
      identity: true,
      paymentPlans: true,
      address: true,
      registrationNote: showRegistrationNote
    };

    const params = new HttpParams().append('filters', JSON.stringify(filters));
    return this.baseHttpService.get<IBase<IPatient>>(`patient-by-id/${patientId}`, params).pipe(
      pluck('data'),
      map(value => PatientInfoModalViewModel.mapFromHttpModel(value))
    );
  }
}
