import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConcurrencyScopeEnum} from '@v2/core/enums/concurrency-scope.enum';
import {OrderOriginEnum} from '@v2/core/enums/order-origin.enum';
import {IBase} from '@v2/core/models/masterdata';
import {IPatientOrder} from '@v2/core/models/masterdata/IPatientOrder.master-data';
import {IDeletableEntity} from '@v2/core/models/masterdata/IPost.master-data';
import {IServiceBilling} from '@v2/core/models/masterdata/IServiceBilling.master-data';
import {BaseHttpService} from '@v2/core/services/base-http.service';
import {Observable} from 'rxjs';
import {DoctorFeeOrderPayload} from '../models/payload/doctor-fee-order.payload';
import {OrOrderPayload} from '../models/payload/or-order.payload';

@Injectable()
export class PatientOrderHttpService {

  constructor(
    private baseHttpService: BaseHttpService
  ) {
  }

  getDoctorFeeOrders(appointmentId: string, isVisit: boolean, stationId: string, orderOrigin: string): Observable<IBase<IServiceBilling>> {
    const endPoint = this.getDoctorFeeEndpoint(orderOrigin, stationId, appointmentId)
    return this.baseHttpService.get<IBase<IServiceBilling>>(endPoint);
  }

  placeDoctorFeeOrders(stationId: string, appointmentId: string, orderOrigin: string, isVisit: boolean, payload: IDeletableEntity<DoctorFeeOrderPayload, string>): Observable<IBase<IServiceBilling>> {
    const endPoint = this.getDoctorFeeEndpoint(orderOrigin, stationId, appointmentId);
    return this.baseHttpService.postWrapper<IBase<IServiceBilling>>(endPoint, payload, ConcurrencyScopeEnum.DOCTOR_FEE_PATIENT_ORDER);
  }

  placeOrBookingOrder(appointmentId: string, payload: OrOrderPayload): Observable<IBase<IPatientOrder>> {
    return this.baseHttpService.postWrapper<IBase<IPatientOrder>>(`appointment/${appointmentId}/or-order`, payload, ConcurrencyScopeEnum.OR_REQUEST, new HttpParams(), payload.uniqueIdForConcurrency);
  }

  private getDoctorFeeEndpoint(orderOrigin: string, stationId: string, appointmentId: string): string {
    switch (orderOrigin) {
      case OrderOriginEnum.drOpd:
        return `opd/doctor/${stationId}/appointment/${appointmentId}/service-billing`
      case OrderOriginEnum.drIpd:
        return `ipd/doctor/${stationId}/admission-appointment/${appointmentId}/service-billing`
    }
  }
}
