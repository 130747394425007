import {formatDateTime, getCurrentDateTime} from '@v2/core/functions/functions';
import {ConcurrencyPayload, ConcurrencyRequest, IConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {INurseNote} from '@v2/core/models/masterdata/INurseNote';
import {PostModel} from '../../../../core/models';

export class NurseNoteViewModel implements IConcurrencyRequest {

  id: string;
  note: string;
  recordedBy?: string;
  recordedTime?: string;
  editedBy?: string;
  editedTime?: string;
  noteInfo?: string;
  updatedAt?: string;

  static mapFromApiModel(data: INurseNote): NurseNoteViewModel {
    const recordedBy = data.recordedBy ? `${data.recordedBy.firstName} ${data.recordedBy.lastName}` : '';
    const editedBy = data.editedBy ? `${data.editedBy.firstName} ${data.editedBy.lastName}` : '';
    const time = !!data.editedTime ? formatDateTime(data.editedTime, 'HH:mm') : formatDateTime(data.recordedTime, 'HH:mm');
    const noteInfo = editedBy ? `Last Updated By ${editedBy} (Nurse) @ ${time}` : `By ${recordedBy} (Nurse) @ ${time}`;

    const viewModel = new NurseNoteViewModel();

    viewModel.id = data.id;
    viewModel.note = data.note;
    viewModel.recordedBy = recordedBy;
    viewModel.recordedTime = data.recordedTime;
    viewModel.editedBy = editedBy;
    viewModel.editedTime = data.editedTime;
    viewModel.noteInfo = noteInfo;
    viewModel.updatedAt = data.updatedAt;

    return viewModel;
  }
}

export class NurseNoteFormModel extends ConcurrencyRequest {

  id: string;
  note: string;

  static mapFromApiModel(nurseData: INurseNote): NurseNoteFormModel {
    const formModel = new NurseNoteFormModel();

    formModel.id = nurseData.id;
    formModel.note = nurseData.note;
    formModel.mapConcurrencyRequest(nurseData);

    return formModel;
  }

  static mapFromViewModel(data: NurseNoteViewModel): NurseNoteFormModel {
    const formModel = new NurseNoteFormModel();

    formModel.id = data.id;
    formModel.note = data.note;
    formModel.updatedAt = data.updatedAt;

    return formModel;
  }
}

export class NurseNotePostModel extends ConcurrencyPayload {

  id: string;
  note: string;
  recordedTime: string;

  static mapFromFormModel(notes: NurseNoteFormModel[], deletedItems: string[]): PostModel<NurseNotePostModel, string> {

    const items: NurseNotePostModel[] = [];
    notes.forEach(note => {
      const payload = new NurseNotePostModel();
      payload.id = note.id;
      payload.note = note.note;
      payload.recordedTime = getCurrentDateTime();
      payload.mapConcurrencyPayload(note);
      items.push(payload);
    });

    return new PostModel<NurseNotePostModel, string>(items, deletedItems);
  }
}
