import {AppSecurityRoles, SubMenuModel} from '../../../../core/models/menu/sub-menu.model';
import {VISIT_PD_PR_ROUTES} from '../url-routs';

// NOTE: If you update any route title then please update 'his-angular/client/src/assets/i18n/submenu/en.json & th.json' translation files

export const NS_VISIT_PD: Array<SubMenuModel> = [
  {
    id: 1,
    title: 'Vitals/ Chief\nComplaints',
    url: VISIT_PD_PR_ROUTES.vitalsChiefComplaint,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 2,
    title: 'Nurse Note',
    url: VISIT_PD_PR_ROUTES.nurseNote,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 3,
    title: 'Physical Examination',
    url: VISIT_PD_PR_ROUTES.physicalExamination,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 4,
    title: 'Patient Order',
    url: VISIT_PD_PR_ROUTES.patientOrder,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 5,
    title: 'Diagnosis',
    url: VISIT_PD_PR_ROUTES.diagnosis,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  /*{
    id: 6,
    title: 'EMR',
    url: VISIT_PD_PR_ROUTES.emr,
    allowedRoles: [AppSecurityRoles.ALL]
  },*/
  {
    id: 6,
    title: 'OPD\nTreatment',
    url: VISIT_PD_PR_ROUTES.opdTreatment,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 7,
    title: 'Reports',
    url: VISIT_PD_PR_ROUTES.reports,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 8,
    title: 'Doctor Note',
    url: VISIT_PD_PR_ROUTES.doctorNote,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 9,
    title: 'Service Billing',
    url: VISIT_PD_PR_ROUTES.serviceBilling,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 10,
    title: 'OR Request/\nSurgical Instruction',
    url: VISIT_PD_PR_ROUTES.orSurgicalIns,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 11,
    title: 'Next\nAppointment',
    url: VISIT_PD_PR_ROUTES.nextAppointment,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 12,
    title: 'Insurance Form',
    url: VISIT_PD_PR_ROUTES.insuranceForm,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 13,
    title: 'Medical\nCertificate',
    url: VISIT_PD_PR_ROUTES.medicalCertificate,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 14,
    title: 'Consultation\nTransfer',
    url: VISIT_PD_PR_ROUTES.consultationTransfer,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 15,
    title: 'Medical Records',
    url: VISIT_PD_PR_ROUTES.medicalRecords,
    allowedRoles: [AppSecurityRoles.ALL]
  }
]
