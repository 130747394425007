import {PatientManagementRequestTypeEnum} from '../../visits/shared/enums/patient-management.enum';

export function getPatientManagementRequestTypeUrl(type: string) {
  switch (type) {
    case PatientManagementRequestTypeEnum.BED_TRANSFER_UI:
      return PatientManagementRequestTypeEnum.BED_TRANSFER_ROUTE_PARAM;
    case PatientManagementRequestTypeEnum.JOINT_ADMISSION_UI:
      return PatientManagementRequestTypeEnum.JOINT_ADMISSION_ROUTE_PARAM;
    case PatientManagementRequestTypeEnum.CONSULTATION_UI:
      return PatientManagementRequestTypeEnum.CONSULTATION_ROUTE_PARAM;
    case PatientManagementRequestTypeEnum.CASE_TRANSFER_UI:
      return PatientManagementRequestTypeEnum.CASE_TRANSFER_ROUTE_PARAM;
    case PatientManagementRequestTypeEnum.ADD_BED_UI:
      return PatientManagementRequestTypeEnum.ADD_BED_ROUTE_PARAM;
    case PatientManagementRequestTypeEnum.TRANSFER_BED_UI:
      return PatientManagementRequestTypeEnum.TRANSFER_BED_ROUTE_PARAM;
    case PatientManagementRequestTypeEnum.RELEASE_BED_UI:
      return PatientManagementRequestTypeEnum.RELEASE_BED_ROUTE_PARAM;
    default:
      return type;
  }
}

export function getPatientManagementRequestTypeUILabel(type: string) {
  switch (type) {
    case PatientManagementRequestTypeEnum.BED_TRANSFER:
      return PatientManagementRequestTypeEnum.BED_TRANSFER_UI;
    case PatientManagementRequestTypeEnum.JOINT_ADMISSION:
      return PatientManagementRequestTypeEnum.JOINT_ADMISSION_UI;
    case PatientManagementRequestTypeEnum.CONSULTATION:
      return PatientManagementRequestTypeEnum.CONSULTATION_UI;
    case PatientManagementRequestTypeEnum.CASE_TRANSFER:
      return PatientManagementRequestTypeEnum.CASE_TRANSFER_UI;
    default:
      return type;
  }
}
