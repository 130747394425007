import { Pipe, PipeTransform } from '@angular/core';
import { getOrdinalDate } from '@v2/core/functions/functions';

@Pipe({
  name: 'ordinalDatePipe',
})

export class OrdinalDatePipe implements PipeTransform {

  transform(value: any): string | null {
    if (!value) {
      return null;
    }
    return getOrdinalDate(value);
  }
}
