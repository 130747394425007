import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {IBase} from '@v2/core/models/masterdata';
import {IAppUser, IPermissions, ITile, ITilePermissions} from '@v2/core/models/masterdata/IAppUser';

import {NgxPermissionsService} from 'ngx-permissions';

import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {concatMap, distinctUntilChanged, filter, map, pluck, tap} from 'rxjs/operators';
import {FORBIDDEN_URL, MAIN_MENUS_KEYS} from '../../shared/layout/main-menu-routes';
import {MAIN_MENUS, MainMenu} from '../../shared/layout/menu-list';
import {AppUserViewModel} from '../models/app-user.view.model';
import {AppUserService} from './app-user.service';
import {InitService} from './init.service';
import {IpAddressService} from './ip-address.service';

import {JwtService} from './jwt.service';

import {APP_SETTINGS} from './setting';

export interface LoginResponse {
  isOTPRequired: boolean;
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
}

interface VerificationResponse {
  isVerify: boolean;
}

interface VerificationResendCodeResponse {
  success: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  defaultURL = '';

  baseUrl = APP_SETTINGS.api_url;
  urlSeparator = APP_SETTINGS.api_prefix;

  clientId = APP_SETTINGS.client_id;
  clientSecret = APP_SETTINGS.client_secret;
  grantType = APP_SETTINGS.grant_type;
  currentUserPermissions: IPermissions;

  public mainMenus: Array<MainMenu> = [];
  private currentUserSubject = new BehaviorSubject<AppUserViewModel>({} as AppUserViewModel);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  constructor(
    private router: Router,
    private jwtService: JwtService,
    private httpClient: HttpClient,
    private initService: InitService,
    private appUserService: AppUserService,
    private ipAddressService: IpAddressService,
    private permissionsService: NgxPermissionsService
  ) {
    this.initService.getCurrentUser().subscribe((value) => this.setupUser(value));
  }

  isAuthenticated(): boolean {
    return !!this.jwtService.getToken();
  }

  getLoginUserMedicalPartyIdByType(type: string): string {
    const user = this.currentUserSubject.value;
    return user.medicalPartyType === type ? user.medicalPartyId : null;
  }

  setupTokenAndUser(data: LoginResponse): Observable<IAppUser> {
    this.jwtService.saveToken(data.access_token);
    return this.setupUserAndPermission();
  }

  setupUserAndPermission(): Observable<IAppUser> {
    return this.appUserService.getMyInfo().pipe(
      tap((value) => {
        const user = AppUserViewModel.mapFromApiModel(value);
        this.setupUser(user);
      })
    );
  }

  setupUser(user: AppUserViewModel) {
    this.setUserPermissions(user.permissions);
    this.setUpPermissions(user);
    this.currentUserSubject.next(user);
    this.filterOperationalAreas(user);
    this.setDefaultUrl();
  }

  setUpPermissions(user: AppUserViewModel) {
    const allPermissions = user.operationAreas;
    const tilePermissions = Object.keys(user.permissions);
    if (tilePermissions && tilePermissions.length) {
      tilePermissions.forEach((key) => {
        user.permissions[key].map((tile) => {
          allPermissions.push(key + '_' + tile.title);
        });
      });
    }
    this.permissionsService.loadPermissions(allPermissions);
  }

  getFilteredMenus(user: AppUserViewModel): MainMenu[] {
    const mainMenus = JSON.parse(JSON.stringify(MAIN_MENUS)) as MainMenu[];
    return mainMenus.filter((item, index) => {
      item.subMenus = item.subMenus.filter((subMenu) => {
        if (user.permissions[item.key]) {
          const tiles = user.permissions[item.key].map((tile) => tile.title);
          return tiles.includes(subMenu.label);
        }
      });
      return item.subMenus.length && user.operationAreas.includes(item.key);
    });
  }

  filterOperationalAreas(user: AppUserViewModel) {
    this.mainMenus = this.getFilteredMenus(user);
  }

  setUserPermissions(userPermissions: IPermissions) {
    this.currentUserPermissions = userPermissions;
  }

  getOATileDetails(subMenuKey: string) {
    const subMenuSplit = subMenuKey.split('_');
    if (subMenuSplit && subMenuSplit.length) {
      const operationArea = subMenuSplit[0];
      const tile = subMenuKey.substring(subMenuKey.indexOf('_') + 1);
      const allowedTile = this.currentUserPermissions[operationArea].find((allowedTil: ITile) => allowedTil.title === tile);
      return allowedTile
        ? {
          tileId: allowedTile.tileId,
          OAId: allowedTile.OAId,
          isReadAccess: allowedTile.isReadAccess,
          isWriteAccess: allowedTile.isWriteAccess
        }
        : null;
    }
    return null;
  }

  hasWriteAccess(subMenuKey: string) {
    const permissions = this.getOATileDetails(subMenuKey) as ITilePermissions;
    return !!permissions && permissions.isWriteAccess;
  }

  hasReadAccess(subMenuKey: string) {
    const permissions = this.getOATileDetails(subMenuKey) as ITilePermissions;
    return !!permissions && permissions.isReadAccess;
  }

  getCurrentOAId(currentTileName: string) {
    const tileDetails = this.getOATileDetails(currentTileName);
    return !!tileDetails && tileDetails.OAId;
  }

  setDefaultUrl() {
    this.mainMenus = this.mainMenus.filter(menu => menu.label !== MAIN_MENUS_KEYS.registration && menu.label !== MAIN_MENUS_KEYS.paymentType && menu.label !== MAIN_MENUS_KEYS.patientVisit);
    if (this.mainMenus.length > 0) {
      let defaultUrl;
      this.mainMenus.map((mainMenu) => {
        if (mainMenu.subMenus && mainMenu.subMenus.length && !defaultUrl) {
          defaultUrl = mainMenu.subMenus[0].link;
        }
      });
      this.defaultURL = defaultUrl || FORBIDDEN_URL;
    } else {
      this.defaultURL = FORBIDDEN_URL;
    }
  }

  flushTokenAndUserInfo(): void {
    this.permissionsService.flushPermissions();
    this.jwtService.destroyToken();
    this.currentUserSubject.next({} as AppUserViewModel);
  }

  // <editor-fold desc="API CALLS">
  // TODO needs to move to app-user.service.ts
  loginUser(credentials): Observable<LoginResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Forwarded-For': this.ipAddressService.getIpv4()
    });

    return this.httpClient.post<LoginResponse>(`/api/login`, credentials, {headers}).pipe(pluck('data'));
  }

  verifyOtp_old(data, token: LoginResponse) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Forwarded-For': this.ipAddressService.getIpv4(),
      Authorization: `Bearer ${token.access_token}`
    });

    return this.httpClient.post(`${this.baseUrl}${this.urlSeparator}auth/verify-otp`, data, {headers}).pipe(
      pluck('data'),
      map((response: VerificationResponse) => {
        if (response.isVerify) {
          this.setupTokenAndUser(token);
          return response;
        } else {
          return throwError(response);
        }
      })
    );
  }

  verifyOtp(data, token: LoginResponse): Observable<VerificationResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Forwarded-For': this.ipAddressService.getIpv4(),
      Authorization: `Bearer ${token.access_token}`
    });

    return this.httpClient
      .post<IBase<VerificationResponse>>(`${this.baseUrl}${this.urlSeparator}auth/verify-otp`, data, {headers})
      .pipe(
        pluck('data'),
        filter((value) => value.isVerify),
        concatMap((value) => this.setupTokenAndUser(token).pipe(map(() => value)))
      );
  }

  resendCode(token: LoginResponse): Observable<VerificationResendCodeResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Forwarded-For': this.ipAddressService.getIpv4(),
      Authorization: `Bearer ${token.access_token}`
    });

    return this.httpClient
      .post<IBase<VerificationResendCodeResponse>>(`${this.baseUrl}${this.urlSeparator}auth/resend-otp`, {}, {headers})
      .pipe(pluck('data'));
  }

  getRelativeHomeUrl() {
    const url: string = localStorage.getItem('currentUrl');
    let homepageUrl = this.defaultURL;
    if (url && this.mainMenus.length) {
      this.mainMenus.map((menu: MainMenu) => {
        menu.subMenus.map((submenu) => {
          if (submenu.link && submenu.link.split('/')[1] === url.split('/')[1]) {
            homepageUrl = menu.subMenus[0].link;
          }
        });
      });
    }
    return homepageUrl || this.defaultURL;
  }

  // </editor-fold>
}
