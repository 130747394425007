import {ICatalogElement, IMedicineInteractionAllergy} from '@v2/core/models/masterdata';
import {IDrugAllergy} from '@v2/core/models/masterdata/IDrugAllergy.master-data';
import {IDrugInteraction} from '@v2/core/models/masterdata/IDrugInteraction.master-data';
import {MedicineInteractionAllergyPayload} from '../../../shared-v2/resources/models/payload/medicine-interaction-allergy.payload';

export class DrugInteractionViewModel {
  id: string;
  drugInteractionXref: string;
  brandName1: string;
  genericName1: string;
  xref1: string;
  strengthUnit1: string;
  brandName2: string;
  brandName1Id: string;
  brandName2Id: string;
  genericName2: string;
  xref2: string;
  strengthUnit2: string;
  englishNote: string;
  thaiNote: string;

  static mapFromHttpModel(httpData: IDrugInteraction): DrugInteractionViewModel {
    let drug1: ICatalogElement;
    let drug2: ICatalogElement;
    if (httpData.drugs && httpData.drugs.length) {
      drug1 = httpData.drugs[0];
      drug2 = httpData.drugs[1];
    }

    const viewData = new DrugInteractionViewModel();
    viewData.id = httpData.id;
    viewData.drugInteractionXref = httpData.xref;
    if (drug1) {
      viewData.brandName1 = drug1.brandName;
      viewData.genericName1 = drug1.genericName;
      viewData.xref1 = drug1.xref;
      viewData.strengthUnit1 = drug1.strengthUnit;
      viewData.brandName1Id = drug1.id;
    }
    if (drug2) {
      viewData.brandName2 = drug2.brandName;
      viewData.brandName2Id = drug2.id;
      viewData.genericName2 = drug2.genericName;
      viewData.xref2 = drug2.xref;
      viewData.strengthUnit2 = drug2.strengthUnit;
    }
    viewData.englishNote = httpData.englishInteractionNote;
    viewData.thaiNote = httpData.thaiInteractionNote;
    return viewData;
  }
}

export class DrugAllergyViewModel {
  id: string;
  xref: string;
  brandName: string;
  genericName: string;
  drugType: string;
  englishNote: string;
  thaiNote: string;

  static mapFromHttpModel(httpData: IDrugAllergy): DrugAllergyViewModel {
    const viewData = new DrugAllergyViewModel();
    viewData.id = httpData.id;
    viewData.xref = httpData.xref;
    if (httpData.drug) {
      viewData.brandName = httpData.drug.brandName;
      viewData.genericName = httpData.drug.genericName;
    }
    viewData.drugType = httpData.drugType && httpData.drugType.label;
    viewData.englishNote = httpData.englishAllergyNote;
    viewData.thaiNote = httpData.thaiAllergyNote;
    return viewData;
  }
}

export class MedicationInteractionAllergyViewModel {
  interaction: DrugInteractionViewModel[];
  allergy: DrugAllergyViewModel[];
  showDrugAllergy: boolean;

  static mapFromHttpModel(httpData: IMedicineInteractionAllergy, payload: MedicineInteractionAllergyPayload): MedicationInteractionAllergyViewModel {
    const interactions: DrugInteractionViewModel[] = [];
    let allergies: DrugAllergyViewModel[] = [];

    if (httpData) {
      if (httpData.interactions && httpData.interactions.length) {
        httpData.interactions.forEach(interaction => {
          const data = interaction.drugs.find(drug => drug.id !== payload.medicineId);
          if (data) {
            interactions.push(DrugInteractionViewModel.mapFromHttpModel(interaction));
          }
        });
      }

      if (httpData.allergies && httpData.allergies.length) {
        allergies = httpData.allergies.map(allergy => DrugAllergyViewModel.mapFromHttpModel(allergy))
      }
    }

    const viewData = new MedicationInteractionAllergyViewModel();
    viewData.interaction = interactions;
    viewData.allergy = allergies;
    return viewData;
  }
}

export class InteractedDrugs {
  id: string;
  brand: string;

  static mapFromDrugInteractionViewModel(medicineId: string, viewData: DrugInteractionViewModel[]): InteractedDrugs[] {
    let data: InteractedDrugs[] = [];
    if (Array.isArray(viewData)) {
      data = viewData.map(interaction => {
        if (interaction.brandName1Id === medicineId) {
          const drug = new InteractedDrugs();
          drug.id = interaction.brandName2Id;
          drug.brand = interaction.brandName2;
          return drug;
        } else if (interaction.brandName2Id === medicineId) {
          const drug = new InteractedDrugs();
          drug.id = interaction.brandName1Id;
          drug.brand = interaction.brandName1;
          return drug;
        }
        return null;
      }).filter(drug => !!drug);
    }
    return data;
  }
}
