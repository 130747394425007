import {isSameDate} from '@v2/core/functions/functions';
import {IBatchNumber, IBatchNumberLog, IBatchNumberMinimal, IInvReceivedItemForPatientOrder} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';

export class BatchNumberViewModel extends ConcurrencyRequest {
  id: string;
  batchId: string;
  manufacturingDate: string;
  xref: string;
  unitOfMeasure: number;
  unitOfMeasureLabel: string;
  batchNumber: string;
  expDate: string;
  remainingQty: number;
  availableRemainingQty: number;
  adjustedRemainingQty: number;
  barcode: string;
  inventoryNote: string;
  deductedQty: number;
  invReceivedItemId: string;

  static mapFromHttpModel(httpData: IBatchNumber): BatchNumberViewModel {
    const viewData = new BatchNumberViewModel();
    viewData.id = httpData.id;
    viewData.batchId = httpData.batchId;
    viewData.xref = httpData.xref ? httpData.xref : '-';
    if (httpData.unitOfMeasure) {
      viewData.unitOfMeasure = httpData.unitOfMeasure.key;
      viewData.unitOfMeasureLabel = httpData.unitOfMeasure.label;
    }
    if (httpData.batchNumDetails) {
      viewData.batchNumber = httpData.batchNumDetails.batchNumber;
      viewData.expDate = httpData.batchNumDetails.expDate;
      viewData.remainingQty = httpData.batchNumDetails.remainingQty;
      viewData.availableRemainingQty = httpData.batchNumDetails.remainingQty;
      viewData.adjustedRemainingQty = httpData.batchNumDetails.totalQty || 0;
    }
    viewData.manufacturingDate = httpData.manufacturingDate;
    viewData.barcode = httpData.barcode;
    viewData.inventoryNote = httpData.inventoryNote;
    viewData.mapConcurrencyRequest(httpData.batchNumDetails)
    return viewData;
  }

  static mapFromMinimalHttpModel(httpData: IBatchNumberMinimal): BatchNumberViewModel {
    const viewData = new BatchNumberViewModel();
    viewData.id = httpData.id;
    viewData.batchId = httpData.id;
    viewData.batchNumber = httpData.batchNumber;
    viewData.expDate = httpData.expDate;
    viewData.remainingQty = httpData.remainingQty;
    viewData.availableRemainingQty = httpData.remainingQty;
    return viewData;
  }

  static mapFromInventoryReceivedItem(httpData: IInvReceivedItemForPatientOrder) {
    const viewModel = new BatchNumberViewModel();

    viewModel.deductedQty = httpData.qty;
    if (httpData.invReceivedItem) {
      viewModel.id = httpData.invReceivedItem.batchId;
      if (httpData.invReceivedItem.batchNum) {
        viewModel.batchId = httpData.invReceivedItem.batchNum.id;
        viewModel.remainingQty = httpData.invReceivedItem.batchNum.remainingQty;
        viewModel.batchNumber = httpData.invReceivedItem.batchNum.batchNumber;
        viewModel.expDate = httpData.invReceivedItem.batchNum.expDate;
        viewModel.manufacturingDate = httpData.invReceivedItem.batchNum.mfdDate;
        viewModel.barcode = httpData.invReceivedItem.batchNum.barcode;
        viewModel.unitOfMeasureLabel = httpData.invReceivedItem.batchNum.dispenseUnit && httpData.invReceivedItem.batchNum.dispenseUnit.label
      }
    }
    return viewModel;
  }
}

export class BatchNumberLogViewModel {
  batchNo: string;
  prevManufactureDate: string;
  manufactureDate: string;
  prevExpiryDate: string;
  expiryDate: string;
  previousRemainingQty: number;
  adjustedRemainingQty: number;
  adjustmentNote: string;
  user: string;
  dateTime: string;
  qtyChanged: boolean;
  mfgDateChanged: boolean;
  expDateChanged: boolean;

  static mapFromHttpModel(httpData: IBatchNumberLog): BatchNumberLogViewModel {
    const viewData = new BatchNumberLogViewModel();
    viewData.batchNo = httpData.newBatchNumber;
    viewData.prevManufactureDate = httpData.oldMfdDate;
    viewData.manufactureDate = httpData.newMfdDate;
    viewData.mfgDateChanged = !isSameDate(httpData.oldMfdDate, httpData.newMfdDate);
    viewData.prevExpiryDate = httpData.oldExpDate;
    viewData.expiryDate = httpData.newExpDate;
    viewData.expDateChanged = !isSameDate(httpData.oldExpDate, httpData.newExpDate);
    viewData.previousRemainingQty = httpData.oldRemainingQty;
    viewData.adjustedRemainingQty = httpData.newRemainingQty;
    viewData.qtyChanged = viewData.previousRemainingQty !== viewData.adjustedRemainingQty;
    viewData.adjustmentNote = httpData.adjustmentNote;
    viewData.user = httpData.adjustedBy ? httpData.adjustedBy.fullName : '';
    viewData.dateTime = httpData.adjustedDT;
    return viewData;
  }
}
