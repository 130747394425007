import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule} from '@angular/forms';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatIconModule,
  MatMenuModule,
  MatProgressSpinnerModule
} from '@angular/material';
import {MdePopoverModule} from '@material-extended/mde';
import {TRANSLOCO_SCOPE, TranslocoModule} from '@ngneat/transloco';
import {PipeModule} from '@v2/core/pipes/pipe.module';
import {NgxPrintModule} from 'ngx-print';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {AgeDisplayModule} from '../../../v2/shared-v2/components/age-display/age-display.module';
import {DobDisplayModule} from '../../../v2/shared-v2/components/dob-display/dob-display.module';
import {LoadingButtonModule} from '../../../v2/shared-v2/components/loading-button/loading-button.module';
import {PatientOrderService} from '../../../v2/shared-v2/components/patient-order/patient-order.service';
import {PatientOrderHttpService} from '../../../v2/shared-v2/components/patient-order/resources/http-service/patient-order.http.service';
import {ProfilePicModule} from '../../../v2/shared-v2/components/profile-pic/profile-pic.module';
import {NoteAlertModule} from '../../../v2/shared-v2/module/note-alert/note-alert.module';
import {LabOrderHttpService} from '../../../v2/shared-v2/resources/http-service/lab-order.http.service';
import {MedicationOrderHttpService} from '../../../v2/shared-v2/resources/http-service/medication-order-http.service';
import {NutritionMealOrderHttpService} from '../../../v2/shared-v2/resources/http-service/nutrition-meal-order-http.service';
import {PatientHttpService} from '../../../v2/shared-v2/resources/http-service/patient-http.service';
import {ProcedureOrderHttpService} from '../../../v2/shared-v2/resources/http-service/procedure-order.http.service';
import {RadiologyOrderHttpService} from '../../../v2/shared-v2/resources/http-service/radiology-order.http.service';
import {SupplyOrderHttpService} from '../../../v2/shared-v2/resources/http-service/supply-order-http.service';
import {CommonDirectiveModule} from '../../directive/common-directive.module';
import {MedicalPackageHttpService} from '../../module/medical-package/services/medical-package-http.service';
import {CommonPipesModule} from '../../pipes/common-pipes/common-pipes.module';
import {PaymentPriorityChipsModule} from '../payment-priority-chips/payment-priority-chips.module';
import {SignatureDisplayModule} from '../signature-display/signature-display.module';
import {AppointmentInfoComponent} from './appointment-info.component';
import {OpdSummaryInfoService} from './opd-summary-info.service';
import {OpdSummaryInfoComponent} from './opd-summary-print-view-modal/opd-summary-info/opd-summary-info.component';
import {OpdSummaryPrintViewModalComponent} from './opd-summary-print-view-modal/opd-summary-print-view-modal.component';
import { WalletManagementApiService } from 'src/app/billing/wallet-management/services/wallet-management-api.service';
import { WalletManagementService } from 'src/app/billing/wallet-management/services/wallet-management.service';

@NgModule({
  declarations: [AppointmentInfoComponent, OpdSummaryPrintViewModalComponent, OpdSummaryInfoComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ProfilePicModule,
    NgxSkeletonLoaderModule,
    CommonPipesModule,
    PaymentPriorityChipsModule,
    MdePopoverModule,
    TranslocoModule,
    PipeModule,
    AgeDisplayModule,
    MatMenuModule,
    CommonDirectiveModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatDialogModule,
    NgxPrintModule,
    LoadingButtonModule,
    MatCheckboxModule,
    FormsModule,
    MatProgressSpinnerModule,
    SignatureDisplayModule,
    DobDisplayModule,
    NoteAlertModule
  ],
  exports: [AppointmentInfoComponent, OpdSummaryInfoComponent],
  providers: [
    {provide: TRANSLOCO_SCOPE, useValue: 'common'},
    PatientOrderService,
    PatientOrderHttpService,
    ProcedureOrderHttpService,
    RadiologyOrderHttpService,
    NutritionMealOrderHttpService,
    LabOrderHttpService,
    SupplyOrderHttpService,
    MedicationOrderHttpService,
    MedicalPackageHttpService,
    PatientHttpService,
    OpdSummaryInfoService,
    WalletManagementService,
    WalletManagementApiService
  ],
  entryComponents: [OpdSummaryPrintViewModalComponent],
})
export class AppointmentInfoModule {
}
