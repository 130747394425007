import { PatientNameTranslation, translatePatientName } from '@v2/core/functions/patient-name-translation';
import { IWalletHistoryLog, WalletListApiModel } from 'src/app/billing/wallet-management/models/api/wallet-list-api.model';
import { IPatient } from '@v2/core/models/masterdata';

export class WalletHistoryLogViewModel {
  date: string;
  user: string;
  transactionAmount: string;
  transactionType: string;
  previousBalance: string
  admissionOrVisit: string
  note: string;

  static mapForViewModel(httpData: IWalletHistoryLog) {
    const viewModel = new WalletHistoryLogViewModel();
    viewModel.date = httpData.createdOn
    viewModel.admissionOrVisit = httpData.admissionOrVisit
    viewModel.user = httpData.createdBy && httpData.createdBy.staffName
    viewModel.transactionAmount = httpData.transactionAmount
    viewModel.transactionType = httpData.historyType && httpData.historyType.label
    viewModel.previousBalance = httpData.previousAmount
    viewModel.note = httpData.note
    return viewModel
  }

}
