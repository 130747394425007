import {Pipe, PipeTransform} from '@angular/core';
import {AppointmentStatusEnum} from '../enums/appointment-status.enum';
import {BedTransferRequestStatusEnum} from '../enums/bed-transfer-request-status.enum';

@Pipe({
  name: 'admitTransferRequestClass'
})
export class AdmitTransferRequestClassPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case AppointmentStatusEnum.RE_SCHEDULED:
      case BedTransferRequestStatusEnum.TRANSFERRED:
      case BedTransferRequestStatusEnum.RELEASED:
        return 'text-orient';
      case AppointmentStatusEnum.NO_SHOW:
        return 'text-hard-purple';
      case AppointmentStatusEnum.ADMITTED:
      case AppointmentStatusEnum.SCHEDULED:
      case BedTransferRequestStatusEnum.ADDED:
        return 'text-eucalyptus';
      case AppointmentStatusEnum.COMPLETED:
        return 'text-dark-blue';
      case AppointmentStatusEnum.CANCELLED:
        return 'text-burnt-sienna';
      default:
        return 'text-black';
    }
    // return value === 'Urgent' ?
  }

}
