import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {matExpansionAnimations} from '@angular/material';
import {Router} from '@angular/router';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {finalize, take} from 'rxjs/operators';
import {AuthService} from 'src/app/core/services';
import {AppUserViewModel} from '../../../core/models/app-user.view.model';
import {AppUserService} from '../../../core/services/app-user.service';
import {HOSPITAL_LOGO} from '../../helpers/app-const';
import {MainMenu} from '../menu-list';
import {SlideInOutAnimation} from './animations';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [matExpansionAnimations.bodyExpansion, SlideInOutAnimation],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'sidebar'}]
})

export class SidebarComponent implements OnInit {
  userData: AppUserViewModel = {} as AppUserViewModel;
  hospitalLogo = HOSPITAL_LOGO;
  isLoading = false;

  mainMenus: MainMenu[] = [];

  constructor(private appUserService: AppUserService,
              private router: Router,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.authService.currentUser.subscribe((userData: AppUserViewModel) => {
      if (Object.keys(userData).length) {
        this.userData = userData;
        this.filterOperationalAreas();
      }
    });
  }

  filterOperationalAreas() {
    this.mainMenus = this.authService.getFilteredMenus(this.userData);
  }

  logout() {
    this.isLoading = true;
    this.appUserService.logout()
      .pipe(take(1),
        finalize(() => {
          this.isLoading = false;
          this.authService.flushTokenAndUserInfo();
          localStorage.removeItem('currentUrl');
          this.router.navigateByUrl('/login').then();
        })
      ).subscribe();
  }
}
