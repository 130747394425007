export class CaseTransferListViewModel {
  constructor(
    public fromDepartmentName: string,
    public fromDoctorName: string,
    public toDepartmentName: string,
    public toDoctorName: string,
    public note: string,
    public fromDate: string,
    public toDate: string,
    public status: string
  ) {
  }
}
