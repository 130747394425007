import {FormControl, Validators} from '@angular/forms';
import {OrderTypeEnum} from '@v2/core/enums/order-type.enum';
import {getElementPrices} from '@v2/core/functions/functions';
import {ICatalogElement} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {IProcedureDetail} from '@v2/core/models/masterdata/IProcedure.master-data';

interface IDoctorFeeOrderForm {
  id: string | FormControl;
  name: ICatalogElement | FormControl;
  xref: string | FormControl;
  catalogElementId: string | FormControl;
  qty: number | FormControl;
  price: number | FormControl;
  prescribedByName: string | FormControl;
  prescribedById: string | FormControl;
  medicalCatalogId: string | FormControl;
  instruction: string | FormControl;
  recordedTime: string | FormControl;
  orderStatus: string | FormControl;
  actualRecordedTime: string | FormControl;
  orderType: string | FormControl;
  catalogGroupId: string | FormControl;
  catalogGroupName: string | FormControl;
  isGroup: boolean | FormControl;
  updatedAt: string | FormControl;
  orderOrigin: string | FormControl;

  isSubmitting?: boolean | FormControl;
  isSubmitted?: boolean | FormControl;
  errorMessage?: string | FormControl;
  isAdded?: boolean | FormControl;
  index?: number | FormControl;
  isFormValid?: boolean | FormControl;
}

export class DoctorFeeOrderForm implements IDoctorFeeOrderForm {
  id = new FormControl();
  name = new FormControl();
  xref = new FormControl();
  catalogElementId = new FormControl();
  qty = new FormControl(null, [Validators.required, Validators.min(1)]);
  price = new FormControl();
  prescribedByName = new FormControl();
  prescribedById = new FormControl();
  medicalCatalogId = new FormControl();
  instruction = new FormControl();
  recordedTime = new FormControl();
  orderStatus = new FormControl();
  actualRecordedTime = new FormControl();
  orderType = new FormControl(OrderTypeEnum.DoctorFee);
  catalogGroupId = new FormControl();
  catalogGroupName = new FormControl();
  isGroup = new FormControl(false);
  updatedAt = new FormControl();
  orderOrigin = new FormControl();

  isSubmitting = new FormControl();
  isSubmitted = new FormControl();
  errorMessage = new FormControl();
  isAdded = new FormControl(false);
  index = new FormControl();
  isFormValid = new FormControl(false);
}

export class DoctorFeeFormDataModel extends ConcurrencyRequest implements IDoctorFeeOrderForm {
  id: string;
  name: ICatalogElement;
  xref: string
  qty: number
  price: number
  prescribedByName: string
  prescribedById: string
  medicalCatalogId: string
  instruction: string;
  catalogElementId: string;
  catalogGroupId: string
  catalogGroupName: string
  recordedTime: string
  actualRecordedTime: string
  orderStatus: string
  orderType: string;
  orderXref: string;
  orderedDate: string;
  orderOrigin: string;

  isGroup: boolean;
  updatedAt: string;

  index?: number;
  isSubmitting?: boolean;
  isSubmitted?: boolean;
  errorMessage?: string;
  isAdded?: boolean;
  isFormValid?: boolean;

  static mapFromHttpModel(httpData: IProcedureDetail, isVisit: boolean): DoctorFeeFormDataModel {
    const formData = new DoctorFeeFormDataModel();
    const elementPrice = getElementPrices(httpData.procedure.procedure.elementPrices);
    const catalogPrice = isVisit ? elementPrice.opdPrice : elementPrice.ipdPrice;
    formData.id = httpData.pdProcedureId;
    formData.name = {xref: httpData.procedure.procedure.xref, name: httpData.procedure.procedureName} as ICatalogElement;
    formData.xref = httpData.procedure.procedure.xref;
    formData.qty = httpData.qty;
    formData.price = catalogPrice;
    if (httpData.prescribedBy) {
      formData.prescribedByName = httpData.prescribedBy.staffName;
      formData.prescribedById = httpData.prescribedBy.staffId;
    }
    if (httpData.procedure) {
      formData.medicalCatalogId = httpData.procedure.procedure && httpData.procedure.procedure.medicalCatalogId;
      formData.instruction = httpData.procedure.procedure && httpData.procedure.procedure.englishInstruction;
      formData.catalogElementId = httpData.procedure.procedureId;
    }
    formData.orderOrigin = httpData.orderOrigin ? httpData.orderOrigin.label : '';
    formData.recordedTime = httpData.recordedTime;
    formData.orderType = OrderTypeEnum.DoctorFee;
    formData.orderedDate = httpData.orderedDate;
    formData.isSubmitted = null;
    formData.errorMessage = null;
    formData.mapConcurrencyRequest(httpData);
    return formData;
  }

}
