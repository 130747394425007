import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';

export interface DialogData {
  heading: string;
  message: string;
  yesButtonLabel: string;
  noButtonLabel: string;
}

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'common'}]
})
export class WarningModalComponent {

  constructor(
    public dialogRef: MatDialogRef<WarningModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}


