import {
  ICatalogElement,
  ICatalogElementMinimal,
  IDepartmentMinimal,
  IDiagnosisDetail,
  IDoctor,
  IIncomeType,
  IMasterData,
  IPatientVisitAdmissionXref
} from '@v2/core/models/masterdata';
import {IDateRange, IDateRangeTimeDuration, IDuration} from '@v2/core/models/masterdata/IFilters.master-data';
import {IProcedure} from '@v2/core/models/masterdata/IProcedure.master-data';
import {IStockItem} from '@v2/core/models/masterdata/IStockItem.master-data';
import {IVendor} from '@v2/core/models/masterdata/IVendor.master-data';
import {PrescriptionMedicineViewModel} from '@v2/core/models/view/prescription-medicine.view.model';

export interface IFilterFormDataModel {
  orderSource: number;
  paymentType: string;
  paymentTypes: string[];
  paymentTypeCategory: number;
  billingStatus: string;
  claimPaymentTypeStatus: string;
  purchaseOrderStatus: string
  msOrgDepartment: number;
  department: string;
  departmentTypeToSearch: IDepartmentMinimal;
  serviceTypeSelection: IMasterData;
  department2: string;
  departments: string[];
  orderStatus: number;
  medicationType: string;
  ward: string;
  allWard: string;
  pharmacyDispenseStatus: string;
  paymentStatus: string;
  doctor: string;
  doctor2: string;
  doctors: string[];
  doctorStaffSelection: string;
  doctorsSelection: string;
  doctorsSelection2: string;
  doctorsMultiSelection: IDoctor[];
  surgeonSelection: string;
  roomType: string;
  admitStatus: string;
  allAdmitStatus: string;
  visitStatus: string;
  bedOccupancyStatusId: number;
  bedConditionStatus: number;
  severityStatus: number;
  orderSeverityStatus: number;
  building: string;
  floor: string;
  pharmacyGroupOrderType: string;
  patientOrderType: number;
  patientOrderTypes: number[];
  reportInvestigationType: number;
  radiologyInvestigationName: ICatalogElement;
  orderType: number;
  orderTypeMultiSelect: number[] | IMasterData[] | string[];
  orRoom: string;
  surgeon: string;
  surgery: IProcedure;
  cashier: string;
  adtRequestType: string,
  adtRequestStatus: string;
  patientVisitAdmissionXref: IPatientVisitAdmissionXref;
  patientVisitAdmissionXrefMultiSelect: IPatientVisitAdmissionXref[];

  inventoryType: string;
  inventoryTypes: number[];
  mainStock: string;
  usedFor: string;
  principalDiagnosis: IDiagnosisDetail;
  priorityStatus: number;
  visitType: number | string;
  msoFormType: number;
  formComponent: number;
  radiologyInvestigationType: string

  labInvestigation: ICatalogElementMinimal;
  nutritionMealCatalogElement: ICatalogElementMinimal;
  Vendor: IVendor
  order: string;
  catalogItemName: string;
  vendorId: string;
  vendorName: string;
  vendorStatus: number;
  orderDate: string;
  subStockType: string;
  PriorityStatus: number;
  requestFromSubStock: string;

  workingStatus: boolean

  billingSource: string
  aptStatus: string
  apptType: IMasterData | number
  purchaseOrderFulfilmentStatus: string;
  purchaseOrderProgressStatus: string;
  purchaseOrderPriority: string;
  purchaseRequestStatus: string;
  purchaseOrderProgressAndFulfillmentStatus: number;

  patientBillingCode: string;
  patientBillingCodes: string[];

  supportTicketType: number;
  supportTicketLevel: number;
  supportTicketResponseType: number;
  supportTicketStatus: number;

  isVerificationDone: boolean
  mealCategory: number

  hospital: string;

  hospitalBedCondition: number;
  purchaseOrderFulfilmentStatuses: number[];

  departmentRoomType: string;
  nurseStationOrReception: string;
  nurseStationOrReceptions: string[];
  nurseStationType: string;
  facilityResourceType: IMasterData;
  opdIpdNurseStation: string;

  supportTicketOwner: string;
  patientApptStatus: number
  gender: number

  icd10Diagnosis: string
  diagnosisMultiSearch: IDiagnosisDetail[];
  diagnosisMultiSearchSelected: IDiagnosisDetail[];
  companyType: number;
  hospitalType: number;
  serviceArea: number;
  departmentType: boolean;

  migrationDataType: number;
  migrationDataStatus: number;
  orSeverityStatus: number;
  specimenType: number;
  supplyCatalogElement: ICatalogElement
  serviceCatalogElement: ICatalogElement

  stockItem: IStockItem;
  stockCatalogElement: ICatalogElement;
  incomeType: IIncomeType;
  incomeTypes: IIncomeType[];

  seedType: number;

  medicalCatalogType: string;
  dispensingUnit: string;
  medicalCodeSystem: string;
  category: string;
  subCategory: string
  opdChargeType: string;
  chronicDiseaseType: number;

  orSurgery: IProcedure;

  activeInactiveStatus: boolean
  drugTypeFamily: number

  stockType: number;

  pharmacyItemName1: PrescriptionMedicineViewModel
  pharmacyItemName2: PrescriptionMedicineViewModel
  pharmacyCatalogElement: ICatalogElementMinimal

  managementCategory: number;

  supplyCategory: string
  supplySubCategory: string

  procurementCatalog: string;
  procurementCatalogs: string[];
  catalogCategory: string;
  catalogCategorys: string[];

  paymentMode: number
  patientStatusOnDischarge: number;
  patientStatusOnDischargeMultiSelect: number[];
  dischargeType: number;
  billingDepositRefundType: string | string[];

  transactionType: number;
  catalogType: number;
  catalogTypes: number[];
  pharmacyOutlet: string;

  dateRange1: IDateRange;
  timeRange1: string;
  date1: string;
  monthYearSelection1: string;
  time1: string;
  text1: string;

  dateRangeTimeDuration: IDateRangeTimeDuration;
  dateRangeTimeDuration2: IDateRangeTimeDuration;
  timeDuration: IDuration

  dateRange2: IDateRange;
  timeRange2: string;
  date2: string;
  time2: string;
  text2: string;

  dateRange3: IDateRange;
  timeRange3: string;
  date3: string;
  time3: string;
  text3: string;

  date4: string;
  text4: string;
  time4: string;

  date5: string;
  text5: string;

  text6: string;
  text7: string;
  text8: string;
}

export interface IFilterFormConfigModel {
  defaultValue: string | number | boolean | IDateRange | string[] | number[] | boolean[];
  mask: { pattern: string };
  displayOnly: string[];
  filterOptions: string[];
  showValuesInChip: boolean;
  searchOn: string;
  returnObject: boolean;
  catalogType: string;
  nsApiCallType: string;
  isNurseStation: boolean;
  fetchAllElements: boolean;
  loadActiveNurseStation: boolean;
  sourceTile: string;
  managementCategoryType: string;
  stockId: string;
  stockTypeId: number;
  stockType: number;
  dateRangeTimeDuration: { dateRangeLabel: string }
  isFilter: boolean;
  isForSubStock: boolean;
  showOnlyMedicalDept: boolean;
  setTextAsValue: boolean;
  maxDate: string;
  minDate: string;
  isReportFilter: boolean;
  patientOrderTypeConfig: Partial<{ includeDoctorFeeType: boolean, includeMedicalPackageType: boolean, loadCoreOrderTypes: boolean }>
  patientId: string;
  getValueBy: string;
  controlName: string;
  diagnosisConfig: Partial<{ diagnosisApiCallType: string, displayWith: 'code' | 'desc', searchOn: 'code' | 'desc' }>
  allowMultiSelect: boolean;
}

export interface IFilterDefinition {
  name: FilterEnum;
  label?: string;
  placeHolder?: string;
  config?: Partial<IFilterFormConfigModel>;
  multiSearchSelectedName?: FilterEnum;
}

export interface IIcd10DetailFilterModel {
  apptTypeCode: number;
  icdId: string;
  year: number;
  month: number;
}

export enum FilterEnum {
  Gender = 'gender',
  MealCategory = 'mealCategory',
  PatientApptStatus = 'patientApptStatus',
  OrderSource = 'orderSource', // OPD, IPD, Other, OR (pharmacy -> daily summary)
  PaymentType = 'paymentType', // INS, SS, CC, SP (common)
  PaymentTypes = 'paymentTypes', // INS, SS, CC, SP (common)
  PaymentTypeCategory = 'paymentTypeCategory',
  WorkingStatus = 'workingStatus', // Completed, Pending (common)
  BillingStatus = 'billingStatus', // Completed, Pending (common)
  ClaimPaymentTypeStatus = 'claimPaymentTypeStatus', // Pending, Finalized, Completed
  PurchaseOrderStatus = 'purchaseOrderStatus', // Completed, Pending (common)
  ActiveInactiveStatus = 'activeInactiveStatus', // Completed, Pending (common)
  DrugTypeFamily = 'drugTypeFamily', // Completed, Pending (common)
  Department = 'department', // (common),
  DepartmentTypeToSearch = 'departmentTypeToSearch',
  Department2 = 'department2', // (common), OPD/IPD Consultation and Transfer Listing
  MsOrgDepartment = 'msOrgDepartment', // (common),
  Departments = 'departments', // (common),
  OrderStatus = 'orderStatus', // Open, Completed, Prepared, etc. (Pharmacy),
  MedicationType = 'medicationType', // Prescription, Supply, Daily, SOS, Stat (common)
  Ward = 'ward', // (common)
  AllWards = 'allWard',
  PharmacyDispenseStatus = 'pharmacyDispenseStatus', // Pending, Dispensed (pharmacy -> dispense medicine),
  PaymentStatus = 'paymentStatus', // Paid, Unpaid
  Doctor = 'doctor',
  Doctor2 = 'doctor2', // OPD/IPD Consultation and Transfer Listing
  Doctors = 'doctors',
  DoctorSelection = 'doctorsSelection',
  ServiceTypeSelection = 'serviceTypeSelection',
  DoctorMultiSelection = 'doctorsMultiSelection',
  DoctorSelection2 = 'doctorsSelection2',
  DoctorStaffSelection = 'doctorStaffSelection',
  SurgeonSelection = 'surgeonSelection', // OR > OR request,Schedule,Dashboard
  RoomType = 'roomType', // VIP, Private, General, etc. (common),
  AdmitStatus = 'admitStatus', // Admit, Discharge Initiated
  AllAdmitStatus = 'allAdmitStatus',
  VisitStatus = 'visitStatus', // pending, completed
  severityStatus = 'severityStatus', // Admit, Discharge Initiated
  Building = 'building',
  Floor = 'floor',
  PharmacyGroupOrderType = 'pharmacyGroupOrderType', // Prescription, stat (pharmacy -> verify medicine),
  PatientOrderType = 'patientOrderType',
  PatientOrderTypes = 'patientOrderTypes',
  SeverityStatus = 'severityStatus',
  OrderSeverityStatus = 'orderSeverityStatus',
  OrSeverityStatus = 'orSeverityStatus',
  OrderType = 'orderType', // Patient Order Type (Radiology, Pathology, etc)
  OrderTypeMultiSelect = 'orderTypeMultiSelect',
  ReportInvestigationType = 'reportInvestigationType', // OR > Surgical Instruction > Report Tab
  OrRoom = 'orRoom', // OR > OR request,Schedule,Dashboard
  Surgeon = 'surgeon', // OR > OR request,Schedule,Dashboard
  Surgery = 'surgery', // OR > OR request,Schedule,Dashboard
  OrSurgery = 'orSurgery', // OR > OR request,Schedule,Dashboard
  Cashier = 'cashier', // Billing > Dashboard > Daily Summary Report
  ADTRequestType = 'adtRequestType',
  ADTRequestStatus = 'adtRequestStatus',
  BillingDepositRefundType = 'billingDepositRefundType',
  PrincipalDiagnosis = 'principalDiagnosis', // or dashboard
  ICD10Diagnosis = 'icd10Diagnosis',
  DiagnosisMultiSearch = 'diagnosisMultiSearch',
  DiagnosisMultiSearchSelected = 'diagnosisMultiSearchSelected',
  BillingSource = 'billingSource',
  AptStatus = 'aptStatus',
  ApptType = 'apptType',
  PatientVisitAdmissionXref = 'patientVisitAdmissionXref',
  PatientVisitAdmissionXrefMultiSelect = 'patientVisitAdmissionXrefMultiSelect',
  PriorityStatus = 'priorityStatus', // inventory -> receive inventory
  InventoryType = 'inventoryType',
  InventoryTypes = 'inventoryTypes',
  MainStockIn = 'MainStockIn',
  UsedForOut = 'UsedForOut',
  TransferRequestNo = 'TransferRequestNo', // procurement purchase request > Sub-Stock PR
  PurchaseOrder = 'PurchaseOrder',  // procurement purchase order backlog
  CatalogItemName = 'CatalogItemName', // procurement purchase request
  VendorId = 'VendorId', // procurement purchase order request
  Vendor = 'Vendor', // procurement purchase request, purchase order backlog
  OrderDate = 'OrderDate', // procurement purchase order backlog
  VendorStatus = 'VendorStatus',
  BedOccupancyStatus = 'bedOccupancyStatusId',
  BedConditionStatus = 'bedConditionStatus',

  subStockType = 'subStockType', // OPD_IPD_Other
  RequestFromSubStock = 'requestFromSubStock',
  PurchaseOrderFulfilmentStatus = 'purchaseOrderFulfilmentStatus',
  PurchaseOrderProgressStatus = 'purchaseOrderProgressStatus',
  PurchaseOrderProgressAndFulfillmentStatus = 'purchaseOrderProgressAndFulfillmentStatus',
  PurchaseOrderPriority = 'purchaseOrderPriority',
  PurchaseRequestStatus = 'purchaseRequestStatus',

  SpecimenType = 'specimenType',
  ChronicDiseaseType = 'chronicDiseaseType',
  PatientBillingCode = 'patientBillingCode',
  PatientBillingCodes = 'patientBillingCodes',

  SupportTicketType = 'supportTicketType',
  SupportTicketLevel = 'supportTicketLevel',
  SupportTicketResponseType = 'supportTicketResponseType',
  SupportTicketStatus = 'supportTicketStatus',
  SupportTicketOwner = 'supportTicketOwner',
  MSOFormType = 'msoFormType',
  FormComponent = 'formComponent',
  PurchaseOrderFulfilmentStatuses = 'purchaseOrderFulfilmentStatuses',

  Hospital = 'hospital',
  DepartmentRoomType = 'departmentRoomType',
  NurseStationOrReception = 'nurseStationOrReception',
  NurseStationOrReceptions = 'nurseStationOrReceptions',
  NurseStationType = 'nurseStationType',
  FacilityResourceType = 'facilityResourceType',
  OpdIpdNurseStation = 'opdIpdNurseStation',
  VisitType = 'visitType',
  RadiologyInvestigationType = 'radiologyInvestigationType',
  RadiologyInvestigationName = 'radiologyInvestigationName',
  CompanyType = 'companyType',
  HospitalType = 'hospitalType',
  DepartmentType = 'departmentType',
  ServiceArea = 'serviceArea',

  MigrationDataType = 'migrationDataType',
  MigrationDataStatus = 'migrationDataStatus',

  LabInvestigation = 'labInvestigation',
  NutritionMealCatalogElement = 'nutritionMealCatalogElement',

  PharmacyItemName1 = 'pharmacyItemName1',
  PharmacyItemName2 = 'pharmacyItemName2',
  PharmacyGroupName = 'pharmacyGroupName',
  PharmacyGroupId = 'pharmacyGroupId',
  PharmacyCatalogElement = 'pharmacyCatalogElement',

  ManagementCategory = 'managementCategory',

  // corp-management
  MedicalCatalogType = 'medicalCatalogType',
  DispensingUnit = 'dispensingUnit',
  MedicalCodeSystem = 'medicalCodeSystem',
  SupplyCatalogElement = 'supplyCatalogElement',
  ServiceCatalogElement = 'serviceCatalogElement',
  Category = 'category',
  SubCategory = 'subCategory',
  OPDChargeType = 'opdChargeType',

  SupplyCategory = 'supplyCategory',
  SupplySubCategory = 'supplySubCategory',

  ProcurementCatalog = 'procurementCatalog',
  ProcurementCatalogs = 'procurementCatalogs',
  CatalogCategory = 'catalogCategory',
  PatientStatusOnDischarge = 'patientStatusOnDischarge',
  PatientStatusOnDischargeMultiSelect = 'patientStatusOnDischargeMultiSelect',
  DischargeType = 'dischargeType',
  CatalogCategorys = 'catalogCategorys',

  PaymentMode = 'paymentMode',

  TransactionType = 'transactionType',
  StockItem = 'stockItem',
  StockCatalogElement = 'stockCatalogElement',
  IncomeType = 'incomeType',
  IncomeTypes = 'incomeTypes',
  SeedType = 'seedType',
  CatalogType = 'catalogType',
  CatalogTypes = 'catalogTypes',
  PharmacyOutlet = 'pharmacyOutlet',

  DateRangeTimeDuration = 'dateRangeTimeDuration',

  TimeDuration = 'timeDuration',
  DATE_RANGE1 = 'dateRange1',
  TIME_RANGE1 = 'timeRange1',
  DATE1 = 'date1',
  MONTH_YEAR_SELECTION1 = 'monthYearSelection1',
  TIME1 = 'time1',
  TEXT1 = 'text1',

  DATE_RANGE2 = 'dateRange2',
  TIME_RANGE2 = 'timeRange2',
  DATE2 = 'date2',
  TIME2 = 'time2',
  TEXT2 = 'text2',

  DATE_RANGE3 = 'dateRange3',
  TIME_RANGE3 = 'timeRange3',
  DATE3 = 'date3',
  TIME3 = 'time3',
  TEXT3 = 'text3',

  DATE4 = 'date4',
  TEXT4 = 'text4',
  TIME4 = 'time4',

  DATE5 = 'date5',
  TEXT5 = 'text5',

  TEXT6 = 'text6',
  TEXT7 = 'text7',
  TEXT8 = 'text8',
}

export const FILTER_DROPDOWN_ALL_VALUE = -9999;
export const DOCTOR_FEE_ORDER_TYPE_VALUE = -9998;
export const MEDICAL_PACKAGE_ORDER_TYPE_VALUE = -9997;
export const BLANK_SPACE_VALUE = -9996;
