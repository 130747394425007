import {Directive, Input} from '@angular/core';
import {ColumnMode, DatatableComponent} from '@swimlane/ngx-datatable';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'ngx-datatable[default]',
  exportAs: 'ngxDatatableDefault',
})
export class NgxDatatableDefaultDirective {

  // @Input() class = 'material shadow-sm';
  //
  // @HostBinding('class')
  // get classes(): string {
  //   return `ngx-datatable ${this.class}`;
  // }

  @Input() footerHeight = 50;
  @Input() headerHeight = 50;
  @Input() rowHeight = 80;
  @Input() scrollbarH = true;
  @Input() scrollbarV = false;
  @Input() externalPaging = true;
  @Input() externalSorting = true;

  constructor(private table: DatatableComponent) {
    this.table.columnMode = ColumnMode.force;
    this.table.footerHeight = 50;
    this.table.headerHeight = 50;
    this.table.rowHeight = 80;
    this.table.scrollbarH = true;
    this.table.scrollbarV = false;
    this.table.externalPaging = true;
    this.table.externalSorting = true;
  }

}
