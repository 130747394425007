import {ErrorHandler, Injectable} from '@angular/core';
import {GlobalErrorHandlerService} from '../../shared-v2/components/error-handler/global-error-handler.service';

@Injectable()
export class ChunkLoadingErrorHandlerService implements ErrorHandler {

  constructor(
    private globalErrorHandlerService: GlobalErrorHandlerService
  ) {
  }

  handleError(error: Error) {
    const chunkFailedMessage = /Loading chunk .+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      console.error(error);
      // this.globalErrorHandlerService.setChunkLoadingError();
    } else {
      console.error(error);
    }
  }
}
