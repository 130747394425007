import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexModule} from '@angular/flex-layout';
import {TranslocoModule} from '@ngneat/transloco';
import {NoDataComponent} from './no-data.component';


@NgModule({
  declarations: [NoDataComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    FlexModule
  ],
  exports: [NoDataComponent]
})
export class NoDataModule {
}
