import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatFormFieldAppearance} from '@angular/material/form-field/typings/form-field';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {InventoryTransactionTypeEnum} from '@v2/core/enums/inventory-transaction-type.enum';
import {getDefaultValueIdsForFilter, isArray} from '@v2/core/functions/functions';
import {IMasterData, IPageableData} from '@v2/core/models/masterdata';
import {ICommonFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {MasterDataHttpService} from '@v2/core/services/MasterData.http.service';
import {EMPTY, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {MasterDataEnum} from '../../../resources/enums/master-data.enum';

@Component({
  selector: 'app-master-data-dropdown',
  templateUrl: './master-data-dropdown.component.html',
  styleUrls: ['./master-data-dropdown.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'common'}]
})
export class MasterDataDropdownComponent implements OnInit, OnChanges {

  @Input() type: string;
  @Input() defaultOptionLang: 'eng' | 'thai';

  @Input() control: FormControl;
  @Input() controlLabel: string;

  @Input() requiredMessage: string;

  @Input() isFilter = false;
  @Input() isRequired = false;
  @Input() reloadData = false;
  @Input() allowMultiSelect = false;
  @Input() showValueInChip = false;

  @Input() setDefaultValueZeroIndex = false;

  @Input() hintText: string
  @Input() returnObject = false;
  @Input() placeHolderText: string;

  @Input() displayOnly: string[] = [];
  @Input() filterOptions: string[] = [];

  @Input() formFieldClass = '';
  @Input() showLabel = true;
  @Input() appearance: MatFormFieldAppearance;

  @Input() isForSubStock = false;
  @Input() pharmacySourceTile: 'verify' | 'prepare' | 'dispense';
  @Input() isEditMode = false;
  @Input() loadAllData = false;
  @Input() disabled = false;
  @Input() setTextAsValue = false;
  @Input() addBlankSpaceValueInList = false;

  @Input() defaultValue: string | string[];

  @Output() optionSelected = new EventEmitter<IMasterData>();
  @Output() responseReceived = new EventEmitter<Array<IMasterData>>();
  @Output() defaultValueIdsReceived = new EventEmitter<Array<number> | number | string | string[]>();

  filters: ICommonFilters = {
    filters: {
      includes: {
        contact: true
      }
    }
  }

  constructor(private masterDataHttpService: MasterDataHttpService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.type && !changes.type.firstChange) {
      this.control.reset();
      this.reloadData = !this.reloadData;
    }
  }

  apiCall = (filters: ICommonFilters): Observable<IPageableData<IMasterData>> => {
    if (this.type) {
      this.filters = {...filters};
      if (this.loadAllData) {
        this.filters.filters.page_size = 'all';
      }
      let request: Observable<IPageableData<any>>;
      if (this.type === MasterDataEnum.MEDICINE_INTAKE) {
        request = this.masterDataHttpService.getMedicineIntake();
      } else if (this.type === MasterDataEnum.TRANSACTION_TYPE) {
        request = this.masterDataHttpService.getTransactionTypes().pipe(
          map(response => {
            if (this.isFilter && this.isForSubStock) {
              const data = response.map(type => (type.label === InventoryTransactionTypeEnum.Procured ? {
                ...type,
                label: InventoryTransactionTypeEnum.Received
              } as IMasterData : type));
              return {items: data, totalPages: 1} as IPageableData<IMasterData>;
            }
            return {
              items: response,
              totalPages: 1
            } as IPageableData<IMasterData>;
          })
        );
      } else if (this.type === MasterDataEnum.CLAIM_PAYMENT_TYPES) {
        request = this.masterDataHttpService.getClaimPaymentTypes();
      } else if (this.type === MasterDataEnum.PURCHASE_REQUEST_STATUS) {
        request = this.masterDataHttpService.getPurchaseRequestStatus();
      } else if (this.type === MasterDataEnum.PHARMACY_ORDER_STATUS) {
        request = this.masterDataHttpService.getPharmacyStatus(this.pharmacySourceTile);
      } else if (this.type === MasterDataEnum.ALERT_NOTE_SEVERITY_STATUS) {
        request = this.masterDataHttpService.getNoteAlertSeverityStatus();
      } else if (this.type === MasterDataEnum.ADT_REQUEST_STATUS) {
        request = this.masterDataHttpService.getADTRequestStatusAsMasterData();
      } else if (this.type === MasterDataEnum.ADT_REQUEST_TYPE) {
        request = this.masterDataHttpService.getADTRequestTypesAsMasterData();
      } else if (this.type === MasterDataEnum.FACILITY_RESOURCE_TYPE) {
        request = this.masterDataHttpService.getFacilityResourceTypes();
      } else if (this.type === MasterDataEnum.SERVICE_TYPE) {
        request = this.masterDataHttpService.getServiceTypes();
      }
      else {
        request = this.masterDataHttpService.getMasterDataWithPagination(this.type, this.filters, this.addBlankSpaceValueInList);
      }

      return request.pipe(
        tap(response => this.responseReceived.emit(response.items)),
        map(response => {
          const {items, ...pagination} = response
          let data = [];
          if (isArray(this.displayOnly) && this.displayOnly.length) {
            data = items.filter(item => this.displayOnly.includes(item.label))
          } else {
            data = isArray(this.filterOptions) ? items.filter(item => !this.filterOptions.includes(item.label)) : items;
          }
          return {items: data, ...pagination}
        })
      );
    } else {
      return EMPTY
    }
  };

  getMasterDataKey = (item: IMasterData) => item.key;

  getMasterDataLabel = (item: IMasterData) => item.label;

  getMasterDataThaiLabel = (item: IMasterData) => item.thaiLabel;

  onOptionSelected(selectedMasterData: unknown) {
    this.optionSelected.emit(selectedMasterData as IMasterData);
  }

  onResponseReceived(data: Array<unknown>) {
    if (this.defaultValue) {
      const defaultValueIds = getDefaultValueIdsForFilter(this.defaultValue, (value) => {
        const matchedPaymentType = (data as Array<IMasterData>).find(type => type.label === value);
        if (matchedPaymentType) {
          return this.setTextAsValue ? matchedPaymentType.label : matchedPaymentType.key;
        }
        return null;
      });
      this.defaultValueIdsReceived.emit(defaultValueIds as number | number[]);
    }
  }
}
