import {AppSecurityRoles, SubMenuModel} from '../../../../core/models/menu/sub-menu.model';
import {ADMISSION_PD_PR_ROUTES} from '../url-routs';

export const NS_ADMISSION_PD: Array<SubMenuModel> = [
  {
    id: 18,
    title: 'OPD Summary',
    url: ADMISSION_PD_PR_ROUTES.opdSummary,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 1,
    title: 'Admission Note',
    url: ADMISSION_PD_PR_ROUTES.admissionNote,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 2,
    title: 'Vitals',
    url: ADMISSION_PD_PR_ROUTES.vitals,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 3,
    title: 'Nurse Note',
    url: ADMISSION_PD_PR_ROUTES.nurseNote,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 4,
    title: 'Progress Note',
    url: ADMISSION_PD_PR_ROUTES.progressNote,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 5,
    title: 'Patient Order',
    url: ADMISSION_PD_PR_ROUTES.patientOrder,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 6,
    title: 'Diagnosis',
    url: ADMISSION_PD_PR_ROUTES.diagnosis,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 7,
    title: 'Treatment',
    url: ADMISSION_PD_PR_ROUTES.treatment,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 8,
    title: 'Next\nAppointment',
    url: ADMISSION_PD_PR_ROUTES.nextAppointment,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 9,
    title: 'Service Billing',
    url: ADMISSION_PD_PR_ROUTES.serviceBilling,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 10,
    title: 'Prescription On\nDischarge',
    url: ADMISSION_PD_PR_ROUTES.prescriptionOnDischarge,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 11,
    title: 'Discharge',
    url: ADMISSION_PD_PR_ROUTES.discharge,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 12,
    title: 'Reports',
    url: ADMISSION_PD_PR_ROUTES.reports,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 13,
    title: 'OR Request/\nSurgical Instruction',
    url: ADMISSION_PD_PR_ROUTES.orSurgicalIns,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 14,
    title: 'Insurance Form',
    url: ADMISSION_PD_PR_ROUTES.insuranceForm,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 15,
    title: 'Medical\nCertificate',
    url: ADMISSION_PD_PR_ROUTES.medicalCertificate,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 16,
    title: 'Patient\nManagement',
    url: ADMISSION_PD_PR_ROUTES.patientManagement,
    allowedRoles: [AppSecurityRoles.ALL]
  },
  {
    id: 17,
    title: 'Medical Records',
    url: ADMISSION_PD_PR_ROUTES.medicalRecords,
    allowedRoles: [AppSecurityRoles.ALL]
  },
]
