import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {SwUpdate} from '@angular/service-worker';
import {TranslocoService} from '@ngneat/transloco';
import {UtilityService} from '@v2/core/services/utility.service';
import {AnimationOptions} from 'ngx-lottie';

@Component({
  selector: 'app-clear-browser-cache-modal',
  templateUrl: './clear-browser-cache-modal.component.html',
  styleUrls: ['./clear-browser-cache-modal.component.scss'],
})
export class ClearBrowserCacheModalComponent implements OnInit {
  showLoader = false;
  options: AnimationOptions = {
    path: '/assets/images/lottie-images/building-a-website.json',
    loop: true,
    autoplay: true,
  };
  styles: Partial<CSSStyleDeclaration> = {
    maxWidth: '700px',
    margin: '0 auto',
  };
  player = {
    width: '800px',
    height: '800px',
  };

  constructor(
    private swUpdate: SwUpdate,
    private matDialogRef: MatDialogRef<ClearBrowserCacheModalComponent>,
    private utilityService: UtilityService,
    private translocoService: TranslocoService,
    @Inject(MAT_DIALOG_DATA) public data: { height: number; width: number }
  ) {
    if (this.data) {
      // to set proper height and width for the player
      this.player.height = this.data.width ? `${(this.data.width / 2 > 500) ? this.data.width : 500}px` : '800px'; // we are using width because we want to render square player
      this.player.width = this.data.width ? `${this.data.width / 2}px` : '800px';
    }
  }

  ngOnInit() {
    // setting the top offset for the modal to match the design
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.position = {top: `80px`}; // 80px that we removed from the modal height
    this.matDialogRef.updatePosition(matDialogConfig.position)
  }

  onSubmit() {
    if (this.swUpdate.isEnabled) {
      this.showLoader = true;
      this.swUpdate.activateUpdate().then(() => {
        this.showLoader = false;
        this.matDialogRef.close();
      }).catch(() => {
        this.showLoader = false;
        this.utilityService.showSnackBarCloseLabel(this.translocoService.translate('Error while updating to latest version. Please try again', {}, 'common'));
      });
    } else {
      this.matDialogRef.close();
    }
  }
}
