import {IPrimaryDoctorCheckerHttpModel} from '@v2/core/models/masterdata';

export function GetDoctorRole(dischargeOrTransferInfo: IPrimaryDoctorCheckerHttpModel): AppSecurityRoles {
  if (!dischargeOrTransferInfo) {
    return AppSecurityRoles.D_SECONDARY;
  }
  if (dischargeOrTransferInfo.isConsultationAppt) {
    return AppSecurityRoles.D_CONSULTANT;
  } else if (dischargeOrTransferInfo.isPrimaryDoctor) {
    return AppSecurityRoles.D_PRIMARY;
  } else {
    return AppSecurityRoles.D_SECONDARY;
  }
}

export function GetAccessibleRoutes(routes: SubMenuModel[], role: AppSecurityRoles) {
  if (role !== AppSecurityRoles.ALL) {
    return routes.filter(route => route.allowedRoles.includes(role));
  }
  return routes;
}

// D: Doctor
export enum AppSecurityRoles {
  D_PRIMARY = 'D_PRIMARY',
  D_SECONDARY = 'D_SECONDARY',
  D_CONSULTANT = 'D_CONSULTANT',
  ALL = 'ALL',
}

export interface SubMenuModel {
  id: number
  title: string
  url: string
  allowedRoles: Array<AppSecurityRoles>
}
