import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TRANSLOCO_SCOPE, TranslocoModule} from '@ngneat/transloco';
import {ImagePreviewModalModule} from '../../../v2/shared-v2/components/image-preview-modal/image-preview-modal.module';
import {DocListComponent} from './doc-list/doc-list.component';

@NgModule({
  declarations: [DocListComponent],
  imports: [
    CommonModule,
    TranslocoModule,
    ImagePreviewModalModule
  ],
  exports: [DocListComponent],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'common'}]
})
export class DocListModule {
}
