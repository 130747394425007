import {Directive, ElementRef, HostListener, OnInit, Optional} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';

@Directive({
  selector: 'input[trim],textarea[trim]'
})
export class TrimInputLeadingSpaceDirective implements OnInit {

  @HostListener('input', [
    '$event.target',
    '$event.target.value',
  ])
  onInput (el: any, value: any): void {
    if (value.trimLeft() !== value) {

      let {start, end} = TrimInputLeadingSpaceDirective.getCaret(el);
      if (value[0] === ' ' && start === 1 && end === 1) {
        start = 0;
        end = 0;
      }
      TrimInputLeadingSpaceDirective.trimValue(el, value);
      TrimInputLeadingSpaceDirective.setCaret(el, start, end);
    }
  }

  private _valueAccessor: ControlValueAccessor;
  private _writeValue: (value) => void;

  private static getCaret (el) {
    return {
      start: el.selectionStart,
      end: el.selectionEnd,
    };
  }

  private static setCaret (el, start, end) {
    el.selectionStart = start;
    el.selectionEnd = end;
    el.focus();
  }

  private static dispatchEvent (el, eventType) {
    const event = document.createEvent('Event');
    event.initEvent(eventType, false, false);
    el.dispatchEvent(event);
  }

  private static trimValue (el, value) {
    el.value = value.trimStart();
    TrimInputLeadingSpaceDirective.dispatchEvent(el, 'input');
  }

  constructor(
    private elementRef: ElementRef,
    @Optional() private ngControl: NgControl,
  ) { }

  ngOnInit(): void {
    if (!this.ngControl) {
      return;
    }

    this._valueAccessor = this.ngControl.valueAccessor;
    this._writeValue = this._valueAccessor.writeValue;

    this._valueAccessor.writeValue = (value) => {
      // const _value = value.trim();
      let _value
      if (value) {
        _value = value.trim();
      }

      if (this._writeValue) {
        this._writeValue.call(this._valueAccessor, _value);
      }

      if (value !== _value) {
        if (this._valueAccessor['onChange']) {
          this._valueAccessor['onChange'](_value);
        }

        if (this._valueAccessor['onTouched']) {
          this._valueAccessor['onTouched']();
        }
      }
    }
  }

}
