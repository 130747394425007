import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';

import { AuthService } from '../../core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'authentication'}]
})
export class ForgotPasswordComponent implements OnInit {

  errorMessage = '';
  isSubmitting = false;
  loginForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.email
      ])]
    });
  }

  get email() {
    return this.loginForm.get('email');
  }

  onSubmit() {
    console.warn(this.loginForm.value);

    /* this.isSubmitting = true;
    this.errorMessage = '';

    const credentials = this.loginForm.value;
    this.authService
      .forgotPassword(credentials)
      .subscribe(
        data => {
          this.router.navigateByUrl('/login').then(nav => {
						return this.snackBar.open("We have e-mailed your password reset link.", "Close");
					});
        },
        err => {
          this.errorMessage = 'User not found';
          this.isSubmitting = false;
        }
      ); */
  }

}
