import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {take} from 'rxjs/internal/operators/take';
import {AdmitTransferRequestViewModel} from '../../../../adt-management/resources/models/view/admit-transfer-request/admit-transfer-request.view.model';
import {PatientInfoModalViewModel} from '../resources/models/view/patient-info-modal.view.model';
import {PatientInfoModalHttpService} from '../resources/services/http/patient-info-modal-http.service';

@Component({
  selector: 'app-patient-info-modal',
  templateUrl: './patient-info-modal.component.html',
  styleUrls: ['./patient-info-modal.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'common'
    }
  ]
})
export class PatientInfoModalComponent implements OnInit, AfterViewInit {

  isPatientInfoLoading = false;
  info: PatientInfoModalViewModel;
  medicalRecordInfo: AdmitTransferRequestViewModel;

  swiperConfig: SwiperConfigInterface = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<PatientInfoModalComponent>,
    private patientInfoModalHttpService: PatientInfoModalHttpService,
  ) {
  }

  ngOnInit() {
    this.getPatientBasicInfoById();
  }

  ngAfterViewInit() {
    this.swiperConfig = {navigation: true};
  }

  getPatientBasicInfoById() {
    this.isPatientInfoLoading = true;
    this.patientInfoModalHttpService.getPatientModelInfo(this.data.patientId, this.data.showRegistrationNote).pipe(
      take(1)
    ).subscribe(patientInfo => {
      this.isPatientInfoLoading = false;
      patientInfo.appointmentDateTime = this.data.appointmentDateTime;
      this.info = patientInfo
      this.medicalRecordInfo = AdmitTransferRequestViewModel.mapFromPatientInfoViewModel(patientInfo);
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
