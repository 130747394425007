import {Component, Input, OnInit} from '@angular/core';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {IPatient} from '@v2/core/models/masterdata';
import {NoteAlertService} from './note-alert.service';

@Component({
  selector: 'app-note-alert',
  templateUrl: './note-alert.component.html',
  styleUrls: ['./note-alert.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'common'}]
})
export class NoteAlertComponent implements OnInit {

  @Input() patient: IPatient;

  @Input() notificationCount: number;
  @Input() appointmentId: string;
  @Input() currentOAId: string;
  @Input() useV2TranslationPipe: boolean;
  @Input() hasWriteAccess = true;
  @Input() allowVisitRestriction = true;

  constructor(private noteAlertService: NoteAlertService) {
  }

  ngOnInit() {
  }

  showNoteAlertListModal() {
    this.noteAlertService.toggleNoteAlertSidebarModal.next({
      showAlertNoteSidebar: true,
      patient: this.patient,
      appointmentId: this.appointmentId,
      currentOAId: this.currentOAId,
      useV2TranslationPipe: this.useV2TranslationPipe,
      hasWriteAccess: this.hasWriteAccess,
      allowVisitRestriction: this.allowVisitRestriction
    })
  }

}
