import {IMedicineFrequency} from '@v2/core/models/masterdata';

export class FrequencyDataViewModel {
  inMorning: number;
  inAfternoon: number;
  inEvening: number;
  inNight: number;

  static mapFormMapApi(item: IMedicineFrequency): FrequencyDataViewModel {
    const viewModel = new FrequencyDataViewModel();
    viewModel.inMorning = item.inMorning;
    viewModel.inAfternoon = item.inAfternoon;
    viewModel.inEvening = item.inEvening;
    viewModel.inNight = item.inNight;
    return viewModel;
  }

  static getDefaultData(defaultValue = 0): FrequencyDataViewModel {
    const viewModel = new FrequencyDataViewModel();
    viewModel.inMorning = defaultValue;
    viewModel.inAfternoon = defaultValue;
    viewModel.inEvening = defaultValue;
    viewModel.inNight = defaultValue;
    return viewModel;
  }
}
