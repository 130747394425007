import {getCurrentDateTime, getDifferenceBetweenTwoDates, getName} from '@v2/core/functions/functions';

import {PatientNameTranslation, translatePatientFullName, translatePatientName} from '@v2/core/functions/patient-name-translation';
import {IAddress, IMasterData, IPatient, IPatientIdentification} from '@v2/core/models/masterdata';

class NationalIdEvidenceViewModel {
  url: string
  attachmentName: string
}

export class PatientInfoModalViewModel {
  id: string
  xref: string
  fullName: string
  firstLastName: string
  patientObj: PatientNameTranslation
  patient: IPatient;
  profilePicture: string
  dob: string
  age: number;
  gender: IMasterData
  bloodGroup: string
  contactNo: string
  religion: IMasterData
  occupation: string
  email: string
  nationality: string
  paymentType: string;
  registrationTime: string;
  registrationNote: string;
  registrationNoteUpdatedBy: string;
  registrationNoteUpdatedAt: string;

  nationalId: IPatientIdentification
  nationalIdEvidences: NationalIdEvidenceViewModel[] = []

  appointmentDateTime?: string;

  isBuddhistDOB: boolean;
  buddhistDOB: string;

  address: IAddress[];

  identity: IPatientIdentification[];
  otherEvidenceId: IPatientIdentification;

  static mapFromHttpModel(httpModel: IPatient): PatientInfoModalViewModel {
    const view = new PatientInfoModalViewModel()

    view.patientObj = httpModel;
    view.patient = httpModel;
    view.id = httpModel.id
    view.xref = httpModel.xref
    view.firstLastName = translatePatientName(httpModel)
    view.fullName = translatePatientFullName(httpModel)
    view.profilePicture = httpModel.profilePic
    view.dob = httpModel.dob;
    view.isBuddhistDOB = httpModel.isBuddhistDOB;
    view.buddhistDOB = httpModel.buddhistDOB;
    view.age = httpModel.dob ? getDifferenceBetweenTwoDates(getCurrentDateTime(), httpModel.dob, 'years') : null;
    view.gender = httpModel.gender;
    view.bloodGroup = httpModel.bloodGroup
    view.contactNo = httpModel.fullPhoneNumber;
    view.religion = httpModel.religion;
    view.occupation = httpModel.occupation
    view.email = httpModel.email;
    view.nationality = httpModel.nationality && httpModel.nationality.label
    view.registrationTime = httpModel.created_at;
    if (httpModel.registrationNote) {
      view.registrationNote = httpModel.registrationNote.note;
      view.registrationNoteUpdatedAt = httpModel.registrationNote.changedAt;
      if (httpModel.registrationNote.changedBy) {
        view.registrationNoteUpdatedBy = getName(httpModel.registrationNote.changedBy.firstName, httpModel.registrationNote.changedBy.lastName);
      }
    }
    view.address = httpModel.address || httpModel.contact.address;

    let paymentTypes = 'Self Pay';
    if (httpModel.paymentPlans && httpModel.paymentPlans.length > 0) {
      const types = httpModel.paymentPlans.map(type => (type.paymentType ? type.paymentType.name : '')).filter(type => !!type);
      if (types.length > 0) {
        paymentTypes = types.join(' + ');
      }
    }
    view.paymentType = paymentTypes;

    const nationalIdEvidence = httpModel.identity.find(value => value.evidence && value.evidence.label === 'National ID')
    const otherIdentification = httpModel.identity.find(value => value.evidence && value.evidence.label === 'Identification')

    if (otherIdentification) {
      view.otherEvidenceId = otherIdentification;
    }

    if (nationalIdEvidence) {
      view.nationalId = nationalIdEvidence

      if (Array.isArray(nationalIdEvidence.evidenceAttach) && nationalIdEvidence.evidenceAttach && nationalIdEvidence.evidenceAttach.length) {
        nationalIdEvidence.evidenceAttach.map(
          value => {
            const evidence = new NationalIdEvidenceViewModel()
            evidence.attachmentName = value.fileName
            evidence.url = value.filePath

            view.nationalIdEvidences.push(evidence)
          }
        )
      }
    }
    return view
  }
}
