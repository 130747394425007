import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {getCurrentDateTime, getDifferenceBetweenTwoDates, getTimeDurationFromNow} from '@v2/core/functions/functions';

@Pipe({
  name: 'durationFromNow'
})
export class DurationFromNowPipe extends DatePipe implements PipeTransform {

  transform(value: string, dateFormat = 'dd-MM-yyyy hh:mm aa'): string {
    const differenceInHours = getDifferenceBetweenTwoDates(value, getCurrentDateTime(), 'hours');
    if (differenceInHours > 24) {
      return super.transform(value, dateFormat);
    }
    return getTimeDurationFromNow(value);
  }

}
