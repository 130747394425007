import {getName} from '@v2/core/functions/functions';
import {PatientNameTranslation, translatePatientName} from '@v2/core/functions/patient-name-translation';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {IPatientManagementRequest, IPatientManagementRequestStatus} from '@v2/core/models/masterdata/IPatientManagementRequest';
import * as moment from 'moment';
import {getPatientManagementRequestTypeUILabel} from '../../../../../core/functions/patient-management-helper.function';
import {PatientManagementRequestTypeEnum} from '../../../enums/patient-management.enum';

export class PatientManagementRequestViewModel {
  id: string;
  profilePic: string;
  patientName: string;
  patientId: string;
  patientXref: string;
  patientDOB: string;
  ageInYears: number;
  appointmentId: string;
  appointmentXref: string;
  admissionXref: string;
  requestDateTime: string;
  requestedDate: string;
  requestedByDepartmentName: string;
  requestedByDoctorName: string;
  toDepartmentName: string;
  toDoctorName: string;
  requestType: string;
  requestStatus: string;
  newAppointmentId: string;
  eventId: string;
  patientObj: PatientNameTranslation;

  static mapFromApiModel(requestData: IPatientManagementRequest): PatientManagementRequestViewModel {
    const viewData = new PatientManagementRequestViewModel();
    if (requestData.patient) {
      viewData.patientObj = requestData.patient;
      viewData.profilePic = requestData.patient.profilePic;
      viewData.patientDOB = requestData.patient.dob;
      viewData.patientName = translatePatientName(requestData.patient);
      viewData.patientId = requestData.patient.id;
      viewData.patientXref = requestData.patient.xref;
      viewData.ageInYears = moment().diff(requestData.patient.dob, 'years');
    }
    if (requestData.originAppointment) {
      viewData.appointmentId = requestData.originAppointment.id;
      viewData.appointmentXref = requestData.originAppointment.xref;
      viewData.requestedByDepartmentName = requestData.originAppointment.department ? requestData.originAppointment.department.name : '';
      viewData.requestedByDoctorName = requestData.originAppointment.doctor ? getName(requestData.originAppointment.doctor.firstName, requestData.originAppointment.doctor.lastName) : '';
    }
    viewData.admissionXref = requestData.requestIdXref;
    viewData.requestDateTime = requestData.requestDateTime;
    viewData.requestType = requestData.requestType ? requestData.requestType.label : '';
    viewData.requestStatus = requestData.requestStatus && requestData.requestStatus.label;
    if (requestData.requestType.label === PatientManagementRequestTypeEnum.JOINT_ADMISSION || requestData.requestType.label === PatientManagementRequestTypeEnum.JOINT_ADMISSION_UI) {
      viewData.id = requestData.jointAdmission.jointDoctorId.toString();
      viewData.requestedDate = '';
      viewData.newAppointmentId = requestData.originAppointment ? requestData.originAppointment.id : '';
      if (requestData.jointAdmission) {
        viewData.toDepartmentName = requestData.jointAdmission.department ? requestData.jointAdmission.department.name : '';
        viewData.toDoctorName = requestData.jointAdmission.doctor ? getName(requestData.jointAdmission.doctor.firstName, requestData.jointAdmission.doctor.lastName) : '';
        viewData.eventId = requestData.jointAdmission.eventId_uuid;
        viewData.requestStatus = requestData.jointAdmission.doctorRequestStatus && requestData.jointAdmission.doctorRequestStatus.label;
      }
    } else if (requestData.requestType.label === PatientManagementRequestTypeEnum.CASE_TRANSFER || requestData.requestType.label === PatientManagementRequestTypeEnum.CONSULTATION) {
      viewData.id = requestData.requestId;
      if (requestData.consultationTransferHistory) {
        const info = requestData.consultationTransferHistory;
        viewData.requestedDate = info.requestForDateTime;
        viewData.requestedByDepartmentName = info.fromDepartment ? info.fromDepartment.name : '';
        viewData.requestedByDoctorName = info.fromDoctor ? getName(info.fromDoctor.firstName, info.fromDoctor.lastName) : '';
        viewData.toDepartmentName = info.toDepartment ? info.toDepartment.name : '';
        viewData.toDoctorName = info.toDoctor ? getName(info.toDoctor.firstName, info.toDoctor.lastName) : '';
        viewData.newAppointmentId = requestData.originAppointment ? requestData.originAppointment.id : '';
        viewData.eventId = info.patientEventId_uuid;
        viewData.requestStatus = info.doctorRequestStatus && info.doctorRequestStatus.label;
      }
    } else if (requestData.requestType.label === PatientManagementRequestTypeEnum.BED_TRANSFER) {
      viewData.id = requestData.requestId;
      viewData.toDepartmentName = '';
      viewData.toDoctorName = '';
      viewData.newAppointmentId = viewData.appointmentId;
      if (requestData.bedTransferData) {
        viewData.requestedDate = requestData.bedTransferData.requestedDate;
        viewData.requestType = requestData.bedTransferData.bedRequestType ? requestData.bedTransferData.bedRequestType.label : viewData.requestType;
        viewData.requestStatus = requestData.bedTransferData.bedTransferReqStatus ? requestData.bedTransferData.bedTransferReqStatus.label : '';
      }
    }
    viewData.requestType = getPatientManagementRequestTypeUILabel(viewData.requestType);
    return viewData;
  }
}

export class PatientManagementRequestStatusViewModel extends ConcurrencyRequest {
  requestStatus: string;

  static mapFromHttpModel(httpModel: IPatientManagementRequestStatus): PatientManagementRequestStatusViewModel {
    const viewModel = new PatientManagementRequestStatusViewModel();
    viewModel.requestStatus = httpModel.doctorRequestStatus ? httpModel.doctorRequestStatus.label : '';
    viewModel.mapConcurrencyRequest(httpModel.attachedEvent);
    return viewModel;
  }
}


