export const FORBIDDEN_ROUTE = 'forbidden';
export const FORBIDDEN_URL = `/${FORBIDDEN_ROUTE}`;

export const MAIN_MENU_ROUTES = {
  registration: 'registration',
  rapidRegistration: 'rapid-registration',
  paymentType: 'payment-type',
  patientVisit: 'patient-visit',
  adtManagement: 'adt-management',
  bedManagement: 'bed-management',
  opd: 'opd',
  ipd: 'ipd',
  nurse: 'nurse',
  doctor: 'doctor',
  nurseOpd: 'opd/nurse',
  nurseIpd: 'ipd/nurse',
  doctorOpd: 'opd/doctor',
  doctorIpd: 'ipd/doctor',
  wardManagement: 'ward-management',
  radiology: 'radiology',
  lab: 'pathology',
  pharmacy: 'pharmacy',
  nutritionMeal: 'nutrition-meal',
  operationRoom: 'operation-room',
  insuranceSSCC: 'insurance-ss-cc',
  billing: 'billing',
  medicalCoding: 'medical-coding',
  corpManagement: 'corp-management',
  inventory: 'stock-management',
  procurement: 'procurement',
  subStockManagement: 'sub-stock-management',
  medicalParty: 'medical-party',
  nonMedicalParty: 'non-medical-party',
  facilityResource: 'medical-resource',
  corpSetup: 'corp-setup',
  userManagement: 'user-management',
  aimSetup: 'aim-setup',
  supportCenter: 'support-center',
  reports: 'reports'
};

export const MAIN_MENUS_KEYS = {
  registration: 'Registration',
  rapidRegistration: 'Rapid Registration',
  paymentType: 'Payment Type',
  patientVisit: 'Patient Visit',
  adtManagement: 'ADT Management',
  bedManagement: 'Bed Management',
  nurseOpd: 'Nurse Station-(OPD)',
  nurseIpd: 'Nurse Station-(IPD)',
  doctorOpd: 'Doctor-(OPD)',
  doctorIpd: 'Doctor-(IPD)',
  wardManagement: 'Ward Management',
  radiology: 'Radiology',
  lab: 'Lab',
  pharmacy: 'Pharmacy',
  nutritionMeal: 'Nutrition & Meal',
  operationRoom: 'Operation Room(OR)',
  insuranceSSCC: 'Insurance/SS/CC',
  billing: 'Billing',
  medicalCoding: 'Medical Coding',
  corpManagement: 'Corp. Management',
  inventory: 'Inventory',
  procurement: 'Procurement',
  subStockManagement: 'Sub Stock Management',
  medicalParty: 'Medical Party',
  nonMedicalParty: 'Non-Medical Party',
  facilityResource: 'Facility Resources',
  corpSetup: 'Corp Setup',
  userManagement: 'User Management',
  aimSetup: 'AIM Setup',
  supportCenter: 'Support Center',
  reports: 'Reports'
};

export const SUB_MENU_OPTIONS = {
  PENDING_ORDERS: 'Pending Orders',
  DASHBOARD: 'Dashboard',
  PATIENT_LIST: 'Patient List',
  PATIENT_VERIFICATION: 'Patient  Verification',
  TRIAGE: 'Triage',
  PAYMENT_PLAN: 'Payment  Plan',
  VALIDATE_PAYMENT: 'Validate  Payment',
  VALIDATE_COVERAGE: 'Validate  Coverage',
  PAYMENT_PRIORITY: 'Payment  Priority',
  PATIENT_APPOINTMENT: 'Patient  Appointment',
  ADT_REQUEST: 'Admit Transfer Request',
  BED_OCCUPANCY: 'Bed Occupancy',
  DISCHARGE_PATIENT: 'Discharge Patient',
  HOSPITAL_BED: 'Hospital Bed',
  PATIENT_ROOM: 'Patient Room',
  BED_PIPELINE: 'Bed Pipeline',
  PATIENT_PROFILE: 'Patient Profile',
  PATIENT_DATA: 'Patient Data',
  PATIENT_REVIEW: 'Patient Review',
  CONSULTATION_TRANSFER: 'Consultation/Transfer',
  PATIENT_MANAGEMENT: 'Patient Management',
  HOSPITAL_WARD: 'Hospital Ward',
  NURSING_STATION: 'Nursing Station',
  PATIENT_BACKLOG: 'Patient Backlog',
  PATIENT_INVESTIGATION: 'Patient Investigation',
  RESULT_UPLOAD: 'Result Upload',
  REPORT_ENTRY: 'Report Entry',
  RADIOLOGY_CATALOG: 'Radiology Catalog',
  SAMPLE_COLLECTION: 'Sample Collection',
  LAB_CATALOG: 'Lab Catalog',
  VERIFY_MEDICINE: 'Verify Medicine',
  PREPARE_MEDICINE: 'Prepare Medicine',
  DISPENSE_MEDICINE: 'Dispense Medicine',
  RETURN_MEDICINE: 'Return Medicine',
  PHARMA_CATALOG: 'Pharma Catalog',
  DRUG_INTERACTION_ALLERGY: 'Drug Interaction / Allergy',
  PHARMACY_OUTLETS: 'Pharmacy Outlets',
  PREPARE_MEAL: 'Prepare & Serve Meal',
  MEAL_CATALOG: 'Meal Catalog',
  OR_REQUEST: 'OR Request',
  OR_SCHEDULE: 'OR Schedule',
  SURGICAL_INSTRUCTION: 'Surgical Instruction',
  OR_LIST: 'OR List',
  COMPANY_CONTRACT_MANAGEMENT: 'Company Contract Management',
  INSURANCE_PLAN_MANAGEMENT: 'Insurance Plan Management',
  SELF_PAY_CODE_MANAGEMENT: 'Self Pay Code Management',
  BILLING_PIPELINE: 'Billing Pipeline',
  VISIT_BILLING: 'Visit Billing',
  ADMISSION_BILLING: 'Admission Billing',
  //for wallet OA
  WALLET_MANAGEMENT: 'Wallet Management',
  SUBMIT_CLAIM: 'Submit Claim',
  ACCEPT_PAYMENT: 'Accept Payment',
  BILL_MANAGEMENT: 'Bill Management',
  ADD_CODES: 'Add Codes',
  CATALOGS: 'Catalogs',
  INCOME_TYPE: 'Income Types',
  PATIENT_BILLING_CODE: 'Patient Billing Code',
  MEDICAL_CODE_LIST: 'Medical Code List',
  DOCTOR_FEE_SETUP: 'Doctor Fee Set-up',
  PAYMENT_TYPE_MANAGEMENT: 'Payment Type Management',
  FORM_MANAGEMENT: 'Form Management',
  INTERNAL_OPERATION_CATALOG: 'Internal Operation Catalog',
  SERVICE_CATALOG: 'Service Catalog',
  SUPPLIES_CATALOG: 'Supplies Catalog',
  MEDICAL_PACKAGE: 'Medical Package',
  OPD_CHARGES_SETTINGS: 'OPD Charges Setting',
  MAIN_STOCK_INVENTORY: 'Main Stock (Inventory)',
  SUB_STOCK_OVERVIEW: 'Sub Stock Overview',
  TRANSFER_REQUEST: 'Transfer Request',
  PURCHASE_REQUEST_BACKLOG: 'Purchase Request Backlog',
  OUTGOING_TRANSFER_REQUEST: 'Outgoing Transfer Request',
  INCOMING_TRANSFER_REQUEST: 'Incoming Transfer Request',
  ADD_PURCHASE_REQUEST: 'Add Purchase Request',
  RECEIVED_INVENTORY: 'Received Inventory',
  PURCHASE_REQUEST: 'Purchase Request',
  PURCHASE_ORDER_BACKLOG: 'Purchase Order Backlog',
  VENDOR_MANAGEMENT: 'Vendor Management',
  PURCHASE_ORDER_MANAGEMENT: 'Purchase Order Management',
  SUB_STOCK_INVENTORY: 'Sub Stock Inventory',
  DOCTOR: 'Doctor',
  ICD: 'International Classification of Diseases (ICD)',
  NURSE: 'Nurse',
  OTHER_STAFF: 'Other Staff',
  EXECUTIVE: 'Executive',
  MANAGER: 'Manager',
  HOSPITAL_ROOM: 'Hospital Room',
  NURSE_STATION: 'Nurse Station',
  EQUIPMENT_MANAGEMENT: 'Equipment Management',
  COMPANY: 'Company',
  HOSPITAL: 'Hospital',
  DEPARTMENT: 'Department',
  SEED_CONFIGURATION: 'Seed Configuration',
  APP_ROLES: 'App Roles',
  HOSPITAL_TEAM: 'Hospital Teams',
  TEAM_ROLE_ASSOCIATION: 'Team Role Association',
  OPERATION_AREA_ACCESS: 'Operational Area Access',
  APP_USERS: 'App Users',
  MIGRATE_DATA: 'Migrate Data',
  INTEGRATION_SETUP: 'Integration Setup',
  SUPPORT_TICKET_LIST: 'Support Ticket List',
  INVENTORY: 'Inventory',
  ORDERED_ITEMS_REPORT: 'Ordered Items Report',
  ADT_MANAGEMENT: 'ADT Management',
  RAPID_REGISTRATION: 'Rapid Registration',
  PROCUREMENT: 'Procurement',
  MANAGEMENT: 'Management',
  INVENTORY_MANAGEMENT: 'Inventory Management',
  PURCHASE_REQUEST_MANAGEMENT: 'Purchase Request Management',
  TRANSFER_REQUEST_MANAGEMENT: 'Transfer Request Management',
  STATISTICS: 'Statistics'
};

export const SUB_MENU_KEYS = {
  [MAIN_MENUS_KEYS.registration]: {
    DASHBOARD: MAIN_MENUS_KEYS.registration + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    PATIENT_LIST: MAIN_MENUS_KEYS.registration + '_' + SUB_MENU_OPTIONS.PATIENT_LIST,
    PATIENT_VERIFICATION: MAIN_MENUS_KEYS.registration + '_' + SUB_MENU_OPTIONS.PATIENT_VERIFICATION
  },
  [MAIN_MENUS_KEYS.rapidRegistration]: {
    DASHBOARD: MAIN_MENUS_KEYS.rapidRegistration + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    PATIENT_LIST: MAIN_MENUS_KEYS.rapidRegistration + '_' + SUB_MENU_OPTIONS.PATIENT_LIST,
    TRIAGE: MAIN_MENUS_KEYS.rapidRegistration + '_' + SUB_MENU_OPTIONS.TRIAGE,
    PENDING_ORDERS: MAIN_MENUS_KEYS.rapidRegistration + '_' + SUB_MENU_OPTIONS.PENDING_ORDERS
  },
  [MAIN_MENUS_KEYS.paymentType]: {
    DASHBOARD: MAIN_MENUS_KEYS.paymentType + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    PAYMENT_PLAN: MAIN_MENUS_KEYS.paymentType + '_' + SUB_MENU_OPTIONS.PAYMENT_PLAN,
    VALIDATE_PAYMENT: MAIN_MENUS_KEYS.paymentType + '_' + SUB_MENU_OPTIONS.VALIDATE_PAYMENT,
    VALIDATE_COVERAGE: MAIN_MENUS_KEYS.paymentType + '_' + SUB_MENU_OPTIONS.VALIDATE_COVERAGE,
    PAYMENT_PRIORITY: MAIN_MENUS_KEYS.paymentType + '_' + SUB_MENU_OPTIONS.PAYMENT_PRIORITY
  },
  [MAIN_MENUS_KEYS.patientVisit]: {
    DASHBOARD: MAIN_MENUS_KEYS.patientVisit + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    PATIENT_APPOINTMENT: MAIN_MENUS_KEYS.patientVisit + '_' + SUB_MENU_OPTIONS.PATIENT_APPOINTMENT,
    TRIAGE: MAIN_MENUS_KEYS.patientVisit + '_' + SUB_MENU_OPTIONS.TRIAGE
  },
  [MAIN_MENUS_KEYS.adtManagement]: {
    DASHBOARD: MAIN_MENUS_KEYS.adtManagement + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    ADT_REQUEST: MAIN_MENUS_KEYS.adtManagement + '_' + SUB_MENU_OPTIONS.ADT_REQUEST,
    BED_OCCUPANCY: MAIN_MENUS_KEYS.adtManagement + '_' + SUB_MENU_OPTIONS.BED_OCCUPANCY,
    DISCHARGE_PATIENT: MAIN_MENUS_KEYS.adtManagement + '_' + SUB_MENU_OPTIONS.DISCHARGE_PATIENT,
    PENDING_ORDERS: MAIN_MENUS_KEYS.adtManagement + '_' + SUB_MENU_OPTIONS.PENDING_ORDERS
  },
  [MAIN_MENUS_KEYS.bedManagement]: {
    HOSPITAL_BED: MAIN_MENUS_KEYS.bedManagement + '_' + SUB_MENU_OPTIONS.HOSPITAL_BED,
    PATIENT_ROOM: MAIN_MENUS_KEYS.bedManagement + '_' + SUB_MENU_OPTIONS.PATIENT_ROOM,
    BED_PIPELINE: MAIN_MENUS_KEYS.bedManagement + '_' + SUB_MENU_OPTIONS.BED_PIPELINE
  },
  [MAIN_MENUS_KEYS.nurseOpd]: {
    DASHBOARD: MAIN_MENUS_KEYS.nurseOpd + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    PATIENT_PROFILE: MAIN_MENUS_KEYS.nurseOpd + '_' + SUB_MENU_OPTIONS.PATIENT_PROFILE,
    PATIENT_DATA: MAIN_MENUS_KEYS.nurseOpd + '_' + SUB_MENU_OPTIONS.PATIENT_DATA,
    PATIENT_REVIEW: MAIN_MENUS_KEYS.nurseOpd + '_' + SUB_MENU_OPTIONS.PATIENT_REVIEW,
    CONSULTATION_TRANSFER: MAIN_MENUS_KEYS.nurseOpd + '_' + SUB_MENU_OPTIONS.CONSULTATION_TRANSFER
  },
  [MAIN_MENUS_KEYS.nurseIpd]: {
    DASHBOARD: MAIN_MENUS_KEYS.nurseIpd + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    PATIENT_PROFILE: MAIN_MENUS_KEYS.nurseIpd + '_' + SUB_MENU_OPTIONS.PATIENT_PROFILE,
    PATIENT_DATA: MAIN_MENUS_KEYS.nurseIpd + '_' + SUB_MENU_OPTIONS.PATIENT_DATA,
    PATIENT_REVIEW: MAIN_MENUS_KEYS.nurseIpd + '_' + SUB_MENU_OPTIONS.PATIENT_REVIEW,
    PATIENT_MANAGEMENT: MAIN_MENUS_KEYS.nurseIpd + '_' + SUB_MENU_OPTIONS.PATIENT_MANAGEMENT
  },
  [MAIN_MENUS_KEYS.doctorOpd]: {
    DASHBOARD: MAIN_MENUS_KEYS.doctorOpd + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    PATIENT_PROFILE: MAIN_MENUS_KEYS.doctorOpd + '_' + SUB_MENU_OPTIONS.PATIENT_PROFILE,
    PATIENT_DATA: MAIN_MENUS_KEYS.doctorOpd + '_' + SUB_MENU_OPTIONS.PATIENT_DATA,
    PATIENT_REVIEW: MAIN_MENUS_KEYS.doctorOpd + '_' + SUB_MENU_OPTIONS.PATIENT_REVIEW,
    CONSULTATION_TRANSFER: MAIN_MENUS_KEYS.doctorOpd + '_' + SUB_MENU_OPTIONS.CONSULTATION_TRANSFER
  },
  [MAIN_MENUS_KEYS.doctorIpd]: {
    DASHBOARD: MAIN_MENUS_KEYS.doctorIpd + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    PATIENT_PROFILE: MAIN_MENUS_KEYS.doctorIpd + '_' + SUB_MENU_OPTIONS.PATIENT_PROFILE,
    PATIENT_DATA: MAIN_MENUS_KEYS.doctorIpd + '_' + SUB_MENU_OPTIONS.PATIENT_DATA,
    PATIENT_REVIEW: MAIN_MENUS_KEYS.doctorIpd + '_' + SUB_MENU_OPTIONS.PATIENT_REVIEW,
    PATIENT_MANAGEMENT: MAIN_MENUS_KEYS.doctorIpd + '_' + SUB_MENU_OPTIONS.PATIENT_MANAGEMENT
  },
  [MAIN_MENUS_KEYS.wardManagement]: {
    DASHBOARD: MAIN_MENUS_KEYS.wardManagement + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    HOSPITAL_WARD: MAIN_MENUS_KEYS.wardManagement + '_' + SUB_MENU_OPTIONS.HOSPITAL_WARD,
    NURSING_STATION: MAIN_MENUS_KEYS.wardManagement + '_' + SUB_MENU_OPTIONS.NURSING_STATION
  },
  [MAIN_MENUS_KEYS.radiology]: {
    DASHBOARD: MAIN_MENUS_KEYS.radiology + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    PATIENT_BACKLOG: MAIN_MENUS_KEYS.radiology + '_' + SUB_MENU_OPTIONS.PATIENT_BACKLOG,
    PATIENT_INVESTIGATION: MAIN_MENUS_KEYS.radiology + '_' + SUB_MENU_OPTIONS.PATIENT_INVESTIGATION,
    RESULT_UPLOAD: MAIN_MENUS_KEYS.radiology + '_' + SUB_MENU_OPTIONS.RESULT_UPLOAD,
    REPORT_ENTRY: MAIN_MENUS_KEYS.radiology + '_' + SUB_MENU_OPTIONS.REPORT_ENTRY,
  },
  [MAIN_MENUS_KEYS.lab]: {
    DASHBOARD: MAIN_MENUS_KEYS.lab + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    PATIENT_BACKLOG: MAIN_MENUS_KEYS.lab + '_' + SUB_MENU_OPTIONS.PATIENT_BACKLOG,
    SAMPLE_COLLECTION: MAIN_MENUS_KEYS.lab + '_' + SUB_MENU_OPTIONS.SAMPLE_COLLECTION,
    RESULT_UPLOAD: MAIN_MENUS_KEYS.lab + '_' + SUB_MENU_OPTIONS.RESULT_UPLOAD,
    REPORT_ENTRY: MAIN_MENUS_KEYS.lab + '_' + SUB_MENU_OPTIONS.REPORT_ENTRY
  },
  [MAIN_MENUS_KEYS.pharmacy]: {
    DASHBOARD: MAIN_MENUS_KEYS.pharmacy + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    VERIFY_MEDICINE: MAIN_MENUS_KEYS.pharmacy + '_' + SUB_MENU_OPTIONS.VERIFY_MEDICINE,
    PREPARE_MEDICINE: MAIN_MENUS_KEYS.pharmacy + '_' + SUB_MENU_OPTIONS.PREPARE_MEDICINE,
    DISPENSE_MEDICINE: MAIN_MENUS_KEYS.pharmacy + '_' + SUB_MENU_OPTIONS.DISPENSE_MEDICINE,
    RETURN_MEDICINE: MAIN_MENUS_KEYS.pharmacy + '_' + SUB_MENU_OPTIONS.RETURN_MEDICINE,
    DRUG_INTERACTION_ALLERGY: MAIN_MENUS_KEYS.pharmacy + '_' + SUB_MENU_OPTIONS.DRUG_INTERACTION_ALLERGY
  },
  [MAIN_MENUS_KEYS.nutritionMeal]: {
    PREPARE_MEAL: MAIN_MENUS_KEYS.nutritionMeal + '_' + SUB_MENU_OPTIONS.PREPARE_MEAL
  },
  [MAIN_MENUS_KEYS.operationRoom]: {
    DASHBOARD: MAIN_MENUS_KEYS.operationRoom + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    OR_REQUEST: MAIN_MENUS_KEYS.operationRoom + '_' + SUB_MENU_OPTIONS.OR_REQUEST,
    OR_SCHEDULE: MAIN_MENUS_KEYS.operationRoom + '_' + SUB_MENU_OPTIONS.OR_SCHEDULE,
    SURGICAL_INSTRUCTION: MAIN_MENUS_KEYS.operationRoom + '_' + SUB_MENU_OPTIONS.SURGICAL_INSTRUCTION,
    OR_LIST: MAIN_MENUS_KEYS.operationRoom + '_' + SUB_MENU_OPTIONS.OR_LIST
  },
  [MAIN_MENUS_KEYS.insuranceSSCC]: {
    DASHBOARD: MAIN_MENUS_KEYS.insuranceSSCC + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    COMPANY_CONTRACT_MANAGEMENT: MAIN_MENUS_KEYS.insuranceSSCC + '_' + SUB_MENU_OPTIONS.COMPANY_CONTRACT_MANAGEMENT,
    INSURANCE_PLAN_MANAGEMENT: MAIN_MENUS_KEYS.insuranceSSCC + '_' + SUB_MENU_OPTIONS.INSURANCE_PLAN_MANAGEMENT,
    SELF_PAY_CODE_MANAGEMENT: MAIN_MENUS_KEYS.insuranceSSCC + '_' + SUB_MENU_OPTIONS.SELF_PAY_CODE_MANAGEMENT
  },
  [MAIN_MENUS_KEYS.billing]: {
    DASHBOARD: MAIN_MENUS_KEYS.billing + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    BILLING_PIPELINE: MAIN_MENUS_KEYS.billing + '_' + SUB_MENU_OPTIONS.BILLING_PIPELINE,
    VISIT_BILLING: MAIN_MENUS_KEYS.billing + '_' + SUB_MENU_OPTIONS.VISIT_BILLING,
    ADMISSION_BILLING: MAIN_MENUS_KEYS.billing + '_' + SUB_MENU_OPTIONS.ADMISSION_BILLING,
    //for wallet management
    WALLET_MANAGEMENT: MAIN_MENUS_KEYS.billing + '_' + SUB_MENU_OPTIONS.WALLET_MANAGEMENT,
    SUBMIT_CLAIM: MAIN_MENUS_KEYS.billing + '_' + SUB_MENU_OPTIONS.SUBMIT_CLAIM,
    ACCEPT_PAYMENT: MAIN_MENUS_KEYS.billing + '_' + SUB_MENU_OPTIONS.ACCEPT_PAYMENT,
    PATIENT_REVIEW: MAIN_MENUS_KEYS.billing + '_' + SUB_MENU_OPTIONS.PATIENT_REVIEW,
    BILL_MANAGEMENT: MAIN_MENUS_KEYS.billing + '_' + SUB_MENU_OPTIONS.BILL_MANAGEMENT
  },
  [MAIN_MENUS_KEYS.medicalCoding]: {
    ADD_CODES: MAIN_MENUS_KEYS.medicalCoding + '_' + SUB_MENU_OPTIONS.ADD_CODES,
    PATIENT_REVIEW: MAIN_MENUS_KEYS.medicalCoding + '_' + SUB_MENU_OPTIONS.PATIENT_REVIEW
  },
  [MAIN_MENUS_KEYS.corpManagement]: {
    CATALOGS: MAIN_MENUS_KEYS.corpManagement + '_' + SUB_MENU_OPTIONS.CATALOGS,
    INCOME_TYPE: MAIN_MENUS_KEYS.corpManagement + '_' + SUB_MENU_OPTIONS.INCOME_TYPE,
    PATIENT_BILLING_CODE: MAIN_MENUS_KEYS.corpManagement + '_' + SUB_MENU_OPTIONS.PATIENT_BILLING_CODE,
    MEDICAL_CODE_LIST: MAIN_MENUS_KEYS.corpManagement + '_' + SUB_MENU_OPTIONS.MEDICAL_CODE_LIST,
    DOCTOR_FEE_SETUP: MAIN_MENUS_KEYS.corpManagement + '_' + SUB_MENU_OPTIONS.DOCTOR_FEE_SETUP,
    PAYMENT_TYPE_MANAGEMENT: MAIN_MENUS_KEYS.corpManagement + '_' + SUB_MENU_OPTIONS.PAYMENT_TYPE_MANAGEMENT,
    FORM_MANAGEMENT: MAIN_MENUS_KEYS.corpManagement + '_' + SUB_MENU_OPTIONS.FORM_MANAGEMENT,
    INTERNAL_OPERATION_CATALOG: MAIN_MENUS_KEYS.corpManagement + '_' + SUB_MENU_OPTIONS.INTERNAL_OPERATION_CATALOG,
    RADIOLOGY_CATALOG: MAIN_MENUS_KEYS.corpManagement + '_' + SUB_MENU_OPTIONS.RADIOLOGY_CATALOG,
    LAB_CATALOG: MAIN_MENUS_KEYS.corpManagement + '_' + SUB_MENU_OPTIONS.LAB_CATALOG,
    MEAL_CATALOG: MAIN_MENUS_KEYS.corpManagement + '_' + SUB_MENU_OPTIONS.MEAL_CATALOG,
    PHARMA_CATALOG: MAIN_MENUS_KEYS.corpManagement + '_' + SUB_MENU_OPTIONS.PHARMA_CATALOG,
    PHARMACY_OUTLETS: MAIN_MENUS_KEYS.corpManagement + '_' + SUB_MENU_OPTIONS.PHARMACY_OUTLETS,
    SERVICE_CATALOG: MAIN_MENUS_KEYS.corpManagement + '_' + SUB_MENU_OPTIONS.SERVICE_CATALOG,
    SUPPLIES_CATALOG: MAIN_MENUS_KEYS.corpManagement + '_' + SUB_MENU_OPTIONS.SUPPLIES_CATALOG,
    MEDICAL_PACKAGE: MAIN_MENUS_KEYS.corpManagement + '_' + SUB_MENU_OPTIONS.MEDICAL_PACKAGE,
    OPD_CHARGES_SETTINGS: MAIN_MENUS_KEYS.corpManagement + '_' + SUB_MENU_OPTIONS.OPD_CHARGES_SETTINGS,

  },
  [MAIN_MENUS_KEYS.inventory]: {
    DASHBOARD: MAIN_MENUS_KEYS.inventory + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    MAIN_STOCK_INVENTORY: MAIN_MENUS_KEYS.inventory + '_' + SUB_MENU_OPTIONS.MAIN_STOCK_INVENTORY,
    SUB_STOCK_OVERVIEW: MAIN_MENUS_KEYS.inventory + '_' + SUB_MENU_OPTIONS.SUB_STOCK_OVERVIEW,
    TRANSFER_REQUEST: MAIN_MENUS_KEYS.inventory + '_' + SUB_MENU_OPTIONS.TRANSFER_REQUEST,
    PURCHASE_REQUEST: MAIN_MENUS_KEYS.inventory + '_' + SUB_MENU_OPTIONS.PURCHASE_REQUEST,
    RECEIVED_INVENTORY: MAIN_MENUS_KEYS.inventory + '_' + SUB_MENU_OPTIONS.RECEIVED_INVENTORY,
    INVENTORY_MANAGEMENT: MAIN_MENUS_KEYS.inventory + '_' + SUB_MENU_OPTIONS.INVENTORY_MANAGEMENT,
    PURCHASE_REQUEST_MANAGEMENT: MAIN_MENUS_KEYS.inventory + '_' + SUB_MENU_OPTIONS.PURCHASE_REQUEST_MANAGEMENT,
  },
  [MAIN_MENUS_KEYS.procurement]: {
    MAIN_STOCK_INVENTORY: MAIN_MENUS_KEYS.procurement + '_' + SUB_MENU_OPTIONS.MAIN_STOCK_INVENTORY,
    PURCHASE_REQUEST_BACKLOG: MAIN_MENUS_KEYS.procurement + '_' + SUB_MENU_OPTIONS.PURCHASE_REQUEST_BACKLOG,
    PURCHASE_ORDER_BACKLOG: MAIN_MENUS_KEYS.procurement + '_' + SUB_MENU_OPTIONS.PURCHASE_ORDER_BACKLOG,
    VENDOR_MANAGEMENT: MAIN_MENUS_KEYS.procurement + '_' + SUB_MENU_OPTIONS.VENDOR_MANAGEMENT,
    PURCHASE_ORDER_MANAGEMENT: MAIN_MENUS_KEYS.procurement + '_' + SUB_MENU_OPTIONS.PURCHASE_ORDER_MANAGEMENT
  },
  [MAIN_MENUS_KEYS.subStockManagement]: {
    DASHBOARD: MAIN_MENUS_KEYS.subStockManagement + '_' + SUB_MENU_OPTIONS.DASHBOARD,
    SUB_STOCK_INVENTORY: MAIN_MENUS_KEYS.subStockManagement + '_' + SUB_MENU_OPTIONS.SUB_STOCK_INVENTORY,
    OUTGOING_TRANSFER_REQUEST: MAIN_MENUS_KEYS.subStockManagement + '_' + SUB_MENU_OPTIONS.OUTGOING_TRANSFER_REQUEST,
    INCOMING_TRANSFER_REQUEST: MAIN_MENUS_KEYS.subStockManagement + '_' + SUB_MENU_OPTIONS.INCOMING_TRANSFER_REQUEST,
    INVENTORY_MANAGEMENT: MAIN_MENUS_KEYS.subStockManagement + '_' + SUB_MENU_OPTIONS.INVENTORY_MANAGEMENT,
    TRANSFER_REQUEST_MANAGEMENT: MAIN_MENUS_KEYS.subStockManagement + '_' + SUB_MENU_OPTIONS.TRANSFER_REQUEST_MANAGEMENT
  },
  [MAIN_MENUS_KEYS.medicalParty]: {
    DOCTOR: MAIN_MENUS_KEYS.medicalParty + '_' + SUB_MENU_OPTIONS.DOCTOR,
    NURSE: MAIN_MENUS_KEYS.medicalParty + '_' + SUB_MENU_OPTIONS.NURSE,
    OTHER_STAFF: MAIN_MENUS_KEYS.medicalParty + '_' + SUB_MENU_OPTIONS.OTHER_STAFF
  },
  [MAIN_MENUS_KEYS.nonMedicalParty]: {
    EXECUTIVE: MAIN_MENUS_KEYS.nonMedicalParty + '_' + SUB_MENU_OPTIONS.EXECUTIVE,
    MANAGER: MAIN_MENUS_KEYS.nonMedicalParty + '_' + SUB_MENU_OPTIONS.MANAGER,
    OTHER_STAFF: MAIN_MENUS_KEYS.nonMedicalParty + '_' + SUB_MENU_OPTIONS.OTHER_STAFF
  },
  [MAIN_MENUS_KEYS.facilityResource]: {
    HOSPITAL_ROOM: MAIN_MENUS_KEYS.facilityResource + '_' + SUB_MENU_OPTIONS.HOSPITAL_ROOM,
    NURSE_STATION: MAIN_MENUS_KEYS.facilityResource + '_' + SUB_MENU_OPTIONS.NURSE_STATION,
    EQUIPMENT_MANAGEMENT: MAIN_MENUS_KEYS.facilityResource + '_' + SUB_MENU_OPTIONS.EQUIPMENT_MANAGEMENT
  },
  [MAIN_MENUS_KEYS.corpSetup]: {
    COMPANY: MAIN_MENUS_KEYS.corpSetup + '_' + SUB_MENU_OPTIONS.COMPANY,
    HOSPITAL: MAIN_MENUS_KEYS.corpSetup + '_' + SUB_MENU_OPTIONS.HOSPITAL,
    DEPARTMENT: MAIN_MENUS_KEYS.corpSetup + '_' + SUB_MENU_OPTIONS.DEPARTMENT,
    SEED_CONFIGURATION: MAIN_MENUS_KEYS.corpSetup + '_' + SUB_MENU_OPTIONS.SEED_CONFIGURATION
  },
  [MAIN_MENUS_KEYS.userManagement]: {
    APP_ROLES: MAIN_MENUS_KEYS.userManagement + '_' + SUB_MENU_OPTIONS.APP_ROLES,
    HOSPITAL_TEAM: MAIN_MENUS_KEYS.userManagement + '_' + SUB_MENU_OPTIONS.HOSPITAL_TEAM,
    TEAM_ROLE_ASSOCIATION: MAIN_MENUS_KEYS.userManagement + '_' + SUB_MENU_OPTIONS.TEAM_ROLE_ASSOCIATION,
    OPERATION_AREA_ACCESS: MAIN_MENUS_KEYS.userManagement + '_' + SUB_MENU_OPTIONS.OPERATION_AREA_ACCESS,
    APP_USERS: MAIN_MENUS_KEYS.userManagement + '_' + SUB_MENU_OPTIONS.APP_USERS
  },
  [MAIN_MENUS_KEYS.aimSetup]: {
    MIGRATE_DATA: MAIN_MENUS_KEYS.aimSetup + '_' + SUB_MENU_OPTIONS.MIGRATE_DATA,
    INTEGRATION_SETUP: MAIN_MENUS_KEYS.aimSetup + '_' + SUB_MENU_OPTIONS.INTEGRATION_SETUP
  },
  [MAIN_MENUS_KEYS.supportCenter]: {
    SUPPORT_TICKET_LIST: MAIN_MENUS_KEYS.supportCenter + '_' + SUB_MENU_OPTIONS.SUPPORT_TICKET_LIST
  },
  [MAIN_MENUS_KEYS.reports]: {
    INVENTORY: MAIN_MENUS_KEYS.reports + '_' + SUB_MENU_OPTIONS.INVENTORY,
    ORDERED_ITEMS_REPORT: MAIN_MENUS_KEYS.reports + '_' + SUB_MENU_OPTIONS.ORDERED_ITEMS_REPORT,
    ADT_MANAGEMENT: MAIN_MENUS_KEYS.reports + '_' + SUB_MENU_OPTIONS.ADT_MANAGEMENT,
    RAPID_REGISTRATION: MAIN_MENUS_KEYS.reports + '_' + SUB_MENU_OPTIONS.RAPID_REGISTRATION,
    PROCUREMENT: MAIN_MENUS_KEYS.reports + '_' + SUB_MENU_OPTIONS.PROCUREMENT,
    STATISTICS: MAIN_MENUS_KEYS.reports + '_' + SUB_MENU_OPTIONS.STATISTICS,
    MANAGEMENT: MAIN_MENUS_KEYS.reports + '_' + SUB_MENU_OPTIONS.MANAGEMENT,
    DOCTOR: MAIN_MENUS_KEYS.reports + '_' + SUB_MENU_OPTIONS.DOCTOR,
    ICD: MAIN_MENUS_KEYS.reports + '_' + SUB_MENU_OPTIONS.ICD,
  }
}
