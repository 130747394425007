import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MdePopoverModule} from '@material-extended/mde';
import {TimelineStatusComponent} from './timeline-status.component';
import { PharmacyOrderStatusTimelineComponent } from './pharmacy-order-status-timeline/pharmacy-order-status-timeline.component';

@NgModule({
  declarations: [TimelineStatusComponent, PharmacyOrderStatusTimelineComponent],
  exports: [
    TimelineStatusComponent,
    PharmacyOrderStatusTimelineComponent
  ],
  imports: [
    CommonModule,
    MdePopoverModule
  ]
})
export class TimelineStatusModule {
}
