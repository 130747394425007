export interface IconRegistry {
  icon: string,
  path: string
}

export const ICON_REGISTRIES: IconRegistry[] = [
  {
    icon: '',
    path: 'assets/images/svg/his-sprite.svg'
  },
  {
    icon: 'bed',
    path: 'assets/images/svg/svg-sprite-icons/bed.svg'
  },
  {
    icon: 'bed-empty',
    path: 'assets/images/svg/svg-sprite-icons/bed-empty.svg'
  },
  {
    icon: 'filter-icon',
    path: 'assets/images/svg/svg-sprite-icons/filter-icon.svg'
  },
  {
    icon: 'ic-close',
    path: 'assets/images/svg/svg-sprite-icons/ic-close.svg'
  },
  {
    icon: 'ic-sort',
    path: 'assets/images/svg/svg-sprite-icons/ic-sort.svg'
  },
  {
    icon: 'help',
    path: 'assets/images/svg/ic-help.svg'
  },
  {
    icon: 'message',
    path: 'assets/images/svg/ic-message.svg'
  },
  {
    icon: 'error',
    path: 'assets/images/svg/ic-error.svg'
  },
  {
    icon: 'ic-corp-setup',
    path: 'assets/images/icons/ic-corp-setup.svg'
  },
  {
    icon: 'ic-medical-services',
    path: 'assets/images/icons/ic-medical-services.svg'
  },
  {
    icon: 'ic-service-delivery',
    path: 'assets/images/icons/ic-service-delivery.svg'
  },
  {
    icon: 'ic-contract',
    path: 'assets/images/icons/ic-contract.svg'
  },
  {
    icon: 'ic-customer-account',
    path: 'assets/images/icons/ic-customer-account.svg'
  },
  {
    icon: 'ic-dashboard',
    path: 'assets/images/icons/ic-dashboard.svg'
  },
  {
    icon: 'ic-lab-result',
    path: 'assets/images/icons/ic-lab-result.svg'
  },
  {
    icon: 'ic-marketing',
    path: 'assets/images/icons/ic-marketing.svg'
  },
  {
    icon: 'ic-supplier-account',
    path: 'assets/images/icons/ic-supplier-account.svg'
  },
  {
    icon: 'ic-users',
    path: 'assets/images/icons/ic-users.svg'
  },
  {
    icon: 'ic-downmenu',
    path: 'assets/images/icons/ic-dropdown.svg'
  },
  {
    icon: 'ic-medical-catalogue',
    path: 'assets/images/icons/ic_med_cat.svg'
  },
  {
    icon: 'ic-marketing-report',
    path: 'assets/images/icons/ic-marketing reports.svg'
  },
  {
    icon: 'ic-corp-company',
    path: 'assets/images/icons/nav/ic-company.svg'
  },
  {
    icon: 'ic-hospital',
    path: 'assets/images/icons/nav/ic-hospital.svg'
  },
  {
    icon: 'ic-team',
    path: 'assets/images/icons/nav/ic-team.svg'
  },
  {
    icon: 'ic-clinic',
    path: 'assets/images/icons/nav/ic-clinic.svg'
  },
  {
    icon: 'ic-client-company',
    path: 'assets/images/icons/nav/ic-client-company.svg'
  },
  {
    icon: 'ic-subsidiary',
    path: 'assets/images/icons/nav/ic-subsidiary.svg'
  },
  {
    icon: 'ic-business-unit',
    path: 'assets/images/icons/nav/ic-business-unit.svg'
  },
  {
    icon: 'ic-customer-employee',
    path: 'assets/images/icons/nav/ic-employee.svg'
  },
  {
    icon: 'ic-supplier-company',
    path: 'assets/images/icons/nav/ic-supplier-company.svg'
  },
  {
    icon: 'ic-catalog',
    path: 'assets/images/icons/nav/ic-catalog.svg'
  },
  {
    icon: 'ic-catalog-element',
    path: 'assets/images/icons/nav/ic-catalog-element.svg'
  },
  {
    icon: 'ic-catalog-group',
    path: 'assets/images/icons/nav/ic-catalog-group.svg'
  },
  {
    icon: 'ic-contract',
    path: 'assets/images/icons/nav/ic-contract.svg'
  },
  {
    icon: 'ic-contract-packages',
    path: 'assets/images/icons/nav/ic-contract-packages.svg'
  },
  {
    icon: 'ic-guideline',
    path: 'assets/images/icons/nav/ic-guideline.svg'
  },
  {
    icon: 'ic-service-package',
    path: 'assets/images/icons/nav/ic-service-package.svg'
  },
  {
    icon: 'ic-campaign-list',
    path: 'assets/images/icons/nav/ic-campaign-list.svg'
  },
  {
    icon: 'ic-corp-campaign',
    path: 'assets/images/icons/nav/ic-corp-campaign.svg'
  },
  {
    icon: 'ic-service-delivery',
    path: 'assets/images/icons/nav/ic-service-delivery.svg'
  },
  {
    icon: 'ic-check-up-plan',
    path: 'assets/images/icons/nav/ic-check-up-plan.svg'
  },
  {
    icon: 'ic-lab-result',
    path: 'assets/images/icons/nav/ic-lab-result.svg'
  },
  {
    icon: 'ic-patient-visit',
    path: 'assets/images/icons/nav/ic-patient-visit.svg'
  },
  {
    icon: 'ic-basic-report',
    path: 'assets/images/icons/nav/ic-basic-report.svg'
  },
  {
    icon: 'ic-condition-type',
    path: 'assets/images/icons/nav/ic-condition-type.svg'
  },
  {
    icon: 'ic-department-type',
    path: 'assets/images/icons/nav/ic-department-type.svg'
  },
  {
    icon: 'ic-medical-cluster',
    path: 'assets/images/icons/nav/ic-medical-cluster.svg'
  },
  {
    icon: 'ic-sources',
    path: 'assets/images/icons/nav/ic-sources.svg'
  },
  {
    icon: 'ic-test-type',
    path: 'assets/images/icons/nav/ic-test-type.svg'
  },
  {
    icon: 'ic-employee-report',
    path: 'assets/images/icons/nav/ic-employee-report.svg'
  },
  {
    icon: 'ic-notification',
    path: 'assets/images/icons/notification.svg'
  },
];
