import {Pipe, PipeTransform} from '@angular/core';
import {AppointmentStatusEnum} from '@v2/core/enums/appointment-status.enum';

@Pipe({
  name: 'appointmentStatusClass'
})
export class AppointmentStatusClassPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    switch (value) {
      case AppointmentStatusEnum.COMPLETED:
        return 'text-lochmara bg-lochmara-15';
      case AppointmentStatusEnum.PENDING:
      case AppointmentStatusEnum.IN_PROGRESS:
        return 'text-burnt-sienna bg-burnt-sienna-15';
      default:
        return 'bg-black-1';
    }
  }

}
