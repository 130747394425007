import {Pipe, PipeTransform} from '@angular/core';
import {OROrderStatusEnum} from '@v2/core/enums/or-order-status.enum';

@Pipe({
  name: 'orOrderStatusClass'
})
export class OrOrderStatusClassPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case OROrderStatusEnum.PENDING:
        return 'text-jaffa bg-jaffa-15 border-jaffa';
      case OROrderStatusEnum.OPEN:
      case OROrderStatusEnum.SCHEDULED:
        return 'text-primary bg-primary-light-1 border-primary';
      case OROrderStatusEnum.CHECKED_IN:
        return 'text-lochmara bg-lochmara-15 border-lochmara';
      case OROrderStatusEnum.COMPLETED:
        return 'text-eucalyptus bg-eucalyptus-15 border-eucalyptus';
      case OROrderStatusEnum.CANCELED:
        return 'text-burnt-sienna bg-burnt-sienna-15 border-burnt-sienna';
      default:
        return '';
    }
  }

}
