import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService {

  clearLoadingError$ = new Subject<void>();
  private _chunkLoadingError = new Subject<void>();
  chunkLoadingError$ = this._chunkLoadingError.asObservable();

  constructor() {
  }

  private _loadingError$ = new Subject<{ error: HttpErrorResponse, key: string }>();

  get loadingError$(): Observable<{ error: HttpErrorResponse, key: string }> {
    return this._loadingError$.asObservable();
  }

  setLoadingError(error: HttpErrorResponse, key: string) {
    this._loadingError$.next({error, key});
  }

  clearLoadingError() {
    this.clearLoadingError$.next();
  }

  setChunkLoadingError() {
    this._chunkLoadingError.next();
  }
}
