import {FormControl, Validators} from '@angular/forms';
import {OrderTypeEnum} from '@v2/core/enums/order-type.enum';
import {PatientOrderStatusEnum} from '@v2/core/enums/patient-order-status.enum';
import {
  getCurrentDateTime,
  getCurrentTime,
  getStartOfDay,
  getTimeFromDate,
  getUTCDateTime,
  mapDepartmentDataToDepartmentMinimal,
  mapFullNameInDoctor,
  objectValidator
} from '@v2/core/functions/functions';
import {ICatalogElement, IDepartmentMinimal, IDoctor, IMasterData} from '@v2/core/models/masterdata';
import {ConcurrencyRequest} from '@v2/core/models/masterdata/IConcurrencyRequest';
import {IMedicalPackage} from '@v2/core/models/masterdata/IMedicalPackage.master-data';
import {IFavoriteOrderElement, IPatientOrder} from '@v2/core/models/masterdata/IPatientOrder.master-data';
import {FavoriteFormDataModel} from './favorite-form.model';

interface IPathologyOrderForm {
  id: string | FormControl;
  apptId?: string | FormControl;
  name: string | ICatalogElement | FormControl;
  selectedName?: ICatalogElement[] | FormControl;
  requestedDateTime: string | FormControl;
  preferredDate: string | FormControl;
  preferredTime: string | FormControl;
  note: string | FormControl;
  catalogGroupId: string | FormControl;
  catalogElementId: string | FormControl;
  groupName: string | FormControl;
  isGroup: boolean | FormControl;
  orderType: string | FormControl;
  orderStatus: string | FormControl;
  orderXref: string | FormControl;
  severity: string | FormControl;
  severityStatus: number | FormControl;
  isOrderCancelled: boolean | FormControl;
  orderCancellationNote: string | FormControl;
  isMedicalPackageOrder: boolean | FormControl;
  isReordered: boolean | FormControl;

  department: IDepartmentMinimal | FormControl;
  doctor: IDoctor | FormControl;
  orderOrigin: string | FormControl;

  isSubmitting?: boolean | FormControl;
  isSubmitted?: boolean | FormControl;
  errorMessage?: string | FormControl;
  isAdded?: boolean | FormControl;
  index?: number | FormControl;
  isFormValid?: boolean | FormControl;
}

export class PathologyOrderForm implements IPathologyOrderForm {
  id = new FormControl();
  apptId = new FormControl();
  catalogElementId = new FormControl();
  selectedName = new FormControl([]);
  catalogGroupId = new FormControl();
  department = new FormControl(null, [Validators.required, objectValidator]);
  doctor = new FormControl(null, [Validators.required, objectValidator]);
  groupName = new FormControl();
  isGroup = new FormControl(false);
  isMedicalPackageOrder = new FormControl();
  isOrderCancelled = new FormControl(false);
  name = new FormControl();
  note = new FormControl();
  orderCancellationNote = new FormControl();
  orderOrigin = new FormControl();
  orderStatus = new FormControl();
  orderType = new FormControl(OrderTypeEnum.lab); // default needs to be handled
  orderXref = new FormControl();
  preferredDate = new FormControl(getCurrentDateTime(), Validators.required);
  preferredTime = new FormControl(getCurrentTime(), Validators.required);
  requestedDateTime = new FormControl(getCurrentDateTime());
  severity = new FormControl();
  severityStatus = new FormControl(null, Validators.required);
  updatedAt = new FormControl();
  isSubmitting = new FormControl();
  isSubmitted = new FormControl();
  errorMessage = new FormControl();
  index = new FormControl();
  isAdded = new FormControl(false);
  isFormValid = new FormControl(false);
  isReordered = new FormControl();
}

export class PathologyFormDataModel extends ConcurrencyRequest implements IPathologyOrderForm {
  id: string;
  apptId: string;
  name: ICatalogElement | string;
  selectedName?: ICatalogElement[];
  severityStatus: number;
  severity: string;
  requestedDateTime: string;
  preferredDate: string;
  preferredTime: string;
  note: string;
  catalogGroupId: string;
  catalogElementId: string;
  groupName: string;
  isGroup: boolean;
  orderType: string;
  orderStatus: string;
  orderXref: string;
  isOrderCancelled: boolean;
  orderCancellationNote: string;
  department: IDepartmentMinimal;
  doctor: IDoctor;
  isMedicalPackageOrder: boolean;
  medicalPackage: IMedicalPackage;
  packageSelectionItemId: string;
  orderOrigin: string;

  updatedAt: string;

  isSelectedForReorder: boolean;
  reorderError: boolean;
  isReordered: boolean;

  index?: number;
  isSubmitting?: boolean;
  isSubmitted?: boolean;
  errorMessage?: string;
  isAdded?: boolean;
  isFormValid?: boolean;

  englishCatalogDesc: string;
  thaiCatalogDesc: string;
  catalogGroupDesc: string;
  pkgSelectionItemUpdatedAt: string;

  static mapFromHttpModel(httpData: IPatientOrder): PathologyFormDataModel {
    const formData = new PathologyFormDataModel();
    formData.id = httpData.id;
    formData.name = httpData.catalogElementId ? httpData.catalogElement.englishName : httpData.catalogGroupElement.name;
    if (httpData.severity) {
      formData.severityStatus = httpData.severity.key;
      formData.severity = httpData.severity.label;
    }

    if (httpData.catalogElement) {
      const {englishCatalogDesc, thaiCatalogDesc} = httpData.catalogElement;
      formData.englishCatalogDesc = englishCatalogDesc;
      formData.thaiCatalogDesc = thaiCatalogDesc;
    }

    formData.pkgSelectionItemUpdatedAt = httpData.pkgSelectionItem ? getUTCDateTime(httpData.pkgSelectionItem.pkgSelectionItemUpdatedAt) : undefined;
    formData.catalogGroupDesc = !!httpData.catalogGroupElement && httpData.catalogGroupElement.desc;
    formData.requestedDateTime = httpData.requestedDateTime;
    formData.preferredDate = getStartOfDay(httpData.preferredDateTime);
    formData.preferredTime = getTimeFromDate(httpData.preferredDateTime);
    formData.note = httpData.note;
    formData.catalogGroupId = httpData.catalogGroupElementId;
    formData.catalogElementId = httpData.catalogElement ? httpData.catalogElement.id : httpData.catalogElementId;
    formData.groupName = httpData.catalogGroupElement && httpData.catalogGroupElement.name;
    formData.isGroup = !httpData.catalogElementId;
    formData.orderType = httpData.orderType ? httpData.orderType.label : '';
    formData.orderStatus = httpData.pathologyOrderStatus ? httpData.pathologyOrderStatus.label : '';
    formData.orderOrigin = httpData.orderOrigin ? httpData.orderOrigin.label : '';
    formData.orderXref = httpData.xref;
    formData.isOrderCancelled = httpData.orderStatus && httpData.orderStatus.label === PatientOrderStatusEnum.CANCELED;
    formData.orderCancellationNote = httpData.statusNote;
    formData.isMedicalPackageOrder = httpData.isPkgOrder;
    formData.medicalPackage = httpData.medicalPackage;
    formData.packageSelectionItemId = httpData.packageSelectionItemId;
    formData.department = mapDepartmentDataToDepartmentMinimal(httpData.orderingDepartment);
    formData.doctor = mapFullNameInDoctor(httpData.orderingDoctor);
    formData.isSubmitted = null;
    formData.errorMessage = null;
    formData.mapConcurrencyRequest(httpData);
    return formData;
  }

  static mapFromMultiOrderFormModel(formData: PathologyFormDataModel): PathologyFormDataModel[] {

    const data: PathologyFormDataModel[] = [];
    const selectedInvestigations = [...formData.selectedName];
    delete formData.selectedName;

    selectedInvestigations.forEach(investigation => {
      const formDataModel: PathologyFormDataModel = {
        ...formData,
        isGroup: investigation.isPanel,
        catalogElementId: investigation.isPanel ? null : investigation.id,
        catalogGroupId: investigation.isPanel ? investigation.id : null,
        name: investigation.name,
        groupName: investigation.isPanel ? investigation.name : null,
        isSubmitted: null,
        isFormValid: true,
        catalogGroupDesc: !!investigation.isPanel && investigation.catalogDesc,
        englishCatalogDesc: investigation.englishDesc,
        thaiCatalogDesc: investigation.thaiCatalogDesc
      }
      data.push(formDataModel);
    });

    return data;
  }

  static mapFromFavoriteOrder(favoriteData: FavoriteFormDataModel, element: IFavoriteOrderElement, normalSeverityStatus: IMasterData): PathologyFormDataModel {
    const formData = new PathologyFormDataModel();
    formData.id = null;
    formData.isGroup = element.isCatalogGroup;
    if (element.isCatalogGroup) {
      formData.name = element.catalogGroup.name;
      formData.groupName = element.catalogGroup.name;
      formData.catalogElementId = null;
      formData.catalogGroupId = element.catalogGroup.id;
      formData.catalogGroupDesc = element.catalogGroup.desc;
    } else {
      formData.name = element.catalogElement.name;
      formData.groupName = null;
      formData.catalogGroupId = null;
      formData.catalogElementId = element.catalogElement.id;
      formData.englishCatalogDesc = element.catalogElement.catalogDesc;
      formData.thaiCatalogDesc = element.catalogElement.thaiCatalogDesc;
    }
    if (normalSeverityStatus) {
      formData.severityStatus = normalSeverityStatus.key;
      formData.severity = normalSeverityStatus.label;
    }
    formData.requestedDateTime = null;
    formData.preferredDate = getCurrentDateTime();
    formData.preferredTime = getCurrentTime();
    formData.note = element.instruction;
    formData.orderType = OrderTypeEnum.lab;
    formData.department = favoriteData.department;
    formData.doctor = favoriteData.doctor;
    formData.isSubmitted = null;
    formData.orderStatus = null;
    formData.isFormValid = true;
    return formData;
  }

  static mapForLabReorder(
    reorderData: PathologyFormDataModel,
    appointmentDepartmentInfo: IDepartmentMinimal,
    appointmentDoctorInfo: IDoctor
  ): PathologyFormDataModel {
    return {
      ...reorderData,
      id: null,
      requestedDateTime: null,
      preferredDate: getCurrentDateTime(),
      preferredTime: getCurrentTime(),
      department: {...appointmentDepartmentInfo},
      doctor: {...appointmentDoctorInfo},
      isSubmitted: null,
      isFormValid: true,
      orderStatus: null,
      isOrderCancelled: false,
      orderXref: null,
      isMedicalPackageOrder: false,
      isReordered: true
    } as PathologyFormDataModel;
  }
}
