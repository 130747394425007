import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {setFilter} from '@v2/core/functions/functions';
import {IAppUser} from '@v2/core/models/masterdata/IAppUser';
import {ICommonFilters} from '@v2/core/models/masterdata/IFilters.master-data';
import {Observable} from 'rxjs';
import {pluck} from 'rxjs/operators';
import {BaseHttpService} from '../http/base-http.service';
import {BaseModel} from '../http/models';

@Injectable({
  providedIn: 'root'
})
export class AppUserService {
  constructor(private baseHttpService: BaseHttpService) {
  }

  getMyInfo(): Observable<IAppUser> {
    const filters: ICommonFilters = {filters: {includes: {hospital: {company: true}, roles: true}}};
    return this.baseHttpService.get<BaseModel<IAppUser>>('login-user/roles-with-operational-area', setFilter(filters)).pipe(
      pluck('data')
    );
  }

  logout(): Observable<any> {
    let params = new HttpParams();
    params = params.append('apiVersion', '1.0');
    return this.baseHttpService.post(`his/logout`, {}, params);
  }
}
