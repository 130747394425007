import {formatDateTime} from '@v2/core/functions/functions';
import {IVitalOPDBloodPressure, IVitalsOPD} from '@v2/core/models/masterdata/IVitals';

export class OpdVitalsViewModel {
  public id: string;
  public bloodPressure: string;
  public bodyTemperature: number | string;
  public pulseRate: number | string;
  public respiratoryRate: number | string;
  public height: number | string;
  public weight: number | string;
  public recordedBy: string;
  public recordedTime: string;
  public bmi: number | string;
  public o2Sat: number | string;
  public map: number | string;
  public headCircumference: number | string;
  public chestCircumference: number | string;
  public waistCircumference: number | string;
  public lmp: number | string;
  public note: string;

  static mapFromHttpModel(item: IVitalsOPD): OpdVitalsViewModel {
    const viewModel = new OpdVitalsViewModel();
    viewModel.id = item.id ? item.id : null;
    viewModel.bloodPressure = (item.bloodPressure) ? this.getBloodPressure(item.bloodPressure) : '-/-';
    viewModel.bodyTemperature = item.bodyTemperature.value;
    viewModel.pulseRate = item.pulseRate.value;
    viewModel.respiratoryRate = item.respiratoryRate.value;
    viewModel.height = item.height.value;
    viewModel.weight = item.weight.value;
    viewModel.recordedBy = item.recordedBy ? item.recordedBy.fullName : '';
    viewModel.recordedTime = formatDateTime(item.recordedTime);
    viewModel.bmi = item.bmi.value;
    viewModel.o2Sat = item.o2Sat.value;
    viewModel.map = item.map.value;
    viewModel.headCircumference = item.headCircumference.value;
    viewModel.chestCircumference = item.chestCircumference.value;
    viewModel.waistCircumference = item.waistCircumference.value;
    viewModel.lmp = item.lmp.value;
    viewModel.note = item.note;

    return viewModel;
  }

  static getBloodPressure(item: IVitalOPDBloodPressure): string {
    const systolicStr = (item.systolic && item.systolic.value) ? item.systolic.value : '-';
    const diastolicStr = (item.diastolic && item.diastolic.value) ? item.diastolic.value : '-';
    return `${systolicStr}/${diastolicStr}`;
  }
}
