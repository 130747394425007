import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';
import {StaleActionEnum} from '../../resources/enums/stale-action.enum';
import {toastrAnimation} from './stale-data-toastr.animation';

@Component({
  selector: 'app-stale-data-snackbar',
  templateUrl: './stale-data-toastr.component.html',
  styleUrls: ['./stale-data-toastr.component.scss'],
  providers: [{provide: TRANSLOCO_SCOPE, useValue: 'common'}],
  animations: [toastrAnimation]
})
export class StaleDataToastrComponent extends Toast implements OnInit {

  staleActionEnum = StaleActionEnum;

  constructor(
    public dialog: MatDialog,
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  ngOnInit() {
  }

  onAction(event, action: string) {
    this.dialog.closeAll();
    event.stopPropagation();
    this.toastPackage.triggerAction(action);
    return false;
  }
}
