import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PatientNameTranslation, PatientNameTranslationV2 } from '@v2/core/functions/patient-name-translation';
import { PatientInfoModalComponent } from './patient-info-modal/patient-info-modal.component';

@Component({
  selector: 'app-patient-name-display',
  templateUrl: './patient-name-display.component.html',
  styleUrls: ['./patient-name-display.component.scss']
})
export class PatientNameDisplayComponent implements OnInit {

  @Input() patientInfo: PatientNameTranslation | PatientNameTranslationV2;
  @Input() appointmentDateTime: string;

  @Input() useV2TranslationPipe: boolean = false;
  @Input() preventDialogTrigger: boolean = false;
  @Input() showMedicalRecords = false;
  @Input() showRegistrationNote = false;

  constructor(
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {

  }

  openPatientDialog() {
    if (this.preventDialogTrigger) {
      return;
    }
    if (this.patientInfo) {
      const patientId = this.patientInfo.patientId || this.patientInfo.id;
      this.dialog.open(PatientInfoModalComponent, {
        data: {
          patientId,
          appointmentDateTime: this.appointmentDateTime,
          showMedicalRecords: this.showMedicalRecords,
          showRegistrationNote: this.showRegistrationNote
        }
      });
    }
  }
}
