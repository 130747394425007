import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatRadioModule} from '@angular/material/radio';
import {PipeModule} from '@v2/core/pipes/pipe.module';
import {SeverityStatusComponent} from './severity-status.component';

@NgModule({
  declarations: [SeverityStatusComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatRadioModule,
    PipeModule
  ],
  exports: [SeverityStatusComponent]
})
export class SeverityStatusModule {
}
